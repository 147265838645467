import React, {
  createRef,
  CSSProperties
} from 'react';
import ReactDOM from 'react-dom';

export type PopupProps = {
  className?: string
  popupClassName?: string
  popupContent: React.ReactNode,
  popupHorizontalAlign?: 'center' | 'left',
  popupVerticalAlign?: 'top' | 'bottom',
  closeOnPopupClick?: boolean,
  clickable?: boolean,
  hoverable?: boolean,
  /**
   * ID attribute for dropdown button
   */
  id?: string
}

type PopupState = {
  showPopup: boolean,
  popupStyle: CSSProperties
}

const TOOLTIP_GAP = 10; // px

class Popup extends React.Component<PopupProps, PopupState> {
  triggerRef = createRef<HTMLAnchorElement>();
  popupRef = createRef<HTMLDivElement>();

  state = {
    showPopup: false,
    popupStyle: {}
  }

  componentWillUnmount() {
    this.setState({showPopup:false});
  }

  onDocumentClick = () => {
    const {clickable = true} = this.props;
    if (!clickable)
      return;

    this.close();
  }

  updateMenuStyle = ()=>{
    const {popupHorizontalAlign = 'center', popupVerticalAlign = "bottom"} = this.props;
    const trigger = this.triggerRef.current;
    const popup = this.popupRef.current;
    if(!trigger || !popup)
      return;

    const triggerRect = trigger.getBoundingClientRect();
    const popupRect = popup.getBoundingClientRect();
    const screenWidth = window.innerWidth;

    const style:React.CSSProperties = {
      top: triggerRect.bottom + 4,
      minWidth: triggerRect.width,
      position: 'absolute',
      zIndex:1000
    };
    if(popupHorizontalAlign === 'center') {
      const left = triggerRect.left + triggerRect.width/2 - popupRect.width/2;
      const offScreenX = Math.max(left + popupRect.width - screenWidth, 0);
  
      style.left = left - offScreenX;
      (style as any)["--popup-off-screen-x"] = `${offScreenX}px`;
    }else if(popupHorizontalAlign === 'left') {
      const left = triggerRect.left;
      const offScreenX = Math.max(left + popupRect.width - screenWidth, 0);

      style.left = triggerRect.left - offScreenX;
      (style as any)["--popup-off-screen-x"] = `${offScreenX}px`;
    }

    if (popupVerticalAlign === "top") {
      style.top = triggerRect.top - popupRect.height - TOOLTIP_GAP;
    }

    style.maxHeight = window.innerHeight - 4 - (style.top as number);

    if(popupRect.width > window.innerWidth){
      style.width = window.innerWidth;
      delete style.minWidth;
    }

    this.setState({popupStyle:style});
  }

  open = (ev:React.MouseEvent) => {
    this.setState({showPopup: true},()=>this.onAnimationFrame());
    document.addEventListener('click',this.onDocumentClick);
  }

  close = () => {
    this.setState({showPopup: false});
    document.removeEventListener('click',this.onDocumentClick);
  }

  onClick = (ev: React.MouseEvent) => {
    const {clickable = true} = this.props;
    if (!clickable)
      return;

    this.state.showPopup ? this.close() : this.open(ev)
  }

  onAnimationFrame = () => {
    if(!this.state.showPopup)
      return;
    this.updateMenuStyle();
    requestAnimationFrame(this.onAnimationFrame);
  }

  onPopupClick = (ev:React.MouseEvent)=>{
    if(this.props.closeOnPopupClick) {
      this.setState({showPopup: false});
      return;
    }

    ev.stopPropagation();
    ev.nativeEvent.stopImmediatePropagation();
  }

  onMouseEnter = (ev:React.MouseEvent) => {
    if (!this.props.hoverable)
      return;

    this.open(ev);
  }

  
  onMouseLeave = () => {
    if (!this.props.hoverable)
      return;

    this.close();
  }

  render() {
    const {showPopup,popupStyle} = this.state;
    const {children,className,popupContent,popupClassName,id} = this.props;

    return (
      <>
        <a
          id={id}
          className={className}
          onClick={this.onClick}
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          ref={this.triggerRef}
        >
          {children}
        </a>
        {showPopup &&
          ReactDOM.createPortal(
            <div
              style={popupStyle}
              className={popupClassName}
              onClick={this.onPopupClick}
              ref={this.popupRef}
            >
              {popupContent}
            </div>,
            document.getElementById("root") as Element
          )}
      </>
    );
  }
}

export default Popup;