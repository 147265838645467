import { useEffect, useRef } from "react";

export default function<T extends Array<any>>(cb: (newArgs:T,oldArgs:T)=>void,args: T) {
  const ref = useRef<T>([...args] as T);

  useEffect(() => {
    const oldArgs:T = ref.current;
    ref.current = [...args] as T;

    const changed = args.some((a,index)=>oldArgs[index] !== a);
    if(changed)
      return cb(args,oldArgs);
  }, args);
}