import React, {Component, CSSProperties, ReactNode, SyntheticEvent} from "react";
import cn from "bem-cn";

import "./style.scss";
import {isDesktopSafari, isEdge, isIE11, isIOS} from "../../services/platform";
import {loadImageAjax} from "../../services/images";
import FrameUnavailable from "../FrameUnavailable";
import whiteNoise from "../../images/white-noise.gif";

const b = cn('video-player');

export type PlayerState = {
  loading: boolean,
  playing: boolean,
  speeds: number[],
  showPreview: boolean,
  videoReady:boolean,
  speed: number,
  previewUrl?: string,
  unavailable: boolean,
  ts: number,
  ratio: number,
  mediaStyle: CSSProperties
}

type Props = {
  onStateChanged: (state:PlayerState)=>void,
  src: string,
  getPreviewUrl: (ts:number) => string
  children: ReactNode,
  defaultTs: number,
  className?: string,
  defaultSpeed?: number;
}

export const defaultState: PlayerState = {
  loading: true,
  playing: false,
  speeds: !isIE11 && !isEdge && !isIOS && !isDesktopSafari ?  [1,2,4,8,16] : [1,2],
  showPreview: true,
  videoReady:false,
  speed:1,
  unavailable: false,
  ts: 0,
  ratio: 2,
  mediaStyle: {}
}

export class VideoPlayer extends Component<Props,PlayerState> {
  private readonly player = React.createRef<HTMLVideoElement>();
  private readonly container = React.createRef<HTMLDivElement>();
  ratio: number | null = null;
  mounted = true;
  private resizeObserver?: ResizeObserver;

  constructor(props: Props, context: any) {
    super(props, context);
    this.state = {
      ...defaultState,
      ts: props.defaultTs,
      speed: props.defaultSpeed || defaultState.speed,
    };
  }

  componentDidUpdate(prevProps: Props,prevState:PlayerState) {
    if(this.state !== prevState)
      this.props.onStateChanged(this.state);

    if((prevState.ts !== this.state.ts || prevState.showPreview !== this.state.showPreview) && this.state.showPreview) {
      this.updatePreview();
    }
  }

  componentDidMount() {
    this.updatePreview();
    (window as any).player = this.player.current;
    this.props.onStateChanged(this.state);

    if(this.container.current) {
      this.resizeObserver = new ResizeObserver(()=>this.updateMediaStyle());
      this.resizeObserver.observe(this.container.current);
    }
  }

  componentWillUnmount() {
    this.mounted = false;
    this.resizeObserver?.disconnect();
  }

  updatePreview(showLoader = true) {
    const {getPreviewUrl} = this.props;
    const {ts} = this.state;
    if(showLoader){
      this.setState({loading:true});
    }
    const url = getPreviewUrl(ts);
    return loadImageAjax(url).then(url=>{
      this.setState({previewUrl:url});
    }).finally(()=>{
      this.setState({loading:false});
    })
  }

  async onLoadedMetadata() {
    let player = this.player.current;
    if(!player)
      return;
    this.setSpeed(this.state.speed);

    if(isIOS) {
      await player.play();
      player.pause();

      //timeupdate fired in a moment after pause
      setTimeout(()=>{
        this.setState({videoReady:true});
      },1000)
    }else
      this.setState({videoReady:true});

    this.updateRatio(player.videoWidth,player.videoHeight);
  }

  onTimeUpdate() {
    const player = this.player.current;
    if(!this.state.videoReady || !player)
      return;

    this.setState({ts:player.currentTime});
    if(this.state.playing && player.currentTime === player.duration){
      this.play();
    }
  }

  onFail() {
    this.setState({unavailable:true});
  }

  onWaiting() {
    this.setState({loading: true})
  }

  onSeeked() {
    this.setState({loading: false})
  }

  onCanPlay() {
    this.setState({loading: false})
  }

  onPreviewFailed() {
    this.setState({showPreview:false});
  }

  getProgress() {
    const player = this.player.current;
    if(!player)
      return 0;
    return this.state.ts/player.duration;
  }

  navigate(ts: number) {
    if(this.state.playing)
      this.navigateHTMLVideo(ts);
    else {
      this.setState({
        showPreview: true,
        ts
      });
    }
  }

  private navigateHTMLVideo(ts:number) {
    let player = this.player.current;
    if(!player)
      return;
    player.currentTime = ts;
  }

  setSpeed(speed: number) {
    const player = this.player.current;
    if(!player)
      return;
    this.setState({speed});
    player.playbackRate = speed;
  }

  play() {
    const player = this.player.current;
    if(!player)
      return;
    let {playing} = this.state;
    if(playing){
      player.pause();
      this.setState({playing: false});
      if(player.readyState <2)
        this.updatePreview(false).then(()=>this.setState({showPreview: true}));
    } else {
      let {ts} = this.state;
      if(ts === player.currentTime){
        player.play();
        this.setState({playing:true,showPreview: false});
      }else {
        this.setState({loading:true,playing:true});
        player.addEventListener('seeked',()=>{
          player.play();
          this.setState({showPreview:false,loading: false});
        },{once:true});
        player.currentTime = ts;
      }

      if(Math.abs(player.currentTime - player.duration)<1){
        player.currentTime = 0;
      }
    }
  }

  imgOnLoad(ev: SyntheticEvent<HTMLImageElement>) {
    this.updateRatio(ev.currentTarget.naturalWidth,ev.currentTarget.naturalHeight);
  }

  updateRatio(width:number, height: number) {
    const ratio = width/height;
    this.setState({ratio},()=>this.updateMediaStyle());
  }

  updateMediaStyle() {
    const elem = this.container.current;
    if(!elem)
      return;
    const mediaRatio = this.state.ratio;
    const outerRatio = elem.offsetWidth/elem.offsetHeight;
    if(mediaRatio > outerRatio) {
      this.setState({mediaStyle:{width: elem.offsetWidth}});
    }else {
      this.setState({mediaStyle:{height: elem.offsetHeight}});
    }
  }

  render() {
    let {loading,showPreview,previewUrl,unavailable,videoReady,mediaStyle} = this.state;
    let {children,className,src} = this.props;

    if(unavailable)
      return (
        <div className={b.mix(className).toString()}>
          <FrameUnavailable msg={'Video is unavailable'}/>
        </div>
      );

    return (
      <div className={b.mix(className)} ref={this.container}>
        {loading && !videoReady && !previewUrl &&
          <div className={b('white-noise')} style={{backgroundImage:`url(${whiteNoise})`}}/>
        }
        <div className={b('media-container')}>
          {showPreview && previewUrl && (
            <img
              onLoad={(ev) => this.imgOnLoad(ev)}
              className={b("video-preview").toString()}
              src={previewUrl}
              style={mediaStyle}
              onError={() => this.onPreviewFailed()}
            />
          )}
          <video
            className={b('video',{invisible: showPreview})}
            muted
            playsInline
            onLoadedMetadata={ev => this.onLoadedMetadata()}
            onCanPlay={() => this.onCanPlay()}
            onWaiting={() => this.onWaiting()}
            onSeeked={()=> this.onSeeked()}
            onTimeUpdate={() => this.onTimeUpdate()}
            onClick={ev => ev.preventDefault()}
            onError={ev => this.onFail()}
            ref={this.player}
            style={mediaStyle}
          >
            <source src={src} type="video/mp4"/>
          </video>
          {!loading && children}
        </div>
      </div>
    );
  }
}

export default VideoPlayer;