import React, {useCallback, useContext, useRef, useState} from 'react';
import { useHistory } from 'react-router-dom';

import styles from "./style.module.scss";
import ApronAlert from "../../models/apronAlert";
import {formatDelayHumanText, now} from "../../services/time";
import {getAlertLabel, getAirlineFromFlightNumber, getStandLabel, parseAlert} from "../../services/data";
import CalendarFormatter from "../CalendarFormatter";

import { observer } from 'mobx-react';
import {RootContext} from "../../services/react";
import Popup from "../Popup";
import AirlineIcon from '../AirlineIcon';
import classNames from 'classnames';
import { DIRECTION_HORIZONTAL, useHammer } from '../../hooks/useHammer';
import { clamp } from 'lodash';
import { isTouchDevice } from '../../services/platform';

interface Props {
  alert: ApronAlert;
  active: boolean;
}

const ALERT_BUTTON_EXTRA_WIDTH = 110; // px
const ALERT_BUTTON_FULL_WIDTH = 50; // px
const ALERT_BUTTON_MIN_WIDTH = 32; // px

const SWIPE_THRESHOLD = 0.65; // from 0 to 1

const getBtnWidth = (x: number, cardWidth: number) => {
  const halfWidth = cardWidth * 0.5;
  const halfWidthWithButton = halfWidth - ALERT_BUTTON_FULL_WIDTH;
  const distanceRatio = (x - halfWidthWithButton) / halfWidthWithButton;

  return clamp(distanceRatio * ALERT_BUTTON_FULL_WIDTH, ALERT_BUTTON_MIN_WIDTH, ALERT_BUTTON_FULL_WIDTH);
}

const ApronAlertContainer: React.FC<Props>= ({alert, active})=> {
  const rootStore = useContext(RootContext);
  const history = useHistory();
  const standActiveTurn = rootStore.standsStore.activeTurnarounds[alert.standId];
  const isForActiveTurn = alert.turnaroundId === standActiveTurn?.id;

  const ref = useRef<HTMLDivElement>(null);
  const btnRef = useRef<HTMLDivElement>(null);

  const [mouseOver,setMouseOver] = useState(false);
  const [wrapperStyle,setWrapperStyle] = useState <React.CSSProperties>({});

  const transition = useRef(false);

  const navigate = (item:ApronAlert) => {
    if(item.fake)
      return;
    history.push(`/${item.standId}/${item.turnaroundId}`);
  }

  const onAlertBtnClick = () =>
    active
      ? rootStore.sidebarStore.markAlertAsRead(alert)
      : rootStore.sidebarStore.markAlertAsUnread(alert);

  const onMouseEnter = useCallback(({clientX}: React.MouseEvent)=>{
    setMouseOver(true);
    transition.current = true;

    const {current} = ref;
    if (!current)
      return;

    const btnWidth = getBtnWidth(clientX, current.clientWidth);
    requestAnimationFrame(() => {
      if (btnRef.current) {
        btnRef.current.style.width = `${btnWidth}px`;
      }
    });
  } ,[]);
  const onMouseLeave = useCallback(()=> {
    setMouseOver(false);
    transition.current = true;

    if (!btnRef.current)
      return;

    // Reset onMouseMove's styles
    requestAnimationFrame(() => {
      if (btnRef.current) {
        // @ts-ignore
        btnRef.current.style.width = null;
        // @ts-ignore
        btnRef.current.style.transition = null;
      }
    });
  } ,[]);
  const onMouseMove = useCallback(({clientX}: React.MouseEvent)=>{
    const {current} = ref;
    if (!current || !btnRef.current)
      return;

    const halfWidth = current.clientWidth * 0.5;
    if (clientX < halfWidth)
      return;

    // Wait for mouseenter animation completion
    if (transition.current)
      return;

    const btnWidth = getBtnWidth(clientX, current.clientWidth);
    requestAnimationFrame(() => {
      if (btnRef.current) {
        btnRef.current.style.width = `${btnWidth}px`;
        btnRef.current.style.transition = `none`;
      }
    });
  } ,[]);
  const onTransitionEnd = useCallback((e: React.TransitionEvent)=>{
    if (e.propertyName !== "width")
      return;

    transition.current = false;
  } ,[]);

  useHammer({
    ref,
    enabled: isForActiveTurn,
    threshold: 6,
    direction: DIRECTION_HORIZONTAL,
    onPanMove: ({ movementX, pointerType }) => {
      const el = ref.current;
      if (pointerType !== "touch" || !el)
        return;
        
      const transform = el.style.transform;
      const minValue = -el.clientWidth * SWIPE_THRESHOLD;
      let value = 0;
      if (transform) {
        value = +transform.split("(")[1].split("px")[0];
        value = clamp(value, minValue, 0);
      }

      if (value <= minValue)
        onAlertBtnClick();

      setWrapperStyle({
        transform: `translateX(${value + movementX}px)`,
      })
    },
    onPanEnd: ({ pointerType }) => {
      const el = ref.current;
      if (pointerType !== "touch" || !el)
        return;

      setWrapperStyle({
        transition: `transform 0.2s`,
      })
    },
  });

  const parsedAlert = parseAlert(alert);

  const severity = alert.config.severity;
  const severityClass =
    isForActiveTurn && active
      ? styles[alert.config.severity.toLowerCase()]
      : undefined;

  const textStyle: React.CSSProperties = {};
  if (isForActiveTurn) {
    // Prevent text wrapping when hovering on alert button
    textStyle.width = `${rootStore.sidebarStore.width - ALERT_BUTTON_EXTRA_WIDTH}px`;
  }

  return (
    <div
      ref={ref}
      className={styles.alertWrapper}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      style={wrapperStyle}
    >
      <div
        className={classNames(styles.alert, severityClass)}
        data-alert-id={alert.id}
        onClick={() => navigate(alert)}
      >
        <div className={styles.header}>
          <div className={styles.title}>
            <AirlineIcon icaoCode={getAirlineFromFlightNumber(alert.outboundFlightNumber) || getAirlineFromFlightNumber(alert.inboundFlightNumber)} />
            {getStandLabel(alert.originalStandId || alert.standId)}
            <i className={classNames(styles.titleSeparator, 'fas fa-circle')}/>
            {alert.outboundFlightNumber || <span style={{opacity:0.6}}>N/A</span>}
            <i className={classNames(styles.titleArrow, 'fas fa-long-arrow-alt-up')}/>
          </div>

          {parsedAlert && 
            <Popup
              className={styles.tooltipComponent}
              popupHorizontalAlign={"center"}
              popupVerticalAlign={"top"}
              clickable={false}
              hoverable={true}
              popupContent={
                <div className={styles.alertTooltip}>
                  <span>event</span><span>{parsedAlert.event.toLowerCase()}</span>
                  <span>trigger</span><span>{parsedAlert.triggerMessage.toLowerCase()}</span>
                  <span>minutes</span><span>{parsedAlert.minutes}</span>
                  <span>timer start</span><span>{parsedAlert.notificationStartLabel.toLowerCase()}</span>
                  <span>severity</span><span>{severity.toLowerCase()}</span>
                  <span>inbound flight</span><span>{alert.config.requireInboundFlight ? "Optional" : "Required"}</span>
                </div>
              }
            >
              <span className={styles.questionMark}>?</span>
            </Popup>
          }

          {isForActiveTurn && severity && (
            <div className={classNames(styles.severity, severityClass)}>{severity}</div>
          )}
        </div>
        <div className={styles.text} style={textStyle}>
          {getAlertLabel(alert)}
        </div>

        <div className={styles.time}>
          {(isForActiveTurn && !mouseOver) ? formatDelayHumanText(now() - alert.ts) + ' ago' : <CalendarFormatter time={alert.ts} showTime={true} showComma={true}/>}
        </div>
      </div>
      {!isTouchDevice() && (
        <div
          ref={btnRef}
          className={classNames(
            styles.alertButton,
            {
              [severityClass!]: active,
              [styles.hidden]: !isForActiveTurn,
            }
          )}
          onClick={onAlertBtnClick}
          onTransitionEnd={onTransitionEnd}
        >
          <i className={active ? "fas fa-check" : "fas fa-redo"} />
        </div>
      )}
    </div>
  )
}
export default observer(ApronAlertContainer);