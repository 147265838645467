import React, {useContext, useEffect, useLayoutEffect} from 'react';
import Timeline from "../Timeline";
import StandVideoStream from "../StandVideoStream";
import "./style.scss";
import ErrorBoundary from "../ErrorBoundary";
import {
  EMPTY_APRON_LABEL, UNAUTHORIZED_TURN_SORRY,
} from "../../constants/strings";
import whiteNoise from "../../images/white-noise.gif";
import {PropsWithClass} from "../../models/common";
import {StandContext} from "../../services/react";
import { useHistory } from 'react-router-dom';
import {when} from "mobx";
import ZoomContainer from "../ZoomContainer";

export const EmptyApron: React.FC<PropsWithClass> = ({className}) => {
  const standContext = useContext(StandContext);
  const history = useHistory();

  useLayoutEffect(()=>{
    standContext.selectTurnaround(null);

    const dispose = when(
      ()=>!!standContext.selectedTurnaroundId,
      ()=>history.push('/' + standContext.standId + '/' + standContext.selectedTurnaroundId)
    )

    return ()=> dispose();
  },[standContext.standId]);

  return (
    <div className={'empty-apron ' + className}>
      <h1>{EMPTY_APRON_LABEL}</h1>
      <ZoomContainer className={'stand-video-stream'}>
        <StandVideoStream key={standContext.standId}/>
      </ZoomContainer>

      <ErrorBoundary msg={'Progress chart is broken'} showRefreshBtn={false}>
        <Timeline/>
      </ErrorBoundary>
    </div>
  )
}

export const UnauthorizedTurn: React.FC<PropsWithClass> = ({className}) => {
  return (
    <div className={'empty-apron ' + className}>
      <h1>{UNAUTHORIZED_TURN_SORRY}</h1>
      <div className={'white-noise'} style={{backgroundImage: `url(${whiteNoise})`}}/>
      <ErrorBoundary msg={'Progress chart is broken'} showRefreshBtn={false}>
        <Timeline/>
      </ErrorBoundary>
    </div>
  )
}