import React, {useContext} from 'react';

import "./style.scss";
import {showStandClearAlert} from "../../services/config";
import Turnaround from "../../models/turnaround";
import {StandContext} from "../../services/react";
import {observer} from "mobx-react";

const TurnaroundAlerts: React.FC<{turnaround: Turnaround}> = ({turnaround}) => {
  const {detections,openModal} = useContext(StandContext);

  let ts =turnaround?.inboundFlight?.actualLandingTime ||  turnaround.start;
  const notClear = detections.find(d=>
    d.type === 'stand_not_clear'
    && d.start <=ts
    && (!d.end || d.end >=ts)
  );

  if(showStandClearAlert && turnaround && notClear)
    return (
      <div className={'turnaround-info-panel__alerts turnaround-alert'}>
        <i className="far fa-exclamation-triangle"/>&nbsp;
        Stand was not clear at the&nbsp;
        <a onClick={()=>openModal(turnaround.start, null)}>moment</a>&nbsp;
        of arrival.
      </div>
    );

  return null;
}

export default observer(TurnaroundAlerts);