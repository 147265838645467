import React, {useContext} from "react";
import {createPortal} from "react-dom";
import Turnaround from "../../models/turnaround";
import { observer } from "mobx-react";
import cn from "bem-cn";

import "./style.scss";
import {NOT_AVAILABLE} from "../../constants/strings";
import {RootContext} from "../../services/react";
import {formatFlightNumber} from "../../services/data";

type Props = {
  target: SVGElement
  turnaround: Turnaround
}

const b = cn('gate-popup');

const TurnaroundMapPopup: React.FC<Props> = ({target,turnaround}) => {
  const mapRect = target.closest('svg')?.getBoundingClientRect()!;
  const rect = target.getBoundingClientRect();
  const style: React.CSSProperties = {
    left: rect.left + rect.width/2
  };
  let placement;

  if(rect.top > mapRect.top + mapRect.height/2) {
    placement = 'top';
    style.bottom = window.innerHeight - rect.top;
  }else {
    placement = 'bottom';
    style.top = rect.top + rect.height;
  }

  const {standsStore} = useContext(RootContext);
  const stand = standsStore.stands.find(s=>s.id === turnaround.standId);
  const {inboundFlight, outboundFlight} = turnaround;

  return createPortal(
    <div className={b({placement})} style={style}>
      <div className={b('header')}>
        {stand && <div className={b('stand')}>{stand.label}</div>}
        <span className={b('aircraft')}>{(inboundFlight || outboundFlight)?.aircraftType || NOT_AVAILABLE}</span>
      </div>

      <div className={b('inbound-flight')}>
        {inboundFlight ? formatFlightNumber(inboundFlight) || NOT_AVAILABLE : NOT_AVAILABLE}
      </div>

      {inboundFlight && (
        <div className={b('airport-from')}>
          {inboundFlight.departureAirport}
          <i className="far fa-arrow-right"/>
        </div>
      )}

      <i className={b('divider').mix("fas fa-circle")}/>

      <div className={b('outbound-flight')}>
        {outboundFlight ? formatFlightNumber(outboundFlight) || NOT_AVAILABLE : NOT_AVAILABLE}
      </div>

      {outboundFlight && (
        <div className={b('airport-to')}>
          <i className="far fa-arrow-right"/>
          {outboundFlight.arrivalAirport}
        </div>
      )}
    </div>,
    document.getElementById('root')!
  )
}

export default observer(TurnaroundMapPopup);