import React, { useContext } from "react";
import cn from "bem-cn";
import { observer } from "mobx-react";
import { GateContext } from "./GateContext";
import { standsDetails } from "../../constants/stands";
import { defaultGateSize } from "./index";
import { formatDelayHumanText, now } from "../../services/time";

const b = cn("gate-pic");

export const PobtLabel: React.FC = observer(() => {
  const context = useContext(GateContext);
  const turnaround = context.turnaround!;
  const { size, angle, parent, disabled } = context;

  if (!turnaround.pobt || disabled) return null;

  let offset = 8;
  if (parent && standsDetails[parent]) {
    const parentHeight = (standsDetails[parent].mapConfig.size ||
      defaultGateSize)[1];
    offset += parentHeight - defaultGateSize[1];
  }

  let textTransform = `translate(0 ${-size[1] / 2 - offset})`;
  if (Math.abs(angle) > 90) textTransform += ` rotate(180)`;

  const delay = turnaround.pobt - now();
  if (delay < 0) return null;

  return (
    <text
      x={0}
      y={0}
      transform={textTransform}
      className={b("pobt-text")}
      dominantBaseline="middle"
      textAnchor="middle"
    >
      {formatDelayHumanText(delay, true, true)}
    </text>
  );
});
