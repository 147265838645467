import React from "react";
import { getAirlineIcon } from "../../services/data";

type Props = {
  icaoCode?: string;
  className?: string;
};

const AirlineIcon: React.FC<Props> = ({ icaoCode, className }) => {
  return <img className={className} src={getAirlineIcon(icaoCode)} alt="" />;
};

export default AirlineIcon;
