import React, { useCallback, useState } from "react";
import "./style.scss";
import icon from "./support.png";
import SupportForm from "../SupportForm";
import GuideTour from "../Tour";

const SupportButton: React.FC = () => {
  const [formOpened, setFormOpened] = useState(false);
  const [tourOpened, setTourOpened] = useState(false);

  return (
    <div className={"support-button-container"}>
      <a
        className={"support-button"}
        onClick={() => setFormOpened(!formOpened)}
      >
        <img src={icon} />
      </a>
      {tourOpened && <GuideTour onClose={() => setTourOpened(false)} />}
      {formOpened && (
        <SupportForm
          onClose={() => setFormOpened(false)}
          onTourStart={() => {
            setFormOpened(false);
            setTourOpened(true);
          }}
        />
      )}
    </div>
  );
};

export default SupportButton;
