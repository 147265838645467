import {action, makeAutoObservable} from "mobx";
import _, { partition } from "lodash";

import {
  breakDetections,
} from "../services/config";
import Detection from "../models/detection";
import {now} from "../services/time";
import StandStore from "./stand";
import {OTHERS_GROUP} from "../constants/strings";

export type DetectionEvent = Detection & {original: Detection};

export default class TimelineStore {
  tmpTime: number | null = null;
  PADDING_MULTIPLIER = 0.05;
  MIN_DISPLAY_PERIOD = 20 * 60 * 1000;
  PTS_MULTIPLIER = 60*1000;
  MAX_DETECTION_LAG = 60*2*1000;

  constructor(public standStore: StandStore) {
    makeAutoObservable(this,{
      getClickedTimestamp: action.bound,
      getLeftFromTimestamp: action.bound
    });
  }

  get detections() {
    let {detections, selectedTurnaround: turn} = this.standStore;


    let detectionsToBreak = [];
    [detections, detectionsToBreak] = partition(detections, i=>!breakDetections.includes(i.type));
    let events:([DetectionEvent,DetectionEvent] | DetectionEvent)[] = detectionsToBreak.map(detection=>{
      let ev1:DetectionEvent = {...detection, original:detection};
      ev1.type = ev1.startType;
      ev1.label = ev1.startLabel;
      ev1.end = ev1.start;
      ev1.original = detection;

      if(!detection.endType)
        return ev1;

      let ev2: DetectionEvent = {...detection, original:detection};
      ev2.type = ev2.endType as string;
      ev2.label = ev2.endLabel;
      ev2.start = ev2.end as number;

      return [ev1,ev2];
    });
    let res: (DetectionEvent | Detection)[] = [...detections , ..._.flatten(events)];
    res = res.filter(i=>i.label).filter(d => d?.turnId === turn?.id);
    return res;
  }

  get bounds() {
    let {selectedTurnaround: turnaround, turnarounds} = this.standStore;
    if(!turnaround) {
      turnaround = turnarounds.length ? turnarounds[0] : undefined;
      const end = now() + 5*60*1000;
      return turnaround ? [turnaround.end as number, end] : [now()-this.MIN_DISPLAY_PERIOD,end]
    }
    if(!turnaround.authorized) {
      return [now()-this.MIN_DISPLAY_PERIOD,now() + 5*60*1000]
    }

    // 2 cases:
    // 1) turn is shorter than average -> show at least mean turn period
    // 2) turn is longer than average but has not ended yet -> show the whole turn
    const start = turnaround.start;
    let itemsEnd = this.detections.length ? Math.max(...this.detections.map(e => e.end || 0)) : now();

    let minEnd = start + this.MIN_DISPLAY_PERIOD;
    // if(ptsAvailable){
    //   const duration = pts.duration;
    //   minEnd = start + duration*PTS_MULTIPLIER;
    // }

    let end = turnaround.end
      ? turnaround.end
      : Math.max(now(), itemsEnd, minEnd);

    const padding = (end - start)*this.PADDING_MULTIPLIER;

    return [start - padding, end + padding];
  }

  get rows(): [string,(Detection | DetectionEvent)[][]][] {
    let groups : ([string,Detection[]])[] =_.sortBy(window.groupsMeta,'order').map(g=>[g.key,this.detections.filter(d=>d.group === g.key)]);

    let noGroupdDetections = this.detections.filter(d=>d.group === OTHERS_GROUP);
    if(noGroupdDetections.length)
      groups.push([OTHERS_GROUP, noGroupdDetections]);

    return groups.map(([group, items]) => {
      items = _.sortBy(items, 'start');
      let result = _.groupBy(items, 'type');
      return [group, Object.values(result)]
    });
  }

  get outages() {
    const {inferenceTimestamp,outages,isLive,cameras} = this.standStore;
    const {bounds} = this;
    if(!inferenceTimestamp)
      return [];
    let items = outages.filter(o=>o.start < bounds[1] && (!o.end || o.end > bounds[0]));
    if(!isLive)
      return items;

    cameras.forEach(camera=>{
      if(now()-inferenceTimestamp[camera] < this.MAX_DETECTION_LAG)
        return;
      if(items.find(i=>i.camera === camera && !i.end))
        return;

      items.push({start:inferenceTimestamp[camera],camera,id: camera+'_fake'});
    });
    return items;
  }

  getLeftFromTimestamp(ts: number) {
    const {bounds} = this;
    return (ts - bounds[0])/(bounds[1] - bounds[0]);
  }

  getClickedTimestamp(ev: MouseEvent) {
    const [start,end] = this.bounds;
    let rect = (ev.target as HTMLElement).closest('.col2')?.getBoundingClientRect();
    if(!rect)
      return null;
    let left = (ev.clientX - rect.left) / rect.width;
    let ts = start + (end - start) * left;
    return ts;
  }
}