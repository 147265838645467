import React, {PropsWithChildren, useCallback, useContext, useEffect, useRef, useState} from "react";
import classNames from "classnames";

import Loader from "../Spinner";
import {loadImageAjax} from "../../services/images";
import "./style.scss";
import whiteNoise from "../../images/white-noise.gif";
import {StandContext} from "../../services/react";
import { observer } from "mobx-react";

type Props = {
  timestamp: number;
  camera: string;
  onSuccess?: (ts:number)=>void;
  onFail: (er: Error)=>void;
  disableSpinner?: boolean;
  className?: string;
  id?: string;
  flushBeforeUpdate?: boolean
}

const Frame : React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const container = useRef<HTMLDivElement>(null);
  const image = useRef<HTMLImageElement>(null);
  const {timestamp,camera,children, onSuccess, onFail, disableSpinner, className, id, flushBeforeUpdate} = props;
  const [loading,setLoading] = useState(true);
  const [url,setUrl] = useState<string>("");
  const [success,setSuccess] = useState(false);
  const standContext = useContext(StandContext);

  const updateRatio = useCallback(()=>{
    const contElem = container.current;
    const imgElem = image.current;
    if (contElem && imgElem && imgElem.naturalWidth) {
      const contRatio = contElem.offsetWidth / contElem.offsetHeight;
      const imgRatio = imgElem.naturalWidth/imgElem.naturalHeight;
      if(imgRatio > contRatio){
        imgElem.style.width = contElem.offsetWidth + 'px'
        imgElem.style.height = 'auto';
      } else {
        imgElem.style.height = contElem.offsetHeight + 'px'
        imgElem.style.width = 'auto';
      }
    }
  },[])

  useEffect(()=>{
    const resizeObserver = new ResizeObserver(updateRatio);
    if(container.current)
      resizeObserver.observe(container.current);
    return ()=> {resizeObserver.disconnect()}
  },[])

  useEffect(()=>{
    let cancelFlag = false;
    setLoading(true);
    if(flushBeforeUpdate)
      setUrl("");
    const url = standContext.getFrameUrl(camera,timestamp);
    loadImageAjax(url).then(url=>{
      if(cancelFlag)
        return;
      setUrl(url);
      setSuccess(true);
      onSuccess && onSuccess(timestamp);
    },(er)=>onFail(er)).finally(()=>{
      setLoading(false);
    });
    return () => {cancelFlag = true}
  },[timestamp,camera]);

  return (
    <div id={id} className={classNames('frame',className)} ref={container}>
      {!url && (
        <div className={'white-noise'} style={{backgroundImage: `url(${whiteNoise})`}}/>
      )}
      {url && (
        <div className={'img-container'}>
          <img
            src={url}
            key={camera}
            ref={image}
            alt=''
            style={{visibility: success ? 'visible':'hidden'}}
            onLoad={updateRatio}
          />
          {children}
        </div>
      )}
      {!disableSpinner && loading && <Loader/>}
    </div>
  );
};

export default observer(Frame);
