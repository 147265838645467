import React, {Component, ContextType, CSSProperties} from "react";
import {observer} from 'mobx-react';

import styles from "./style.module.scss";
import classnames from 'classnames';
import {default as BBoxType}  from "../../models/bbox";
import classNames from "classnames";
import { loadImageAjax } from "../../services/images";
import { clamp } from "lodash";
import {RootContext} from "../../services/react";
import TimeFormatter from "../TimeFormatter";

type Props = {
  bbox:BBoxType,
  color?: string,
  label?: string
  hiresEnabled?: boolean,
}

type State = {
  showLabel: boolean,
  labelInside: boolean,
  showHires: boolean,
  hiresUrl: string,
  hiresTs: number,
}

class BBox extends Component<Props,State> {
  state = {
    showLabel: false,
    labelInside: false,
    showHires: false,
    hiresUrl: "",
    hiresTs: 0,
  };

  ref = React.createRef<HTMLDivElement>();

  static contextType = RootContext;
  context!: ContextType<typeof RootContext>;

  static decimalToCSS(value: number) {
    return value * 100 + '%'
  };

  get clickable() {
    return this.props.hiresEnabled && this.props.bbox.hires.length > 0;
  }

  componentDidMount() {
    this.loadHiresImage();

    if(!this.props.label)
      return;

    let cont = this.ref.current as HTMLDivElement;
    let parentRect = (cont.parentElement as HTMLDivElement).getBoundingClientRect();
    let boxRect = cont.getBoundingClientRect();

    if(boxRect.top - parentRect.top < 20)
      this.setState({labelInside:true});

    this.setState({showLabel: true});
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    if (prevProps.bbox.camera !== this.props.bbox.camera) {
      this.setState({showHires: false, hiresUrl: ""});
      this.loadHiresImage();
    }
  }

  loadHiresImage() {
    if (!this.clickable)
      return;

    const hiresData = this.props.bbox.hires[0];
    const url = hiresData.url + '?token=' + this.context.imgToken
    loadImageAjax(url).then((hiresUrl) => {
      this.setState({hiresUrl, hiresTs: hiresData.ts * 1000 })

    })
  }

  getHiresStyle() {
    const {
      bbox: { box },
    } = this.props;

    const MIN_HIRES_SIZE = 0.5; // 50%
    const MAX_HIRES_SIZE = 1;   // 100%
    const width = box[2] - box[0];
    const height = box[3] - box[1];

    let hiresWidth = 0;
    let hiresHeight = 0;

    if (width > height) {
      hiresWidth = clamp(width * 2, MIN_HIRES_SIZE, MAX_HIRES_SIZE);
      const trueMultiplier = hiresWidth / width;
      hiresHeight = height * trueMultiplier;
    } else {
      hiresHeight = clamp(height * 2, MIN_HIRES_SIZE, MAX_HIRES_SIZE);
      const trueMultiplier = hiresHeight / height;
      hiresWidth = width * trueMultiplier;
    }

    let hiresLeft = 0;
    if (hiresWidth < 1) {
      hiresLeft = Math.min(box[0], MAX_HIRES_SIZE - hiresWidth);
    }

    let hiresTop = 0;
    if (hiresHeight < 1) {
      hiresTop = Math.min(box[1], MAX_HIRES_SIZE - hiresHeight);
    }

    return {
      left: hiresLeft,
      top: hiresTop,
      width: hiresWidth,
      height: hiresHeight,
    };
  }

  renderHires() {
    let { showHires, hiresUrl, hiresTs } = this.state;

    const hiresStyle = this.getHiresStyle();
    const tsStyle: CSSProperties = {};

    const threshold = 0.075; // 7.5% of height
    const overallHeight = hiresStyle.top + hiresStyle.height;
    if (hiresStyle.top > threshold) {
      tsStyle.top = 0;
    } else if (1 - overallHeight > threshold) {
      tsStyle.transform = `translateY(0%)`;
    } else {
      tsStyle.transform = `translateY(-125%)`;
      tsStyle.left = 4;
    }

    return (
      <div
        className={styles.bboxHiresContainer}
        style={{
          left: BBox.decimalToCSS(hiresStyle.left),
          top: BBox.decimalToCSS(hiresStyle.top),
          width: BBox.decimalToCSS(hiresStyle.width),
          height: BBox.decimalToCSS(hiresStyle.height),
        }}
        onClick={() => this.setState({ showHires: !showHires })}
      >
        <img className={styles.bboxHiresImage} alt={""} src={hiresUrl} />
        <span className={styles.hiresTimestamp} style={tsStyle}>
          <TimeFormatter date={false} time={hiresTs} />
        </span>
      </div>
    );
  }

  render() {
    const {clickable} = this;
    let {bbox:{box},color,label} = this.props;
    let {showLabel, labelInside, showHires, hiresUrl} = this.state;

    if (box[2] - box[0] === 1. && box[3] - box[1] === 1.) {
      return null;
    }

    let st: React.CSSProperties = {
      left: BBox.decimalToCSS(box[0]),
      top: BBox.decimalToCSS(box[1]),
      width: BBox.decimalToCSS(box[2] - box[0]),
      height: BBox.decimalToCSS(box[3] - box[1])
    };

    if(color)
      st.borderColor = color;

  return (
    <>
      <div
        className={classNames(styles.bbox, { [styles.clickable]: hiresUrl && clickable })}
        style={st}
        ref={this.ref}
        onClick={() => this.setState({ showHires: !showHires })}
      >
        {showLabel && (
          <span
            className={classnames({ [styles.inside]: labelInside })}
            style={{ backgroundColor: color }}
          >
            {label}
          </span>
        )}
      </div>
      {showHires && hiresUrl && this.renderHires()}
    </>
  );
  }
}

export default observer(BBox);