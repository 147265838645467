import React from "react";
import Turnaround from "../../models/turnaround";

export type GateContextValue = {
  titleHeight: number;
  center: [number, number];
  size: [number, number];
  angle: number;
  turnaround?: Turnaround;
  parent?: string;
  planeOffsetY: number;
  hasChildren: boolean;
  standId: string;
  disabled: boolean;
};

export const GateContext = React.createContext<GateContextValue>(
  {} as GateContextValue
);
