import React, {useContext, useEffect, useState} from "react";
import "moment-timezone";
import { observer } from "mobx-react";
import { useHistory } from 'react-router-dom';

import styles from "./style.module.scss";
import Modal from '../Modal';
import {RootContext} from "../../services/react";
import {getFlights} from "../../services/api";
import {FutureFlight} from "../../models/flightInfo";
import TimeFormatter from "../TimeFormatter";
import { xor } from "lodash";

import Spinner from "../Spinner";
import Turnaround from "../../models/turnaround";
import Watchlist from "../../models/watchlist";
import {WATCHLIST_TAB} from "../../constants/strings";
import {now} from "../../services/time";
import {watchListLifePeriod} from "../../services/config";

type Props = {
  onClose: () => void
}

type ItemProps = {
  checked: boolean
  flight: FutureFlight
  onToggle: (v:boolean)=>void,
  query?: string,
  openTurn?: () => void,
}

const Item: React.FC<ItemProps> = observer(({checked,flight,onToggle,query,openTurn}) => {
  const html = query ? flight.flightNumber.replace(new RegExp(query,'i'),`<mark>${query}</mark>`) : flight.flightNumber;

  return (
    <div className={styles.item}>
      <a className={styles.checkbox} onClick={()=>onToggle(!checked)}>
        {checked ? <i className="fas fa-check-square"/> : <i className="fal fa-square"/>}
      </a>
      <span className={styles.flightNumber} dangerouslySetInnerHTML={{__html:html}}/>
      <span className={styles.sobt}>
        <TimeFormatter date={false} time={flight.scheduledOffBlockTime} seconds={false}/>
      </span>
      {openTurn && <a className={styles.activeBtn} onClick={openTurn}>current</a>}
    </div>
  )
});

const IncidentWatchlistModal: React.FC<Props> = ({onClose}) => {
  const history = useHistory();
  const root = useContext(RootContext);
  const activeTurns = root.standsStore.activeTurnaroundsForMap;

  const [loading,setLoading] = useState(true);

  const [query,setQuery] = useState("");
  const [flights,setFlights] = useState<FutureFlight[]>([]);
  const [selected,setSelected] = useState<FutureFlight[]>([]);

  const openTurn = (turn:Turnaround) => history.push(`/${turn.standId}/${turn.id}`);

  useEffect(()=>{
    getFlights(now() + watchListLifePeriod).then(items=>{
      setFlights(items);

      const existingList = root.sidebarStore.watchlist;
      if(existingList) {
        const selected: FutureFlight[] = [];
        existingList.flights.forEach(f=>{
          const flight = items.find(i=>i.flightNumber === f);
          if(flight)
            selected.push(flight);
        })
        setSelected(selected)
      }
    })
    .finally(() => setLoading(false));
  },[]);

  const onRemove = (item: FutureFlight) => {
    setSelected(xor(selected,[item]));
  }

  const onAdd = (item: FutureFlight) => {
    setSelected([...selected,item]);
  }

  const onSubmit = () => {
    const existingList = root.sidebarStore.watchlist;
    const start = Math.min(...Object.values(root.standsStore.activeTurnarounds).map(t=>t.start));
    const flights = selected.map(s=>s.flightNumber);
    if(!flights.length) {
      if(existingList) {
        Watchlist.remove();
        root.sidebarStore.setWatchlist(null);
      }
    }else {
      if(existingList) {
        existingList.setFlights(flights);
        root.sidebarStore.setTab(WATCHLIST_TAB);
      }else {
        const watchList = Watchlist.create(start,flights);
        root.sidebarStore.setWatchlist(watchList);
      }
    }
    onClose();
  }

  const searchResult = query
    ? flights.filter(f=>f.flightNumber.toLowerCase().indexOf(query.trim().toLowerCase())>=0)
    : flights

  const getTableHeader = (length: number) => (
    <>
      {length > 0 && <div className={styles.itemTitle}>
        <span className={styles.flightTitle}>Flight No</span>
        <span className={styles.sobtTitle}>SOBT</span>
      </div>}
      {length > 1 && <div className={styles.itemTitle}>
        <span className={styles.flightTitle}>Flight No</span>
        <span className={styles.sobtTitle}>SOBT</span>
      </div>}
    </>
  )

  return (
    <Modal modalTitle={'Watchlist settings'} onClose={onClose}>
      <div className={'modal-section-title'}>
        current watchlist (0)
        <a className={styles.clear} onClick={()=>setSelected([])}>clear all</a>
      </div>

      <div className={styles.scrollContainer}>
        <div className={styles.items}>
          {getTableHeader(selected.length)}
          {selected.map((i) => (
            <Item
              checked={true}
              flight={i}
              openTurn={
                activeTurns[i.standId]
                  ? () => openTurn(activeTurns[i.standId])
                  : undefined
              }
              onToggle={() => onRemove(i)}
            />
          ))}
        </div>
      </div>

      <div className={'modal-section-title'}>
        search turnarounds
      </div>
      <div className={styles.searchContainer}>
        <i className="fas fa-search"/>
        <input placeholder={'Search turnaround'} onChange={ev=>setQuery(ev.target.value.toLowerCase())}/>
      </div>

      <div className={styles.scrollContainer}>
        <div className={styles.items}>
          {getTableHeader(searchResult.length)}
          {searchResult.map((i) => (
            <Item
              checked={selected.includes(i)}
              flight={i}
              openTurn={
                activeTurns[i.standId]
                  ? () => openTurn(activeTurns[i.standId])
                  : undefined
              }
              query={query}
              onToggle={(v) => (v ? onAdd(i) : onRemove(i))}
            />
          ))}
        </div>
        {loading && <Spinner className={styles.spinner}/>}
      </div>

      <a className={styles.saveBtn} onClick={onSubmit}>
        save watchlist
      </a>
    </Modal>
  )
}

export default observer(IncidentWatchlistModal);
