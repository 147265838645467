import React, {CSSProperties, useContext, useEffect, useLayoutEffect, useRef, useState} from "react";
import {observer} from "mobx-react";
import classnames from 'classnames';

import {TimelineContext} from "./index";
import TimeFormatter from "../TimeFormatter";
import useAutoRefresh, {StandContext} from "../../services/react";
import {now} from "../../services/time";

type Props = {
  timestamp: number;
  className: string;
  showTimeLabel?: boolean;
}

const TimeDashLine: React.FC<Props> = ({timestamp,className, showTimeLabel}) => {
  const {bounds} = useContext(TimelineContext);
  const [start,end] = bounds;
  const spanRef = useRef<HTMLSpanElement>(null);
  const lineRef = useRef<HTMLDivElement>(null);

  useAutoRefresh();

  useLayoutEffect(()=>{
    const el = lineRef.current;
    const col1El = el?.closest('.timeline')?.querySelector('.col1');
    const col2El = el?.closest('.timeline')?.querySelector('.col2');
    if(!col1El || !col2El || !el)
      return;

    let labelsSectionWidth = col1El.getBoundingClientRect().width;
    let paneWidth = col2El.getBoundingClientRect().width;
    let newLeft = labelsSectionWidth + paneWidth * (timestamp - start)/(end-start) + 'px';
    el.style.left = newLeft;
  },[timestamp]);

  const labelStyle: CSSProperties = {};
  if(timestamp > (start + (end-start)/2))
    labelStyle.transform = 'translateX(-100%)';

  return (
    <div
      className={classnames('time-dashline',className)}
      ref={lineRef}
    >
      {showTimeLabel && (
        <span className={'time-label'} ref={spanRef} key={timestamp} style={labelStyle}>
          <TimeFormatter time={timestamp} date={false}/>
        </span>
      )}
    </div>
  )
};

export const CurrentTimeDashLine: React.FC = observer(() => {
  const {isLive,displayedTimestamp} = useContext(StandContext);
  const [time] = useAutoRefresh();
  const currentTimestamp = isLive ? time : displayedTimestamp;

  if(!currentTimestamp)
    return null;
  return <TimeDashLine timestamp={currentTimestamp} className={'current-time'} showTimeLabel={true}/>;
});

export default observer(TimeDashLine);