import _ from 'lodash';

import Detection from "../models/detection";
import Turnaround from "../models/turnaround";
import {AIRCRAFT_ON_STAND} from "../constants/strings";

export type TimelineMiddleware = ((a:Detection[],b: Turnaround[],c:any,_:any)=>[Detection[],Turnaround[]])[]

export function leaveFirstAndLastWalkaround(detections: Detection[], turns: Turnaround[], ts:number) {
  // console.log('leaveFirstAndLastWalkaround')
  const aircraft = detections.find(d=>d.type === 'aircraft');
  const aircraftEnded = aircraft?.endType;
  let types = detections.filter(function (d) {
    return d.type.indexOf("walkaround_") === 0
  }).map(function (d) {
    return d.type
  });
  if(!types.length)
    return [detections, turns, ts]
  types = _.uniq(types);
  types.forEach(function (type) {
    const [walks, others] = _.partition(detections, function (d) {
      return d.type === type
    });
    if (walks.length === 1)
      return;

    const result = [];
    let start = _.minBy(walks, 'start') as Detection;
    start.endLabel = undefined;
    start.end = start.start + 10000;
    result.push(start);

    if (aircraftEnded) {
      let end = _.maxBy(walks, 'start') as Detection;
      end.endLabel = undefined;
      end.end = end.start + 10000;
      result.push(end);
    }

    detections = [...others, ...result];
  })
  return [detections, turns, ts]
}

//хак чтобы детекшн stand not clear показывался только в интервалах [начало терна, aircraft_on_stand start - 10 секунд]
export function leaveOnlySmallStandNotClearBeforeTurnStart(detections: Detection[], turns: Turnaround[], ts:number) {
  // console.log('leaveOnlySmallStandNotClearBeforeTurnStart')
  const turn = turns[0];
  if (!turn)
    return [detections, turns, ts];

  let standNotClears;
  [standNotClears, detections] = _.partition(detections, function (d) {
    return d.type === 'stand_not_clear'
  });
  const aircraft = detections.find(function (d) {
    return d.type === AIRCRAFT_ON_STAND
  });
  if (!standNotClears.length || !aircraft)
    return [detections, turns, ts];

  let turnEnd = turn.end || Math.max(...detections.map(function (d) {
    return d.end || Date.now()
  }));
  const padding = (turnEnd - turn.start) * 0.05;
  const bounds = [turn.start - padding, turnEnd + padding];

  standNotClears.forEach(function (standNotClear) {
    if (standNotClear.start < aircraft.start) {
      const tmp = {...standNotClear};
      tmp.id = `${tmp.id}_${turn.id}_start`;
      tmp.start = Math.max(tmp.start, bounds[0]);
      tmp.end = aircraft.start - 10000;
      if (tmp.start < tmp.end)
        detections.push(tmp);
    }
  })

  return [detections, turns, ts];
}