import {ApronAlertsStore} from "./alertsBase";
import SidebarStore from "../sidebar";
import ApronAlert from "../../models/apronAlert";
import {getAlerts, getFlights} from "../../services/api";
import {keyBy, last} from "lodash";
import {action, runInAction} from "mobx";
import {updateRate, watchListLifePeriod} from "../../services/config";
import {AlertsParams} from "../../models/api";
import {now} from "../../services/time";
import {FutureFlight} from "../../models/flightInfo";
import {NOT_COVERED_GATE_ALERT_TYPE} from "../../constants/strings";

const SCHEDULE_UPDATE_RATE = 5*60*1000;

export class WatchlistAlertsRepository extends ApronAlertsStore {
  canLoadMore = false;
  schedule: Record<string,FutureFlight> = {}

  constructor(sidebarStore: SidebarStore) {
    super(sidebarStore);
  }

  filterAlerts(alerts: ApronAlert[]) {
    const watchlist = this.sidebarStore.watchlist;
    if (!watchlist)
      return [];
    const min = watchlist.start;
    return alerts.filter(a => a.outboundFlightNumber && watchlist.flights.includes(a.outboundFlightNumber) && a.ts >= min);
  }

  async init() {
    console.log("init watchlist");
    this.loadSchedule();

    const watchlist = this.sidebarStore.watchlist;
    if (!watchlist)
      return;
    const min = watchlist.start;
    this.isLoadingMore = true;

    this.addAlerts([...watchlist.fakeAlerts]);

    let before: string | undefined;
    do {
      const bunch = await getAlerts({before, limit: 150});
      if (this.disposed)
        return;
      before = last(bunch)?.id;
      const filtered = this.filterAlerts(bunch);
      this.addAlerts(filtered);
      runInAction(() => {
        this.ready = true;
      })
      if (!bunch.length || last(bunch)!.ts <= min)
        break;

    } while (true);
    runInAction(() => {
      this.isLoadingMore = false;
    })
    setTimeout(() => this.sync(), updateRate);
  }

  sync() {
    const {syncStart} = this;
    const params: AlertsParams = {};

    if (syncStart)
      params.until = syncStart;

    getAlerts(params).then(action(newAlerts => {
      if (this.disposed)
        return;
      this.addAlerts(this.filterAlerts(newAlerts));
      this.generateFakeAlerts();
    })).finally(() => {
      if (!this.disposed)
        window.setTimeout(() => this.sync(), updateRate);
    })
  }

  loadSchedule() {
    if(this.disposed)
      return;

    getFlights(now() + watchListLifePeriod).then(items=>{
      if(this.disposed)
        return;
      const schedule = keyBy(items,'flightNumber')
      Object.assign(this.schedule,schedule)
    }).finally(()=>{
      setTimeout(()=>this.loadSchedule(),SCHEDULE_UPDATE_RATE);
    })
  }

  generateFakeAlerts() {
    const {watchlist} = this.sidebarStore;
    if(!watchlist)
      return;

    const min30 = 30*60*1000;
    const min60 = min30*2;
    const ts = now();
    const allStands = this.sidebarStore.root.standsStore.ids;
    const {schedule} = this;

    const alerts: ApronAlert[] = [];

    watchlist.flights.forEach(f=>{
      const info = schedule[f];
      if(!info || allStands.includes(info.standId))
        return;

      if(ts > info.scheduledOffBlockTime - min60) {
        const id = info.flightNumber + '-60min';
        if(!watchlist.fakeAlerts.find(a=>a.id === id)) {
          const alert = this.makeFakeAlert(info.standId,info.scheduledOffBlockTime - min60,f,id);
          alerts.push(alert);
        }
      }
      if(ts > info.scheduledOffBlockTime - min30) {
        const id = info.flightNumber + '-30min';
        if(!watchlist.fakeAlerts.find(a=>a.id === id)) {
          const alert = this.makeFakeAlert(info.standId,info.scheduledOffBlockTime - min30,f,id);
          alerts.push(alert);
        }
      }
    });

    if(alerts.length) {
      watchlist.addFakeAlerts(alerts);
      this.addAlerts(alerts);
    }
  }

  makeFakeAlert(standId: string, ts: number, flightNo: string,id: string): ApronAlert {
    return {
      id,
      data: null,
      fake: true,
      config: {
        active: true,
        severity: 'LOW',
        incidentType: NOT_COVERED_GATE_ALERT_TYPE,
        group: "",
        requireInboundFlight: false,
        requireOutboundFlight: false,
        data: {} as any,
        customText: null
      },
      incidentType: NOT_COVERED_GATE_ALERT_TYPE,
      alertType: NOT_COVERED_GATE_ALERT_TYPE,
      retracted: false,
      ts,
      standId,
      originalStandId: standId,
      outboundFlightNumber: flightNo,
      turnaroundId: ""
    }
  }

  loadMore() {}

  dispose() {
    super.dispose();
    console.log("dispose watchlist");
  }
}