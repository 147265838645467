import {ApronAlertsStore} from "./alertsBase";
import SidebarStore from "../sidebar";
import {AlertsParams} from "../../models/api";
import {getAlerts} from "../../services/api";
import {action} from "mobx";
import * as Sentry from "@sentry/browser";

export class SelectedTurnAlertsRepository extends ApronAlertsStore {
  turnaroundId: string;
  canLoadMore = false;

  constructor(sidebarStore: SidebarStore) {
    super(sidebarStore);
    this.turnaroundId = sidebarStore.root.standStore?.selectedTurnaround?.id || "";
  }

  init() {
    console.log("init turn");
    this.sync();
  }

  async sync() {
    if (!this.turnaroundId) {
      this.ready = true;
      return;
    }

    const params: AlertsParams = {
      turnaround_id: this.turnaroundId,
      limit: 1000
    };

    getAlerts(params).then(action(newAlerts => {
      if (this.disposed)
        return;

      if (newAlerts.length > 900)
        Sentry.captureMessage(`There is a big amount of new alerts: ${newAlerts.length}. Check the limit in params!`);

      this.addAlerts(newAlerts);
      this.ready = true;
    }))
  }

  loadMore() {
  }

  dispose() {
    super.dispose();
    console.log("dispose turn");
  }
}