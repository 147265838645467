import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useHistory} from "react-router-dom";
// @ts-ignore
import KeyboardEventHandler from 'react-keyboard-event-handler';
import {flatten, chunk, uniq} from 'lodash';

import styles from "./style.module.scss";

import Popup from "../Popup";
import plane from "../../images/plane.png";
import {standsDetails} from "../../constants/stands";
import Stand from "../../models/stand";
import {getIcaoByTurn, getStandAlias} from "../../services/data";

import {observer} from "mobx-react";
import {RootContext} from "../../services/react";
import { tourStepIdList } from '../Tour/steps';
import AirlineIcon from '../AirlineIcon';
import classNames from 'classnames';

const MAX_COLUMNS = 3;

function getGroup(id: string) {
  return standsDetails[id]?.terminal || "others";
}

function getLabel(stand: Stand) {
  const alias = getStandAlias(stand.id);
  return alias ? `${stand.label}/${alias}` : stand.label;
}

const StandSelector: React.FC = () => {
  const history = useHistory();
  const rootStore = useContext(RootContext);
  const {sortedStands: stands, selectedStand, activeTurnarounds} = rootStore.standsStore;

  const selectedStandId = rootStore.standStore?.standId;
  const groups = uniq(stands.map(s=>getGroup(s.id))).sort();

  const [selectedGroup, setSelectedGroup] = useState(groups[0]);
  const filteredStands = stands.filter(s=>getGroup(s.id) === selectedGroup);

  useEffect(()=>{
    setSelectedGroup(selectedStandId ? getGroup(selectedStandId) : groups[0])
  },[selectedStandId])

  const onKeyHandle = (key:string,ev:React.KeyboardEvent)=>{
    ev.preventDefault();

    const orderedStands = flatten(groups.map(g=>stands.filter(s=>getGroup(s.id) ===g)));

    const currentIndex = selectedStandId ? orderedStands.findIndex(s=>s.id === selectedStandId) : 0;
    let newIndex = key === 'up' ? currentIndex-1:currentIndex+1;
    if(newIndex < 0)
      newIndex = orderedStands.length-1;
    if(newIndex > orderedStands.length-1)
      newIndex = 0;
    const newStand = orderedStands[newIndex];
    history.push('/' + newStand.id)
  };

  const onSelect = (s: Stand)=>{
    if(selectedStandId !== s.id)
      history.push('/' + s.id);
  };

  const standsInColumn = Math.ceil(filteredStands.length / MAX_COLUMNS)
  const columnedStands = chunk(filteredStands, Math.max(standsInColumn, groups.length));

  const popupContent = (
    <>
      <div className={styles.triangle} />
      <div className={styles.inner}>
        <div
          className={styles.groups}
          onClick={(ev) => {
            ev.stopPropagation();
            ev.nativeEvent.stopImmediatePropagation();
          }}
        >
          {groups.map((g) => (
            <a
              className={classNames(styles.groupBtn, {
                [styles.active]: selectedGroup === g,
              })}
              key={g}
              onClick={() => setSelectedGroup(g)}
            >
              {g}
              <i className="far fa-chevron-right" />
            </a>
          ))}
        </div>
        <div className={styles.stands}>
          {columnedStands.map((c) => (
            <div className={styles.standsColumn}>
              {c.map((s) => (
                <a
                  className={classNames(styles.standBtn, {
                    [styles.active]: selectedStandId === s.id,
                  })}
                  onClick={() => onSelect(s)}
                  key={s.id}
                >
                  <AirlineIcon
                    icaoCode={getIcaoByTurn(activeTurnarounds[s.id])}
                  />
                  <span>{getLabel(s)}</span>
                  {activeTurnarounds[s.id] && <img src={plane} />}
                </a>
              ))}
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      {stands.length > 1 && (
        <KeyboardEventHandler
          handleKeys={["up", "down"]}
          onKeyEvent={onKeyHandle}
        />
      )}

      <Popup
        id={tourStepIdList.standSelect}
        popupContent={popupContent}
        className={styles.standSelectorButton}
        popupClassName={styles.standSelectorPopup}
        closeOnPopupClick={true}
      >
        {selectedStand && (
          <>
            {getLabel(selectedStand)}
          </>
        )}
        {!selectedStand && <>Select stand</>}
        <i className="far fa-chevron-down" />
      </Popup>
    </>
  );
}

export default observer(StandSelector);