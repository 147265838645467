import React, {useContext, useEffect} from 'react';
import cn from "bem-cn";

import Header from "../Header"
import Loader from "../Spinner";
import './style.scss';
import {
  browserNotificationsActivated,
  isIE11,
  isMobile,
  isWindows,
  requestNotificationsPermission
} from "../../services/platform";
import {disableMap, enableBrowserNotifications, enableSidebar} from "../../services/config";
import {startSleepCheck} from "../../services/time";
import SupportButton from "../SupportButton";
import ErrorBoundary from "../ErrorBoundary";
import ErrorMessage from "../ErrorMessage";
import Map from "../Map";
import Logo from "../Logo";
import {COPYRIGHT} from "../../constants/strings";
import MainMenu from "../MainMenu";
import SidebarResizer from "../SidebarResizer";
import {observer} from "mobx-react";
import {Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import Stand from "../Stand";
import {RootContext} from "../../services/react";
import {standsDetails} from "../../constants/stands";
import DebugExport from "../DebugExportModal";
import HotjarManager from "../HotjarManager";
import Sidebar from '../Sidebar/Sidebar';

window.addEventListener('resize',()=>{
  const root = document.getElementById('root');
  if(!root)
    return;
  root.style.height = window.innerHeight + 'px';
})

const b = cn('home');

const Home: React.FC = ()=> {
  const rootStore = useContext(RootContext);
  const {sidebarVisible} = rootStore.sidebarStore;
  const topAlertMessages = rootStore.topAlert;

  useEffect(()=>{
      if(enableBrowserNotifications && !browserNotificationsActivated) {
        requestNotificationsPermission();
      }

    if(process.env.NODE_ENV === 'production')
      startSleepCheck();
  },[]);

  if (rootStore.serverUnavailable && !rootStore.standsStore.standsLoaded)
    return <ErrorMessage className={'error'}/>;

  if (!rootStore.standsStore.standsLoaded || !rootStore.imgToken)
    return (
      <div className={'home'}>
        <Loader/>
      </div>
    );

  return (
    <>
      <ErrorBoundary>
        <div className={b({'win-os': isWindows,'ie11': isIE11, 'sidebar-disabled': !enableSidebar || !sidebarVisible})}>
          {!!topAlertMessages.length && (
            <div className={b("warning")}>
              {topAlertMessages.map((message) => (
                <p>{message}</p>
              ))}
            </div>
          )}

          <Header className={b('header')}/>

          <MainMenu className={b('main-menu')}/>

          {enableSidebar &&
            <>
              <Sidebar className={b('sidebar').toString()}/>
              <SidebarResizer className={b('resizer')}/>
            </>
          }
          {!enableSidebar && (
            <div className={b('footer-logo')}>
              <Logo/>
              <span>{COPYRIGHT}</span>
            </div>
          )}

          <Switch>
            <Route path={'/'} exact>
              {(disableMap || isMobile)
                ? <Redirect to={"/" + rootStore.standsStore.stands[0].id}/>
                : <Map className={b('inner')}/>
              }
            </Route>
            <Route
              path={`/:standId`}
              render={(route: RouteComponentProps<{standId: string}>)=>{
                const {standId} = route.match.params;
                if(rootStore.standsStore.stands.find(s=>s.id === standId))
                  return <Stand className={b('inner')} id={standId}/>

                const details = standsDetails[standId];
                if(details && details.mapConfig.parent)
                  return <Redirect to={'/' + details.mapConfig.parent}/>

                return <Redirect to={'/' + rootStore.standsStore.stands[0].id}/>
              }}
            />
          </Switch>
          <DebugExport/>
          <SupportButton/>
        </div>
      </ErrorBoundary>
      <HotjarManager/>
    </>
  )
};

export default observer(Home);