import React, {useContext, useLayoutEffect, useRef, useState} from 'react';
import "./style.scss"
import styles from "./style.module.scss";
import cn from 'bem-cn';
import { observer } from 'mobx-react';
import ReactTooltip from 'react-tooltip';

import TimeFormatter from '../TimeFormatter';
import {NOT_AVAILABLE} from "../../constants/strings";
import {formatFlightNumber} from "../../services/data";
import Turnaround from "../../models/turnaround";
import StandVideoStream from "../StandVideoStream";
import TurnaroundVideo from "../TurnaroundVideo";
import { tourStepIdList } from '../Tour/steps';
import TurnaroundAlerts from '../TurnaroundAlerts';
import ZoomContainer from "../ZoomContainer";
import { RootContext } from '../../services/react';
import moment from "moment";
import "moment-timezone";
import {disablePOBT} from "../../services/config";

const b = cn('turnaround-info-panel');

const TurnaroundInfoPanel : React.FC<{turnaround: Turnaround}> = ({turnaround}) => {
  const {timezone} = useContext(RootContext);
  const isLive = !turnaround.end;
  const ref = useRef<HTMLDivElement>(null);
  const [collapsed, setCollapsed] = useState(false);

  useLayoutEffect(()=>{
    const resizeObserver = new ResizeObserver(()=>{
      if(ref.current) {
        const collapsedNewVal = ref.current.offsetWidth <= 1200;
        if(collapsedNewVal !== collapsed)
          setCollapsed(collapsedNewVal);
      }
    });
    if(ref.current)
      resizeObserver.observe(ref.current);
    return ()=> {resizeObserver.disconnect()}
  },[collapsed])

  const {inboundFlight,outboundFlight} = turnaround;

  const inboundDay = moment(turnaround.start).tz(timezone).day();
  const outboundDay = turnaround.end ? moment(turnaround.end).tz(timezone).day() : inboundDay;
  const dateTimeFormat = inboundDay !== outboundDay ? "DD MMM HH:mm" : "HH:mm";

  return (
    <div
      id={tourStepIdList.turnaroundPanel}
      className={b({collapsed})}
      ref={ref}
    >
      <ReactTooltip className={styles.turnInfoTooltip} effect={"solid"}/>

      <div className={b('aircraft-type')}>{(inboundFlight || outboundFlight)?.aircraftType || NOT_AVAILABLE}</div>

      {/* <div className={b('time-status')}>in time</div> */}

      <div className={b('inbound-flight')}>
        {inboundFlight ? formatFlightNumber(inboundFlight) || NOT_AVAILABLE : NOT_AVAILABLE}
      </div>
      {inboundFlight && (
        <div className={b('airport-from')}>
          {inboundFlight.departureAirport}
          <i className="far fa-arrow-right"/>
        </div>
      )}

      <i className={"fas fa-circle " + b("flight-divider")}/>

      <div className={b('outbound-flight')}>
        {outboundFlight ? formatFlightNumber(outboundFlight) || NOT_AVAILABLE : NOT_AVAILABLE}
      </div>
      {outboundFlight && (
        <div className={b('airport-to')}>
          <i className="far fa-arrow-right"/>
          {outboundFlight.arrivalAirport}
        </div>
      )}

      <div data-tip="Actual In-Block Time" className={b('ibt-label')}>
        aibt
      </div>
      <div className={b('ibt-value',{big:true})}>
        {inboundFlight?.actualInBlockTime && <TimeFormatter format={dateTimeFormat} time={inboundFlight.actualInBlockTime}/>}
      </div>

      <div data-tip="Scheduled In-Block Time" className={b('ibt-label')}>
        sibt
      </div>
      <div className={b('ibt-value')}>
        {inboundFlight?.scheduledInBlockTime && <TimeFormatter format={dateTimeFormat} time={inboundFlight.scheduledInBlockTime}/>}
      </div>

      {!disablePOBT && (
        <>
          <div
            data-tip="Predicted Off-Block Time"
            className={b('obt-label')}
          >
            pobt
          </div>
          <div
            className={b('obt-value',{big:true})}
          >
            {turnaround.pobt
              ? <TimeFormatter format={dateTimeFormat} time={turnaround.pobt}/>
              : turnaround.outboundFlight && <span className={styles.placeholder}>calculating</span>}
          </div>
        </>
      )}
      <div
        data-tip="Scheduled Off-Block Time"
        className={b('obt-label')}
      >
        sobt
      </div>
      <div
        className={b("obt-value",{big: disablePOBT})}
      >
        {outboundFlight?.scheduledOffBlockTime && (
          <TimeFormatter
            format={dateTimeFormat}
            time={outboundFlight.scheduledOffBlockTime}
          />
        )}
      </div>
      <div data-tip="Actual Off-Block Time" className={b('obt-label')}>
        aobt
      </div>
      <div className={b('obt-value')}>
        {outboundFlight?.actualOffBlockTime && <TimeFormatter format={dateTimeFormat} time={outboundFlight.actualOffBlockTime}/>}
      </div>
      <div data-tip="Estimated Off-Block Time" className={b('obt-label')}>
        eobt
      </div>
      <div className={b('obt-value')}>
        {outboundFlight?.estimatedOffBlockTime && <TimeFormatter format={dateTimeFormat} time={outboundFlight.estimatedOffBlockTime}/>}
      </div>

      <ZoomContainer className={b('video-cell')}>
        {isLive ? <StandVideoStream className={b('video-player')}/> : <TurnaroundVideo  className={b('video-player')}/>}
      </ZoomContainer>

      <TurnaroundAlerts turnaround={turnaround}/>
    </div>
  )


};

export default observer(TurnaroundInfoPanel);