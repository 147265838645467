import React, {useContext} from "react";
import {observer} from "mobx-react";

import {TimelineContext} from "./index";
import {decimalToCSS} from "../../services/dom";
import {now} from "../../services/time";
import CameraOutage from "../../models/cameraOutage";
import {getCameraLabel} from "../../services/data";

type Props = {
  items: CameraOutage[],
  camera: string
}

const OutageRow: React.FC<Props> = ({items,camera})=> {
  const timeline = useContext(TimelineContext);
  const {bounds} = timeline;
  const {isLive,displayedTimestamp} = timeline.standStore;
  if(!timeline)
    return null;
  const {getLeftFromTimestamp} = timeline;
  const maxTs = !isLive && displayedTimestamp ? displayedTimestamp : now();
  const bkWidth = !isLive ? '100%' : decimalToCSS(getLeftFromTimestamp(maxTs));

  const getFillStyle = (outage: CameraOutage) => {
    let start = Math.max(bounds[0],outage.start);

    let end = maxTs;
    if (outage.end) {
      end = Math.min(outage.end, bounds[1]);
    } else if (!isLive) {
      end = bounds[1];
    }

    let style = {
      left: decimalToCSS(getLeftFromTimestamp(start)),
      width: decimalToCSS(getLeftFromTimestamp(end) - getLeftFromTimestamp(start))
    };
    return style;
  };

  return (
    <div className={'row outage-row'}>
      <label style={{left:10}}>{getCameraLabel(camera)}</label>
      <div className={'outage-bk'} style={{width:bkWidth}}/>
      {items.map(i=>(
        <div
          className={'outage-fill'}
          style={getFillStyle(i)}
          key={i.id}
        />
      ))}
    </div>
  )
}

export default observer(OutageRow);