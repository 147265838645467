import React, {useContext, useEffect, useRef} from "react";
import classNames from "classnames";
import { observer } from "mobx-react";
import styles from "./style.module.scss";
import SidebarHeader from "../SidebarHeader";
import SidebarMenu from "../SidebarMenu";
import SidebarContent from "../SidebarContent";
import SidebarFooter from "../SidebarFooter";
import { RootContext } from "../../services/react";
import useOnChanged from "../../hooks/useOnChanged";

type Props = {} & React.HTMLProps<HTMLDivElement>;

const Sidebar: React.FC<Props> = ({ className, style = {} }) => {
  const rootStore = useContext(RootContext);
  const {
    sidebarVisible,
    resizing,
    width: sidebarWidth,
    currentAlertsCount,
    ready,
    alerts,
    tab
  } = rootStore.sidebarStore;

  const watchArgs:[number,number,boolean,string | null] = [currentAlertsCount,alerts.length,ready,tab];
  useOnChanged(([currentCount,allCount,ready,tab],[oldCurrentCount,oldAllCount,oldReady,oldTab]) => {
    const defaultTitle = "Assaia ApronAI";
    const counterTitle = `(${currentCount}) Assaia ApronAI`;
    document.title = currentCount ? counterTitle : defaultTitle;

    if(!ready || (!oldReady && ready) || oldAllCount >= allCount || tab !== oldTab){
      return;
    }

    let count = 0;
    const intervalId = setInterval(()=>{
      document.title =  document.title === defaultTitle ? counterTitle : defaultTitle;
      count++;
      if(count > 10){
        clearInterval(intervalId);
        document.title = counterTitle;
      }
    },1000);

    return () => {
      clearInterval(intervalId);
      document.title = defaultTitle;
    }
  }, watchArgs);

  if (sidebarVisible) style.width = sidebarWidth;

  return (
    <section
      className={classNames(className, styles.sidebar, {
        [styles.hidden]: !sidebarVisible,
        [styles.resizing]: resizing,
      })}
      style={style}
    >
      <SidebarHeader className={styles.header} />
      <SidebarMenu className={styles.menu}/>
      <SidebarContent className={styles.alerts}/>
      <SidebarFooter className={styles.footer} />
    </section>
  );
};

export default observer(Sidebar);
