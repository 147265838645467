import React, { useContext } from "react";
import { observer } from "mobx-react";
import { GateContext } from "./GateContext";
import { textHeight } from "./index";

export const ProgressRect: React.FC = observer(() => {
  const context = useContext(GateContext);
  const turnaround = context.turnaround!;
  const { size, angle, disabled } = context;

  if (!turnaround.progress || disabled) return null;

  const progress = turnaround.progress;
  const txtK = textHeight / size[1];
  const gradValue = (1 - txtK) * progress * 100;
  const gradId = turnaround.id + "-progress-bk";
  const color = "#8cb5e8";

  return (
    <>
      <defs>
        <linearGradient id={gradId} gradientTransform="rotate(90)">
          {Math.abs(angle) <= 90 && (
            <>
              <stop offset="0%" stopColor={"transparent"} />
              <stop offset={100 - gradValue + "%"} stopColor={"transparent"} />
              <stop offset={100 - gradValue + "%"} stopColor={color} />
              <stop offset="100%" stopColor={color} />
            </>
          )}
          {Math.abs(angle) > 90 && (
            <>
              <stop offset="0%" stopColor={"transparent"} />
              <stop offset={txtK * 100 + "%"} stopColor={"transparent"} />
              <stop offset={txtK * 100 + "%"} stopColor={color} />
              <stop offset={txtK * 100 + gradValue + "%"} stopColor={color} />
              <stop
                offset={txtK * 100 + gradValue + "%"}
                stopColor={"transparent"}
              />
              <stop offset="100%" stopColor={"transparent"} />
            </>
          )}
        </linearGradient>
      </defs>
      <rect
        x={-size[0] / 2}
        y={-size[1] / 2}
        rx={2}
        width={size[0]}
        height={size[1]}
        style={{ fill: `url(#${gradId})` }}
      />
    </>
  );
});
