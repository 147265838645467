import {camelCase, groupBy, orderBy} from 'lodash';
import {Flight} from "../models/flightInfo";
import Detection from "../models/detection";
import DetectionEvent from "../models/event";
import {camerasLabels, standsAliases, standsLabels} from "./config";
import {standsDetails} from "../constants/stands";
import airlines from "../constants/airlines";
import Turnaround from "../models/turnaround";
import {resetTime} from "./time";
import ApronAlert from "../models/apronAlert";
import {customAlertsLabels, notificationStartLabels, notificationTriggerLabels} from "../constants/alerts";
import BBox, { BBoxList } from '../models/bbox';

export function camelCaseKeys(obj: any):any {
  return Object.keys(obj).reduce((ccObj, field) => ({
    ...ccObj,
    [camelCase(field)]: obj[field] && typeof obj[field] === 'object'? camelCaseKeys(obj[field]) : obj[field]
  }), {})
}

export function convertObjUtcToMillseconds(obj: any):any {
  for(let key in obj){
    if(typeof obj[key] === 'number')
      obj[key] = obj[key]*1000;
  }
  return obj;
}

export function detectionToEvents(detection: Detection): DetectionEvent[] {
  let res: DetectionEvent[] = [
    {
      id: detection.id + '-start',
      type: detection.startType,
      timestamp: detection.start,
      label: detection.startLabel,
      confidence: detection.startConfidence,
      detectionGap: detection.startDetectionGap,
      detection
    }
  ]

  if(detection.end && detection.endType){
    res.push({
      id: detection.id + '-end',
      type: detection.endType,
      timestamp: detection.end,
      label: detection.endLabel,
      confidence: detection.endConfidence,
      detectionGap: detection.endDetectionGap,
      detection
    })
  }

  return res;
}

export function formatFlightNumber(info: Flight) {
  let res = '';
  let airline = info.companyIata || info.airline;
  res += airline;
  res += info.flightNumber;
  return res.toUpperCase();
};

export function getStandLabel(id:string) {
  let label = typeof standsLabels === 'function' ? standsLabels(id) : standsLabels[id];
  return (label || id).toUpperCase();
}

export function getStandAlias(id:string) {
  let alias = typeof standsAliases === 'function' ? standsAliases(id) : standsAliases[id];
  return alias?.toUpperCase();
}

export function getAirlineIcon(airline?: string): string | undefined {
  if (!airline)
    return airlines.noAirline;

  const icon = airlines[airline];
  if (!icon) {
    // TODO: Send not found ICAO via Sentry
    console.log("NOT FOUND AIRLINE CODE", airline);
    return airlines.noAirline;
  }

  return airlines[airline];
}

export function getCameraLabel(id:string) {
  let label = typeof camerasLabels === 'function' ? camerasLabels(id) : camerasLabels[id];
  return (label || id).toUpperCase();
}

export function groupTurnaroundsByStartDate(turns: Turnaround[], timezone?: string) : [string,Turnaround[]][] {
  const groups = groupBy(turns, t => resetTime(t.start, timezone));
  return orderBy(Object.entries(groups), [g => g[0]], ['desc']);
}

export const turnaroundDuration = (turn: Turnaround) =>
  Math.abs((turn.end || Date.now()) - turn.start);

export const getAirlineFromFlightNumber = (flightNumber?: string) => {
  const regex = /[a-z0-9]{2}[a-z]?/i;
  const airline = regex.exec(flightNumber || "");
  return airline ? airline[0] : "";
}

export const getIcaoByTurn = (turn?: Turnaround) => {
  return turn?.outboundFlight?.companyIata || turn?.inboundFlight?.companyIata;
}

const inRangeTypes = ['detection_in_range','all_detections_in_range','any_detections_in_range'];

export const parseAlert = (item:ApronAlert) => {
  if (!inRangeTypes.includes(item.incidentType))
    return;

  const {data, config} = item;

  const {trigger, timeout, startTimer} = config.data;
  if(!data)
    return;

  const alertMessageCount = data.triggeredEvents.length;

  let result = "";
  data.triggeredEvents.forEach(({objectName,eventName}, index) => {
    const objName = objectName.replace(/_/g,' ');
    const evName = eventName
      .replace(/_/g,' ')
      .replace('connected','connection')
      .replace('arrived','arrival')
      .replace('deployed','deployment')
      .replace(/ed\b/gi,"ing");

    let message = `${objName} ${evName}`;
    message = message.replace('ac inflating','inflation of PC air hose')
    if (index === 0) {
      result += message;
      return;
    }

    if (index > 0 && index === alertMessageCount - 1) {
      result += ` and ${message}`;
      return;
    }

    result += `, ${message}`;
  })

  let triggerMessage = notificationTriggerLabels[trigger];
  if (alertMessageCount > 1 && triggerMessage === notificationTriggerLabels.not_happened) {
    triggerMessage = triggerMessage.replace("was", "were");
  }

  return {
    event: result,
    triggerMessage,
    minutes: timeout/60,
    notificationStartLabel: notificationStartLabels[startTimer]
  }
}

export function getAlertLabel(item:ApronAlert): string {
  const {config} = item;
  if(config.customText)
    return config.customText;

  const parsed = parseAlert(item);
  if (!parsed)
    return customAlertsLabels[item.incidentType] || "";
 
  const {
    event,
    triggerMessage,
    minutes,
    notificationStartLabel
  } = parsed;

  return `${event} ${triggerMessage} within ${minutes} minutes ${notificationStartLabel}`;
}

export const mergeRefs = <T extends Element = HTMLDivElement>(
  ...refs:
    (((instance: T | null) => void)
    | React.MutableRefObject<T | null>
    | null)[]
) => {
  const filteredRefs = refs.filter(Boolean);
  if (!filteredRefs.length) return null;
  if (filteredRefs.length === 0) return filteredRefs[0];
  return (inst: T) => {
    for (const ref of filteredRefs) {
      if (typeof ref === "function") {
        ref(inst);
      } else if (ref) {
        ref.current = inst;
      }
    }
  };
};

export const getCb = (fn?: (e?: Event) => void) => fn ? ((e: Event) => fn(e)) : null;

const BOOL_TRUE = "true";
const BOOL_FALSE = "false";

export function writeBooleanToLocalStorage(key:string, value: boolean) {
  localStorage.setItem(key, value ? BOOL_TRUE : BOOL_FALSE);
}

export function readBooleanFromLocalStorage<T = boolean>(
  key: string,
  def: T | boolean = false
): T | boolean {
  const raw = localStorage.getItem(key);

  if (raw === BOOL_TRUE)
    return true;
  if (raw === BOOL_FALSE)
    return false;

  return def;
}

export const getBBoxByCamera = (bboxes: BBoxList, targetCamera: string): BBox | undefined => {
  return Object.values(bboxes).find((v) => v.camera === targetCamera);
}