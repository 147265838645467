import {useEffect, useState, createContext, useRef, useContext} from "react";
import {now} from "./time";
import {RootStore} from "../stores/root";
import StandStore from "../stores/stand";

interface Props {
  disabled?: boolean;
}

export default function useAutoRefresh({disabled}: Props = {}) {
  const rootStore = useContext(RootContext);
  return [disabled ? 0 : rootStore.time];
}

export const RootContext = createContext<RootStore>({} as RootStore);

export const StandContext = createContext<StandStore>({} as StandStore);

