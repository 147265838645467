import React, {useContext, useEffect, useRef, useState} from "react";
import DetectionBar from "./DetectionBar";
import PtsBar from "./PtsBar";
import {decimalToCSS} from "../../services/dom";
import {TimelineContext} from "./index";
import Detection from "../../models/detection";
import {formatTime} from "../../services/time";
import {observer} from "mobx-react";
import {DetectionEvent} from "../../stores/timeline";
import useAutoRefresh from "../../services/react";

type Props = {
  items: (Detection | DetectionEvent)[]
}

const OperationRow: React.FC<Props> = ({items}) => {
  const rowRef = useRef<HTMLDivElement>(null);
  const [hoveredDetection,setHoveredDetection] = useState<Detection | null>(null)
  const timeline = useContext(TimelineContext);
  const {timezone} = timeline.standStore.root;
  const type = items[0].type;
  let labelLeft = timeline ? timeline.getLeftFromTimestamp(Math.min(...items.map(i=>i.start))) : 0;
  let labelLeftCss = labelLeft < 0 ? '4px' : decimalToCSS(labelLeft);

  useAutoRefresh({
    disabled: !timeline.standStore.isLive,
  });

  useEffect(()=>{
    if(!rowRef.current)
      return;
    const index = hoveredDetection ? items.indexOf(hoveredDetection) : 0;
    const detectionBar = rowRef.current.querySelectorAll(`.detection-bar`)[index];
    const label = rowRef.current.querySelector('label');
    const axis = document.querySelector('.timeaxis');
    if(!detectionBar || !label || !axis)
      return;
    const barRect = detectionBar.getBoundingClientRect();
    const labelRect = label.getBoundingClientRect();
    const axisRect = axis.getBoundingClientRect();

    let left = barRect.left - axisRect.left;
    let overlap = axisRect.right - (barRect.left + labelRect.width);
    if(overlap<0)
      left += overlap;
    else if(left < 0)
      left = 0;

    label.style.left = left + 'px';
  });

  //TODO use time formatter
  const getDetectionRangeText = (detection:Detection) => {
    return `${formatTime(detection.start,timezone,false,false)} - ${detection.end ? formatTime(detection.end,timezone,false,false) : 'in progress'}`
  };

  return (
    <div className={'row operation-row'} ref={rowRef}>
      <label style={{left:labelLeftCss}}>
        {items[0].label}
        {hoveredDetection && <span className={'detection-range'}>{getDetectionRangeText(hoveredDetection)}</span>}
      </label>
      {items.map(i=>(
        <DetectionBar
          key={i.id}
          detection={i}
          onMouseEnter={()=>setHoveredDetection(i)}
          onMouseLeave={()=>setHoveredDetection(null)}
        />
      ))}
      {/*{pts && pts[type] && <PtsBar type={type}/>}*/}
    </div>
  )
};

export default observer(OperationRow);