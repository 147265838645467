import {NOT_COVERED_GATE_ALERT_TYPE} from "./strings";

export const notificationTriggerLabels = {
  happened: 'happened',
  not_happened: 'was not detected'
}
export const notificationStartLabels = {
  after_turnaround_start: 'after turnaround start',
  before_turnaround_end: 'before scheduled off-block time',
}
export const customAlertsLabels: { [k: string]: string } = {
  "stairs-late": "Offboarding has not started within 3 minutes of aircraft stationary. ",
  "belt-or-highloader-late": "Baggage / cargo unloading has not commenced within 5 minutes of aircraft stationary. ",
  "gp-late": "Ground power has not been connected within 4 minutes of aircraft stationary. ",
  "chocks-not-applied": "Chocks not applied after aircraft arrival. ",
  "cargo-late": "Baggage / cargo unloading has not commenced in time. ",
  "_stairs-late": "Offboarding has not started within 3 minutes of aircraft stationary. ",
  "_belt-or-highloader-late": "Baggage / cargo unloading has not commenced within 5 minutes of aircraft stationary. ",
  "_gp-late": "Ground power has not been connected within 4 minutes of aircraft stationary. ",
  "_chocks-not-applied": "Chocks not applied after aircraft arrival. ",
  "_cargo-late": "Baggage / cargo unloading has not commenced in time. ",
  [NOT_COVERED_GATE_ALERT_TYPE]: "This flight from your watchlist is being handled at a gate which is currently not yet covered by the ApronAI system. Please monitor turnaround progress manually"
}