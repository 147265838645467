import React, {useContext, useEffect, useMemo, useState} from 'react';

import Spinner from "../Spinner";
import ErrorBoundary from "../ErrorBoundary";
import Turnaround from '../Turnaround';

import ErrorMessage from "../ErrorMessage";
import {PropsWithClass} from "../../models/common";

import StandStore from "../../stores/stand";
import {RootContext, StandContext} from "../../services/react";
import { observer } from 'mobx-react';
import {Redirect, Route, RouteComponentProps, Switch } from 'react-router-dom';
import {EmptyApron} from "../EmptyApron";
import FrameModal from "../FrameModal";

const Stand:React.FC<PropsWithClass & {id:string}> = ({className,id}) => {
  const rootStore = useContext(RootContext);
  const {serverUnavailable} = rootStore;

  const store = useMemo(()=>new StandStore(rootStore,id),[id]);
  useEffect(()=>{
    return ()=>store.dispose()
  },[store]);

  if(serverUnavailable && !store.turnaroundsListLoaded)
    return <ErrorMessage/>;

  return <>
    <StandContext.Provider value={store}>
      {store.frameModalData && <FrameModal/>}
      <ErrorBoundary>
        <Switch>
          <Route path="/:standId" exact>
            {store.turnaroundsListLoaded ? <EmptyApron className={className}/> : <Spinner/>}
          </Route>
          <Route
            path="/:standId/:turnId?"
            render={(props:RouteComponentProps<{turnId:string}>)=>{
              return <Turnaround className={className} key={props.match.params.turnId}/>
            }}
          />
        </Switch>
      </ErrorBoundary>
    </StandContext.Provider>
  </>;
}

export default observer(Stand);
