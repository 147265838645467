import React, {
  ReactElement,
  forwardRef,
  useRef,
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
} from "react";
import classNames from "classnames";
import { createPortal } from "react-dom";
import { ReactComponent as CloseSvg } from "../../svg/close.svg";
import "./style.scss";
import { useHammer } from "../../hooks/useHammer";
import { mergeRefs } from "../../services/data";
import { clamp } from "lodash";
import { useWindowEventListener } from "../../hooks/useWindowEventListener";
import { useResizeObserver } from "../../hooks/useResizeObserver";
import { tourStepIdList } from "../Tour/steps";

type Props = {
  onClose: () => void;
  className?: string;
  modalClassName?: string;
  style?: React.CSSProperties;
  modalTitle?: string;
  movable?: boolean;
  resizable?: boolean;
  /**
   * Show backdrop with dark background
   */
  backdrop?: boolean;
  /**
   * Close modal if click was outside
   */
  closeOnOutside?: boolean;
} & React.HTMLProps<HTMLDivElement>;

const Modal: React.ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    children,
    onClose,
    className,
    modalClassName,
    style = {},
    id,
    movable = false,
    resizable = false,
    backdrop = true,
    closeOnOutside = true,
    modalTitle,
  },
  ref
) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);

  const [delta, setDelta] = useState({
    deltaX: 0,
    deltaY: 0,
  });
  const [currentOffset, setCurrentOffset] = useState({
    left: window.innerWidth / 2,
    top: window.innerHeight / 2,
  });
  const [grabbing, setGrabbing] = useState(false);
  const resizing = useRef(false);

  const [modalSize, setModalSize] = useState({
    width: 0,
    height: 0,
  });
  const modalSizeRef = useRef({
    width: 0,
    height: 0,
  });

  const initialModalSizeRef = useRef({
    width: 0,
    height: 0,
  });

  const [deltaModalSize, setDeltaModalSize] = useState({
    deltaX: 0,
    deltaY: 0,
  });

  const root = document.querySelector(".home");
  const closeAfterMouseUp = useRef<boolean>(false);
  const clickedOnModal = useRef<boolean>(false);

  const onMouseDown = (ev: React.MouseEvent) => {
    closeAfterMouseUp.current = true;
  };

  const onMouseUp = (ev: React.MouseEvent) => {
    if (closeAfterMouseUp.current) {
      closeAfterMouseUp.current = false;

      if (closeOnOutside) {
        onClose();
      }
    }
  };

  const getModalSize = () => {
    if (modalRef.current) {
      const { width, height } = modalRef.current.getBoundingClientRect();
      return { width, height };
    }

    return { width: 0, height: 0 };
  };

  useLayoutEffect(() => {
    const size = getModalSize();
    updateModalSize(size);

    if (resizable) {
      // This centers modal on first render
      updateOffset(-size.width / 2, -size.height / 2);
    }

    if (!initialModalSizeRef.current.width) {
      initialModalSizeRef.current = size;
    }
  }, []);

  const updateOffset = (deltaX: number = 0, deltaY: number = 0) => {
    setCurrentOffset((prev) => {
      const currentSize = getModalSize();

      const left = clamp(
        prev.left + deltaX,
        0,
        window.innerWidth - currentSize.width
      );

      const top = clamp(
        prev.top + deltaY,
        0,
        window.innerHeight - currentSize.height
      );

      return {
        left,
        top,
      };
    });
  };

  useHammer({
    ref: headerRef,
    onPanMove: ({ deltaX, deltaY }) => {
      if (!movable)
        return;

      setGrabbing(true);
      setDelta({ deltaX, deltaY });
    },
    onPanEnd: ({ deltaX, deltaY }) => {
      if (!movable)
        return;

      setGrabbing(false);
      setDelta({
        deltaX: 0,
        deltaY: 0,
      });
      updateOffset(deltaX, deltaY);
    },
  });

  useHammer({
    ref: resizerRef,
    onPanMove: ({ deltaX, deltaY }) => {
      setDeltaModalSize({ deltaX, deltaY });

      resizing.current = true;
      root && root.classList.add("no-user-select");
    },
    onPanEnd: ({ deltaX, deltaY }) => {
      resizing.current = false;
      root && root.classList.remove("no-user-select");

      setDeltaModalSize({
        deltaX: 0,
        deltaY: 0,
      });

      if (!modalRef.current)
        return;

      const computedStyle = window.getComputedStyle(modalRef.current);

      let minWidth = +computedStyle.getPropertyValue("min-width").split("px")[0];
      if (isNaN(minWidth))
        minWidth = initialModalSizeRef.current.width;

      let minHeight = +computedStyle.getPropertyValue("min-height").split("px")[0];
      if (isNaN(minHeight))
        minHeight = initialModalSizeRef.current.height;

      const {left, top} = modalRef.current.getBoundingClientRect();
      updateModalSize({
        width: clamp(modalSizeRef.current.width + deltaX, minWidth, window.innerWidth - left),
        height: clamp(modalSizeRef.current.height + deltaY, minHeight, window.innerHeight - top),
      })

      updateOffset();
    },
  });

  const updateModalSize = (size: typeof modalSize) => {
    setModalSize(size);
    modalSizeRef.current = size;
  }

  const isClickedOutsideModal = (e: PointerEvent): boolean => {
    if (!modalRef.current)
      return true;

    const { left, right, top, bottom } = modalRef.current.getBoundingClientRect();
    return e.clientX < left ||
      e.clientX > right ||
      e.clientY < top ||
      e.clientY > bottom;
  }

  useWindowEventListener({
    onKeyDown: (e) => {
      if (e.code === "Escape")
        onClose()
    },
    onWindowResize: () => {
      updateOffset();
      updateModalSize(getModalSize());
    },
    onDocumentMouseDown: (e) => {
      if (!e || !modalRef.current)
        return;

      if (!isClickedOutsideModal(e))
        clickedOnModal.current = true;
    },
    onDocumentClick: (e) => {
      if (!e || !modalRef.current || backdrop || !closeOnOutside || resizing.current) {
        clickedOnModal.current = false;
        return;
      }

      const isClickedOutsideWindow = 
        e.clientX < 0 ||
        e.clientX > window.innerWidth ||
        e.clientY < 0 ||
        e.clientY > window.innerHeight;

      if (!isClickedOutsideWindow && isClickedOutsideModal(e) && !clickedOnModal.current) {
        onClose();
      }

      clickedOnModal.current = false;
    },
  });

  const [observeModalResize] = useResizeObserver({
    onResize: () => {
      if (resizing.current)
        return;

      const size = getModalSize();
      if (modalSizeRef.current.width !== size.width || modalSizeRef.current.height !== size.height) {

        updateOffset();
        updateModalSize(size);
      }
    } 
  })

  const left = clamp(
    currentOffset.left + delta.deltaX,
    0,
    window.innerWidth - modalSize.width
  );
  const top = clamp(
    currentOffset.top + delta.deltaY,
    0,
    window.innerHeight - modalSize.height
  );

  if (movable) {
    // Clamp in style guarantees that window will be inside the viewport
    style.left = `clamp(0px, ${left}px, calc(100% - ${modalSize.width}px))`;
    style.top = `clamp(0px, ${top}px, calc(100% - ${modalSize.height}px))`;
  }

  if (resizable) {
    if (modalSize.width)
      style.width = Math.min(modalSize.width + deltaModalSize.deltaX, window.innerWidth - left);
    if (modalSize.height)
      style.height = Math.min(modalSize.height + deltaModalSize.deltaY, window.innerHeight - top);
  }
  

  if (!root) return null;
  return createPortal(
    <div
      className={classNames(["modal-container", className, { backdrop }])}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
    >
      <div
        id={id}
        className={classNames(["modal", modalClassName, { resizable }, { movable }])}
        style={style}
        onMouseDown={(ev) => ev.stopPropagation()}
        ref={mergeRefs(ref, modalRef, observeModalResize)}
      >
        <section
          id={tourStepIdList.modalCloseBtn}
          ref={headerRef}
          className={classNames(["modal-header", { movable }, { grabbing }])}
        >
          {modalTitle}
          <a className={"close-btn"} onClick={onClose}>
            <CloseSvg />
          </a>
        </section>

        {resizable && (
          <div ref={resizerRef} className={"resize-btn"}>
            <i className="icon fas fa-caret-right"></i>
          </div>
        )}

        {children}
      </div>
    </div>,
    root
  );
};

export default forwardRef(Modal);
