import React from "react";
import "./style.scss";
import classNames from "classnames";
import {PropsWithClass} from "../../models/common";

type Props = React.HTMLProps<HTMLElement>;

const Spinner: React.FC<Props> = ({ className, style }) => {
  return <div style={style} className={classNames("spinner", className)} />;
};

export default Spinner;
