import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import classNames from "classnames";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
// @ts-ignore
import KeyboardEventHandler from "react-keyboard-event-handler";
import moment from "moment";

import "./style.scss";
import styles from "./style.module.scss";

import { showStandClear } from "../../services/config";
import { formatTime } from "../../services/time";
import Modal from "../Modal";
import FrameUnavailable from "../FrameUnavailable";
import Frame from "../Frame";
import BBox from "../BBox";
import TimeFormatter from "../TimeFormatter";
import { detectionToEvents, getBBoxByCamera, getCameraLabel } from "../../services/data";
import Detection from "../../models/detection";
import Turnaround from "../../models/turnaround";
import {
  MODAL_DETECTION_ID_QUERY_KEY,
  MODAL_TS_QUERY_KEY,
} from "../../constants/strings";
import { StandContext } from "../../services/react";
import StandStore from "../../stores/stand";
import { tourStepIdList } from "../Tour/steps";
import { ReactComponent as BackwardSvg } from "../../svg/backward.svg";
import { ReactComponent as FastBackwardSvg } from "../../svg/fast-backward.svg";
import { ReactComponent as EventPlaySvg } from "../../svg/event-play.svg";
import { ProgressBar } from "./ProgressBar";

type ModalState = {
  camera: string;
  step: number;
  failCounter: number;
  timestamp: number;
  unavailable: boolean;
  // events: DetectionEvent[];
  loaded: boolean;
  detection?: Detection;
};

type Props = RouteComponentProps;

class FrameModal extends Component<Props, ModalState> {
  static contextType = StandContext;
  context!: StandStore;

  modalRef = React.createRef<HTMLDivElement>();

  constructor(props: Props, context: StandStore) {
    super(props, context);

    const { detection, timestamp } = context.frameModalData!;

    const state: ModalState = {
      camera: context.cameras[0],
      step: -1000,
      failCounter: 0,
      timestamp,
      unavailable: false,
      loaded: false,
    };

    if (detection) {
      state.detection = detection;
      for (const camera of Object.keys(detection.bboxes)) {
        if (context.cameras.includes(camera)) {
          state.camera = camera;
          break;
        }
      }
    }

    this.state = state;
  }

  componentDidMount() {
    this.updateUrl();
    this.updateWidth();
  }

  componentWillUnmount() {
    this.props.history.push(this.props.location.pathname);
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<ModalState>,
    snapshot?: any
  ) {
    if (this.state.timestamp !== prevState.timestamp) this.updateUrl();
    this.updateWidth();
  }

  updateUrl() {
    let search = `?${MODAL_TS_QUERY_KEY}=${moment(this.state.timestamp)
      .utc()
      .format()}`;
    if (this.state.detection)
      search += `&${MODAL_DETECTION_ID_QUERY_KEY}=${this.state.detection.id}`;
    this.props.history.push(this.props.location.pathname + search);
  }

  updateWidth() {
    const modalDiv = this.modalRef.current;

    if (!modalDiv) return;
    const delta = modalDiv.scrollHeight - modalDiv.offsetHeight;
    if(delta <= 0) return;

    const frame: HTMLDivElement | null = modalDiv.querySelector('.' + styles.frame);
    if(!frame) return;

    const height = frame.offsetHeight - delta;
    frame.style.height = height + 'px';
  }

  selectCamera(camera: string) {
    if (this.state.camera !== camera)
      this.setState({ camera, failCounter: 0, unavailable: false });
  }

  next(ev: React.KeyboardEvent | React.MouseEvent, x: number) {
    ev.stopPropagation();
    let { timestamp, camera } = this.state;
    const { isLive, lastImageTimestamp } = this.context;
    const maxTs = lastImageTimestamp && lastImageTimestamp[camera];
    if (!maxTs) {
      return;
    }
    if (ev.shiftKey) x = 10;
    timestamp = timestamp + 1000 * x;

    if ((isLive && timestamp > maxTs) || timestamp > Date.now()) return;
    this.selectTimestamp(timestamp, 1000 * x);
  }

  prev(ev: React.KeyboardEvent | React.MouseEvent, x: number) {
    ev.stopPropagation();
    if (ev.shiftKey) x = 10;
    let ts = this.state.timestamp;
    ts = ts - 1000 * x;
    this.selectTimestamp(ts, -1000 * x);
  }

  selectTimestamp(timestamp: number, step: number = -1000) {
    if (timestamp !== this.state.timestamp)
      this.setState({
        timestamp,
        step,
        loaded: false,
        failCounter: 0,
        unavailable: false,
      });
  }

  onFail() {
    let { failCounter, timestamp, step } = this.state;
    failCounter++;
    if (failCounter < 3) {
      timestamp += step;
      this.setState({ timestamp, failCounter });
    } else this.setState({ unavailable: true });
  }

  isTimestampInsideTurn() {
    const { timestamp } = this.state;
    const turnaround = this.context.selectedTurnaround as Turnaround;
    const { start, end } = turnaround;

    if (start < timestamp && (!end || timestamp < end)) return true;
    return false;
  }

  isStandClear() {
    const { timestamp } = this.state;
    const { detections } = this.context;
    let standNotClear =
      timestamp &&
      detections.find(
        (d) =>
          d.type === "stand_not_clear" &&
          ((!d.end && d.start < timestamp) ||
            (d.start < timestamp && d.end && timestamp < d.end))
      );
    if (standNotClear) return false;
    return true;
  }

  enableShowStandClear() {
    const { standId } = this.context;
    if (showStandClear.includes(standId)) return true;
    return false;
  }

  close = () => {
    this.context.closeModal();
  };

  renderAlerts() {
    const { detection } = this.context.frameModalData!;

    if (detection?.confidence && detection.confidence < 0.6)
      return (
        <div className="alert warning">
          <i className="far fa-exclamation-triangle" />
          ATTENTION! LOW CONFIDENCE{" "}
          {`(${Math.round(detection.confidence * 100)}%)`}
        </div>
      );
    if (
      this.enableShowStandClear() &&
      this.isStandClear() &&
      !this.isTimestampInsideTurn() &&
      !detection
    )
      return (
        <div className="alert success">
          <i className="fas fa-check" />
          Stand clear
        </div>
      );
    if (
      this.enableShowStandClear() &&
      !this.isStandClear() &&
      !this.isTimestampInsideTurn() &&
      !detection
    )
      return (
        <div className="alert error">
          <i className="far fa-exclamation-triangle" />
          Stand not clear
        </div>
      );
    return null;
  }

  renderProgressBar() {
    const { detection, timestamp } = this.state;
    const { selectedTurnaround } = this.context;

    if (!selectedTurnaround || !detection) return null;

    return (
      <ProgressBar
        detection={detection}
        timestamp={timestamp}
        turnaround={selectedTurnaround}
        selectTimestamp={(ts: number) => this.selectTimestamp(ts)}
      />
    );
  }

  renderToolbar() {
    const { timestamp } = this.state;

    return (
      <div className="toolbar">
        <KeyboardEventHandler
          handleKeys={["left", "right", "shift+left", "shift+right"]}
          isExclusive={true}
          onKeyEvent={(key: string, ev: React.KeyboardEvent) =>
            ev.key === "ArrowLeft" ? this.prev(ev, 1) : this.next(ev, 1)
          }
        />
        <a
          id={tourStepIdList.modalPrev10Btn}
          className={classNames("prev10", styles.hoverable)}
          onClick={(ev: React.MouseEvent) => this.prev(ev, 10)}
        >
          <FastBackwardSvg />
        </a>
        <a
          id={tourStepIdList.modalPrevBtn}
          className={classNames("prev", styles.hoverable)}
          onClick={(ev) => this.prev(ev, 1)}
        >
          <BackwardSvg />
        </a>

        <span className={styles.dateTime}>
          <div className={styles.date}>
            <TimeFormatter format={"DD MMM YYYY"} time={timestamp} />
          </div>
          <div className={styles.time}>
            <TimeFormatter date={false} time={timestamp} />
          </div>
        </span>

        <a
          id={tourStepIdList.modalNextBtn}
          className={classNames("next", styles.hoverable)}
          onClick={(ev: any) => this.next(ev, 1)}
        >
          <BackwardSvg className={styles.flipped} />
        </a>
        <a
          id={tourStepIdList.modalNext10Btn}
          className={classNames("next10", styles.hoverable)}
          onClick={(ev: any) => this.next(ev, 10)}
        >
          <FastBackwardSvg className={styles.flipped} />
        </a>
      </div>
    );
  }

  renderEventsInfo() {
    const {detection} = this.state;
    if(!detection) return null;
    const events = detectionToEvents(detection).filter((e) => e.label);
    if (!events.length) return null;

    return (
      <table className="events-info">
        <thead>
          <tr>
            <th>event</th>
            <th>conf</th>
            <th>timestamp</th>
            <th></th>
          </tr>
        </thead>
        {events.map(({ timestamp, label, confidence }) => (
          <tr key={label}>
            <td id={tourStepIdList.modalEventName} className="label">
              {label}
            </td>
            <td className="confidence">
              {confidence ? (
                <span className={confidence < 0.8 ? "low" : ""}>
                  {Math.round(confidence * 100) + "%"}
                </span>
              ) : (
                ""
              )}
            </td>
            <td id={tourStepIdList.modalEventDateTime} className="date-time">
              <div className="date">
                <TimeFormatter format={"DD MMM YYYY"} time={timestamp} />
              </div>
              <div className="time">
                <TimeFormatter date={false} time={timestamp} />
              </div>
            </td>
            <td className="action">
              <a
                className={styles.hoverable}
                onClick={() => this.selectTimestamp(timestamp)}
              >
                <EventPlaySvg />
              </a>
            </td>
          </tr>
        ))}
      </table>
    );
  }

  render() {
    let { camera, detection, timestamp, unavailable, loaded } = this.state;
    const { cameras } = this.context;

    let bbox;
    if (
      detection &&
      detection.start <= timestamp &&
      (!detection.end || timestamp <= detection.end)
    ){
      bbox = getBBoxByCamera(detection.bboxes, camera);
    }

    return (
      <Modal
        onClose={this.close}
        className={"frame-modal"}
        ref={this.modalRef}
        modalTitle={detection && detection.label}
      >
        {this.renderEventsInfo()}

        <div className={"modal-section-title"}>
          video
          <div id={tourStepIdList.modalCameras} className={"cameras"}>
            {cameras.length > 1 &&
              cameras.map((c) => (
                <a
                  className={classNames({ active: c === camera })}
                  onClick={() => this.selectCamera(c)}
                  key={c}
                >
                  {getCameraLabel(c)}
                </a>
              ))}
          </div>
        </div>

        {!unavailable && (
          <Frame
            id={tourStepIdList.modalFrame}
            timestamp={timestamp}
            camera={camera}
            onFail={() => this.onFail()}
            onSuccess={() => this.setState({ loaded: true })}
            className={styles.frame}
          >
            {loaded && bbox && (
              <BBox bbox={bbox} hiresEnabled={true}/>
            )}
          </Frame>
        )}

        {unavailable && (
          <div className={styles.frameUnavailableWrapper}>
            <FrameUnavailable
              msg={`Image at \n${formatTime(
                timestamp,
                this.context.root.timezone
              )}\n is unavailable.`}
            />
          </div>
        )}

        {this.renderProgressBar()}

        {this.renderToolbar()}

        {this.renderAlerts()}
      </Modal>
    );
  }
}

export default withRouter(observer(FrameModal));
