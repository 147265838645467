import React, {Component, createRef, UIEventHandler} from 'react';
import {IBlock} from 'bem-cn';
import {last, throttle} from "lodash";
import {observer} from "mobx-react";

import './style.scss';
import {formatFlightNumber, getStandLabel} from "../../services/data";
import TimeFormatter from "../TimeFormatter";
import Turnaround from "../../models/turnaround";
import {NavLink} from 'react-router-dom';
import {Flight} from "../../models/flightInfo";
import CalendarFormatter from "../CalendarFormatter";
import AirlineIcon from '../AirlineIcon';

type Props = {
  b: IBlock,
  groups: TurnaroundsGroup[]
  loading: boolean,
  query?: string,
  onScroll?: (ev: React.UIEvent)=>void,
  onScrolledToEnd?: ()=>void
}

export type TurnaroundsGroup = {
  label?: string,
  date?: number
  items: (Turnaround | null | {label:string, href: string})[],
  hideCounter?: boolean
}

export class GenericTurnaroundsList extends Component<Props> {
  scrollRef = createRef<HTMLDivElement>()
  scrollLocked = false;

  componentDidMount() {
    const selectedClass = this.props.b('turnaround') + '_active';
    const elem = this.scrollRef.current?.querySelector('.' + selectedClass);
    if(elem){
      //list is not present unless we wait some
      setTimeout(()=>{
        elem.scrollIntoView(false);
      })
    }
  }

  startScrollPreserving() {
    this.scrollLocked = true;
    const el = this.scrollRef.current;
    if(!el)
      return;
    let scrollHeight = 0;

    const fn = () => {
      if(!this.scrollLocked)
        return;
      if(scrollHeight) {
        const scrollDiff = el.scrollHeight - scrollHeight;
        el.scrollTop += scrollDiff;
      }
      scrollHeight = el.scrollHeight;
      requestAnimationFrame(fn);
    }
    fn();
  }

  stopScrollPreserving() {
    this.scrollLocked = false;
  }

  onScroll = (ev: React.UIEvent) => {
    const el = this.scrollRef.current;
    if(!el)
      return;

    if(el.scrollHeight - (el.scrollTop + el.offsetHeight) < 100){
      this.onScrolledToEnd();
    }else if(this.props.onScroll)
      this.props.onScroll(ev)
  }

  onScrolledToEnd = throttle(() => {
    if(this.props.onScrolledToEnd) {
      this.props.onScrolledToEnd();
    }
  },500)

  renderFlightNumber(flightInfo: Flight): React.ReactNode {
    const str = formatFlightNumber(flightInfo).toLowerCase();
    const {query} = this.props;
    if(!query)
      return str;
    return <span dangerouslySetInnerHTML={{__html:str.replace(query.toLowerCase(),`<mark>${query}</mark>`)}}/>
  }

  renderSkeletonRow() {
    const {b} = this.props;

    return <div className={b('skeleton').toString()}/>;
  }

  renderTurnaroundRow(turn: Turnaround): React.ReactNode {
    const {b} = this.props;

    return (
      <NavLink
        className={b('turnaround').toString()}
        key={turn.id}
        data-turn-id={turn.id}
        to={`/${turn.standId}/${turn.id}`}
        activeClassName={b('turnaround',{active:true})}
      >
        <AirlineIcon icaoCode={turn.outboundFlight?.companyIata || turn.inboundFlight?.companyIata} />
        {getStandLabel(turn.originalStandId || turn.standId)}
        &nbsp;&nbsp;
        <span className={b('flight-num', {active: !!turn.inboundFlight})}>
          {turn.inboundFlight ? this.renderFlightNumber(turn.inboundFlight) : 'N/A'}
        </span>

        <i className={'fas fa-circle'}/>

        <span className={b('flight-num', {active: !!turn.outboundFlight})}>
          {turn.outboundFlight ? this.renderFlightNumber(turn.outboundFlight) : 'N/A'}
        </span>

        <span className={b('turn-time')}>
          <TimeFormatter time={turn.start} format={'HH:mm'}/>
          &nbsp;-&nbsp;
          {turn.end ? <TimeFormatter time={turn.end} format={'HH:mm'}/> : 'In progress'}
        </span>
      </NavLink>
    )
  }

  renderGroupItem(item: {label:string, href:string} | null | Turnaround) {
    if(!item)
      return this.renderSkeletonRow();
    if("href" in item)
      return (
        <NavLink
          className={this.props.b('turnaround').toString()}
          to={item.href}
          key={item.label}
        >{item.label}</NavLink>
      )
    return this.renderTurnaroundRow(item);
  }

  render() {
    const {b,groups,query,loading} = this.props;

    return (
      <div className={b('scroll-container')} onScroll={this.onScroll} ref={this.scrollRef}>
        {groups.length === 0 && query && query.length > 0 && !loading && (
          <div className={this.props.b("group-header")}>Nothing found</div>
        )}
        {groups.map(({label,items,date,hideCounter}) =>
          <React.Fragment key={label}>
            <div className={this.props.b('group-header')}>
              {date ? <CalendarFormatter time={date}/> : label}&nbsp;
              {!hideCounter && `(${items.length})`}
            </div>
            {items.map(i =>this.renderGroupItem(i))}
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default observer(GenericTurnaroundsList);