import React, {Component, useContext, useEffect, useState} from "react";
import {xor} from 'lodash';
import classnames from 'classnames';

import "./style.scss";
import Modal from '../Modal';
import DateTimeInput from "../DateTimeInput";
import {now} from "../../services/time";
import Spinner from "../Spinner";
import exportData from './export';
import {observer} from "mobx-react";
import {RootContext} from "../../services/react";

const timeRangeLabels: {[key:string]:string} = {
  day: 'All turnarounds today',
  turn: 'Selected turnaround',
  manual: 'Custom interval'
};

function getCurrentDay():number {
  let dt = new Date();
  dt.setHours(0);
  dt.setSeconds(0);
  dt.setMinutes(0);
  return dt.getTime();
}

//For debug purposes, and yes it's 99% copy past.
const DebugExportModal: React.FC<{onClose: ()=>void}> = observer(({onClose}) => {
  const root = useContext(RootContext);
  const {stands} = root.standsStore;
  const currentStandId = root.standStore?.standId;
  const [selectedStands, setSelectedStands] = useState(currentStandId ? [currentStandId] : []);
  const currentTurn = root.standStore?.selectedTurnaround;
  const [start, setStart] = useState<number>(getCurrentDay());
  const [end, setEnd] = useState<number>(now());
  const [loading, setLoading] = useState(false);
  const [rangeMode, setRangeMode] = useState<string>("day");

  const setCurrentDay = () => {
    let dt = new Date();
    dt.setHours(0);
    dt.setSeconds(0);
    dt.setMinutes(0);
    setStart(dt.getTime());
    setEnd(now());
    setRangeMode('day');
  };

  useEffect(()=>{
    if(selectedStands.length > 1 && rangeMode === 'turn')
      setCurrentDay();
  },[selectedStands, rangeMode])

  const setCurrentTurn = () => {
    if(!currentTurn)
      return;
    setStart(currentTurn.start);
    setEnd(currentTurn.end || now());
    setRangeMode('turn');
  };

  const setManually = () => {
    setRangeMode('manual');
  };

  const submit = async () => {
    setLoading(true);
    exportData(selectedStands,start,end).catch(()=>{
      alert("Failed to make export. Please try again later.")
    }).finally(()=>setLoading(false))
  };

  const onStartDateChange = (ts: number) => {
    let dt = new Date(ts);
    let oldDt = new Date(start);
    if(dt.getDate() !== oldDt.getDate() || dt.getMonth() !== oldDt.getMonth()) {
      dt.setHours(0);
      dt.setMinutes(0);
      ts = dt.getTime();
    }

    setStart(ts);
    if(ts > end){
      let newEnd = new Date(ts);
      newEnd.setHours(23);
      newEnd.setMinutes(59);
      setEnd(newEnd.getTime());
    }
  };

  const onEndDateChange = (ts: number) => {
    let dt = new Date(ts);
    let oldDt = new Date(end);
    if(dt.getDate() !== oldDt.getDate() || dt.getMonth() !== oldDt.getMonth()) {
      dt.setHours(23);
      dt.setMinutes(59);
      ts = dt.getTime();
      ts = Math.min(ts,now())
    }

    setEnd(ts);
    if(ts < start){
      let newStart = new Date(ts);
      newStart.setHours(0);
      newStart.setMinutes(0);
      setStart(newStart.getTime());
    }
  };

  let selectStandsSummaryText = "";
  if(selectedStands.length) {
    selectStandsSummaryText = selectedStands.slice(0,2).join(', ');
  }
  if(selectedStands.length > 2){
    selectStandsSummaryText += ` & ${selectedStands.length-2} more`
  }

  return (
    <Modal onClose={onClose} className={'export-modal'} modalTitle={"Debug export"}>

      <div className={'modal-section-title'}>
        stands
        <span className={'modal-section-title_badge'}>{selectStandsSummaryText}</span>
      </div>
      <br/>
      <div className={'list scrollable'}>
        <a className={'list-item'} onClick={()=>setSelectedStands(stands.map(s=>s.id))}>All stands</a>
        {currentStandId && <a className={'list-item'} onClick={()=>setSelectedStands([currentStandId])}>Current stand</a>}
        {stands.map(s=>(
          <a
            className={classnames('list-item',{active: selectedStands.includes(s.id)})}
            key={s.id} onClick={()=>setSelectedStands(xor(selectedStands,[s.id]))}
          >
            <i className={classnames(!selectedStands.includes(s.id) ? " fal fa-square" : "fas fa-check-square")}/>
            {s.label}
          </a>
        ))}
      </div>

      <div className={'modal-section-title time-range'}>
        time
        <span className={'modal-section-title_badge'}>{timeRangeLabels[rangeMode]}</span>
      </div>
      <a className={classnames('list-item',{active: rangeMode === 'day'})} onClick={setCurrentDay}>{timeRangeLabels.day}</a>
      {currentStandId && selectedStands.length <= 1 && currentTurn && (
        <a className={classnames('list-item',{active: rangeMode === 'turn'})} onClick={setCurrentTurn}>{timeRangeLabels.turn}</a>
      )}
      <a className={classnames('list-item',{active: rangeMode === 'manual'})} onClick={setManually}>{timeRangeLabels.manual}</a>

      {rangeMode === 'manual' && (
        <div className={'range-container'}>
          <label className={'start-label'}>from</label>
          <DateTimeInput value={start} className={'start-input'} onChange={onStartDateChange} max={now()} timezone={"Etc/UTC"}/>
          <label className={'end-label'}>to</label>
          <DateTimeInput value={end} className={'end-input'} onChange={onEndDateChange} max={now()} timezone={"Etc/UTC"}/>
        </div>
      )}

      <a
        className={classnames('export-btn',{disabled: loading || !selectedStands.length || !rangeMode})}
        onClick={() => !loading && selectedStands.length && submit()}
      >
        {loading ? <Spinner/> : 'export'}
      </a>
    </Modal>
  )
})

export default function DebugExport() {
  const [active,setActive] = useState(false);

  useEffect(()=>{
    // @ts-ignore
    window._debugExport = () => {
      setActive(true);
    }
  })

  return active ? <DebugExportModal onClose={()=>setActive(false)}/> : null;
};