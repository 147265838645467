import React, { useState } from "react";
import Turnaround from "../../models/turnaround";
import TimeFormatter from "../TimeFormatter";

import styles from "./style.module.scss";
import { turnaroundDuration } from "../../services/data";
import Detection from "../../models/detection";

interface Props {
  timestamp: number;
  turnaround: Turnaround;
  detection: Detection;
  selectTimestamp: (timestamp: number) => void;
}

type ReactMouseEvent = React.SyntheticEvent<HTMLDivElement, MouseEvent>;

export const ProgressBar: React.FC<Props> = ({
  turnaround,
  detection,
  timestamp,
  selectTimestamp,
}) => {
  const [cursorX, setCursorX] = useState<number | null>(null);
  const [cursorTs, setCursorTs] = useState<number | null>(null);
  const { round } = Math;

  const turnDuration = turnaroundDuration(turnaround);

  const getBoundary = (ts: number | null) =>
    (Math.abs((ts || Date.now()) - turnaround.start) / turnDuration) * 100;

  const eventStartBoundary = getBoundary(detection.start); // %
  const eventEndBoundary = detection.end ? getBoundary(detection.end) : null; // %
  const eventProgressWidth = eventEndBoundary // %
    ? eventEndBoundary - eventStartBoundary
    : 100 - eventStartBoundary;

  const slider =
    (Math.abs((timestamp || 0) - turnaround.start) / turnDuration) * 100;

  const calcProgress = (e: ReactMouseEvent) => {
    const progress = Math.abs(
      e.nativeEvent.offsetX / e.currentTarget.clientWidth
    );
    return turnaround.start + turnDuration * progress;
  };

  const onMouseMove = (e: ReactMouseEvent) => {
    setCursorTs(calcProgress(e));
    setCursorX(e.nativeEvent.offsetX);
  };

  const onMouseLeave = () => setCursorX(null);

  const onClick = (e: ReactMouseEvent) => selectTimestamp(calcProgress(e));

  return (
    <section className={styles.progressBarContainer}>
      <div
        className={styles.progressBar}
        onMouseLeave={onMouseLeave}
        onMouseMove={onMouseMove}
        onClick={onClick}
      >
        {cursorX !== null && cursorTs !== null && (
          <div
            className={styles.timeTooltip}
            style={{
              left: `clamp(0%, calc(${cursorX}px - 2.66rem), calc(100% - 5.33rem))`,
            }}
          >
            <TimeFormatter date={false} time={cursorTs} />
          </div>
        )}
        <div
          className={styles.slider}
          style={{ left: `calc(${round(slider)}% - 0.25rem)` }}
        />
        <div
          className={styles.event}
          style={{
            left: `${round(eventStartBoundary)}%`,
            width: `${round(eventProgressWidth)}%`,
          }}
        />
      </div>
    </section>
  );
};
