import React, {Component, createRef} from 'react';
import cn from 'bem-cn';
import {debounce} from "lodash";

import './style.scss';
import {RootContext} from "../../services/react";
import {RootStore} from "../../stores/root";
import {observer} from "mobx-react";
import GlobalTurnaroundsList from "./globalList";
import LocalTurnaroundsList from "./localList";
import {useStandInsteadGate} from "../../services/config";

const b = cn('turnarounds-list');

type ListState = {
  showSearch: boolean
  query: string,
  allStands: boolean,
}

//TODO alert network errors
class TurnaroundsList extends Component<{}, ListState> {
  static contextType = RootContext;
  context! : RootStore;

  scrollRef = createRef<HTMLDivElement>()

  constructor(props: {}, context:RootStore) {
    super(props, context);

    this.state = {
      showSearch: !context.standStore,
      query: "",
      allStands: !context.standStore,
    };
  }

  onQueryChanged = debounce((val:string) => {
    this.setState({query:val})
  },500);

  render() {
    const {showSearch, query, allStands} = this.state;

    return (
      <div className={b()}>
        {!showSearch && (
          <div className={b('header')} onClick={ev=>{ev.stopPropagation(); ev.nativeEvent.stopImmediatePropagation()}}>
            Turnaround history
            <a className={b('toggle-search')} onClick={() => this.setState({showSearch:true})}>
              <i className={'fas fa-search'}/>
            </a>
          </div>
        )}
        {showSearch && (
          <div className={b('search-menu')} onClick={ev=>{ev.stopPropagation(); ev.nativeEvent.stopImmediatePropagation()}}>
            <i className={'fas fa-search'}/>
            <input
              placeholder={'Turnaround search...'}
              autoFocus
              onChange={(ev)=>this.onQueryChanged(ev.target.value)}
            />
            {this.context.standStore && (
              <>
                <a className={b('toggle-mode', {active: !allStands})} onClick={() => this.setState({allStands:false})}>
                  {useStandInsteadGate ? 'This stand' : 'This gate'}
                </a>
                <a className={b('toggle-mode', {active: allStands})} onClick={() => this.setState({allStands:true})}>
                  {useStandInsteadGate ? 'All stands' : 'All gates'}
                </a>
              </>
            )}
          </div>
        )}

        {(!!query || allStands) && (
          <GlobalTurnaroundsList b={b} allStands={allStands} query={query}/>
        )}
        {!query && !allStands && (
          <LocalTurnaroundsList b={b}/>
        )}
      </div>
    );
  }
}

export default observer(TurnaroundsList);