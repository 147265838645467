import { initializeApp } from "firebase/app";
import { getFirestore,collection, addDoc } from "firebase/firestore";

import ApronAlert from "./apronAlert";
import {getTimeFormatTemplate, now} from "../services/time";
import moment from "moment";
import {rootStore} from "../stores/root";
import {storeWatchlistToDB, watchListLifePeriod} from "../services/config";

const INCIDENTS_WATCHLIST_KEY = "INCIDENTS_WATCHLIST"

export default class Watchlist {
  start: number = now()
  private created = now()
  private _flights: string[] = []
  private _fakeAlerts: ApronAlert[] = []

  private constructor() {}

  get flights(): ReadonlyArray<string> {
    return this._flights;
  }

  get fakeAlerts(): ReadonlyArray<ApronAlert> {
    return this._fakeAlerts;
  }

  setFlights(flights: string[]) {
    this._flights = flights;
    this._fakeAlerts = this._fakeAlerts.filter(a=>flights.includes(a.outboundFlightNumber!))
    this.save();
  }

  addFakeAlerts(alerts: ApronAlert[]) {
    this._fakeAlerts.push(...alerts);
    this.save();
  }

  static remove() {
    localStorage.removeItem(INCIDENTS_WATCHLIST_KEY);
  }

  private save() {
    const data = {
      start: this.start,
      created: this.created,
      _flights: this._flights,
      _fakeAlerts: this._fakeAlerts
    }
    localStorage.setItem(INCIDENTS_WATCHLIST_KEY,JSON.stringify(data));

    if(storeWatchlistToDB)
      this.saveToFirebase()
  }

  private saveToFirebase() {
    const firebaseConfig = {
      apiKey: "AIzaSyADvD8MaCN5qmexZJvWrovMS_0kV9T87jE",
      authDomain: "dashboard-watchlist-store.firebaseapp.com",
      projectId: "dashboard-watchlist-store",
      storageBucket: "dashboard-watchlist-store.appspot.com",
      messagingSenderId: "961930923315",
      appId: "1:961930923315:web:ced67556f8bc5a13f40246"
    };

    initializeApp(firebaseConfig);
    const db = getFirestore();

    addDoc(collection(db, "watchlists"), {
      start: moment(this.start).utc().format(getTimeFormatTemplate(true,false)),
      startUnix: this.start,
      created: moment(this.created).utc().format(getTimeFormatTemplate(true,false)),
      createdUnix: this.created,
      flights: this._flights,
      user: rootStore.authStore.user?.profile.email
    }).then(()=>console.log("watchlist save to firebase"), ()=>console.log("failed to save watchlist to firebase"));
  }

  static create(start: number,flights: string[]) : Watchlist {
    const list = new Watchlist();
    list.start = start;
    list._flights = flights;
    list.save();
    return list;
  }

  static read() {
    const watchlistRaw = localStorage.getItem(INCIDENTS_WATCHLIST_KEY);
    if(watchlistRaw) {
      try {
        const {start,created,_flights,_fakeAlerts} = JSON.parse(watchlistRaw);

        if(!start || !created || !_flights || !_fakeAlerts || Date.now() - created > watchListLifePeriod){
          Watchlist.remove();
          return null;
        }

        const watchList = new Watchlist();
        watchList.start = start;
        watchList.created = created;
        watchList._fakeAlerts = _fakeAlerts;
        watchList._flights = _flights;
        return watchList;
      }catch (er) {
        return null;
      }
    }
    return null;
  }
}