import React, {Component, useContext} from 'react';
import Logo from "../Logo";
import './style.scss';
import Dropdown, {DropdownItem} from "../Dropdown";
import {enableSidebar, logoUrl} from "../../services/config";
import userPic from "./user-pic.png";
import { NavLink } from 'react-router-dom';
import {PropsWithClass} from "../../models/common";
import { observer } from 'mobx-react';

import {RootContext} from "../../services/react";
import { tourStepIdList } from '../Tour/steps';
import classNames from 'classnames';

const Header: React.FC<PropsWithClass> = ({className}) => {
  const rootStore = useContext(RootContext);
  const {user} = rootStore.authStore;

  return (
    <header className={className}>
      {enableSidebar && !rootStore.sidebarStore.sidebarVisible && (
        <a
          onClick={() => rootStore.sidebarStore.setVisibility(true)}
          className={"hide-btn"}
        >
          <i className={"fas fa-bell"} />
        </a>
      )}
      <NavLink to={'/'} className={'logo'}>
        {logoUrl ? <img src={logoUrl}/> : <Logo/>}
      </NavLink>
      <Dropdown
        id={tourStepIdList.userDropdown}
        className={'user-dropdown'}
        title={
          <>
            {user && (user.profile.full_name || user.profile.email)}
            <i className="far fa-angle-down"/>
            <img src={userPic}/>
          </>
        }
      >
        {/* <DropdownItem
          className={'logout-btn'}
          onSelect={rootStore.authStore.logout}
        >
          Log out
          &nbsp;
          <i className="far fa-sign-out"></i>
        </DropdownItem> */}
        <div className={'dropdown_separator'}/>
        <div className={'dropdown_section-title'}>TIME ZONE</div>
        {rootStore.timezones.map(([label,zone])=>(
          <DropdownItem
            key={zone}
            onSelect={() => rootStore.setTimezone(zone)}
            className={classNames('timeformat-option', {utc: label === "Utc"})}
            active={rootStore.timezone === zone}
          >
            {label}
          </DropdownItem>
        ))}
      </Dropdown>
    </header>
  )
}

export default observer(Header);