import {ApronAlertsStore} from "./alertsBase";
import SidebarStore from "../sidebar";
import {AlertsParams} from "../../models/api";
import {getAlerts} from "../../services/api";
import {action} from "mobx";
import {updateRate} from "../../services/config";
import ApronAlert from "../../models/apronAlert";
import {last} from "lodash";

export class CurrentGateAlertsRepository extends ApronAlertsStore {
  standId: string

  constructor(sidebarStore: SidebarStore) {
    super(sidebarStore);
    this.standId = sidebarStore.root.standStore?.standId || "";
  }

  init() {
    console.log("init current gate");
    this.sync();
  }

  async sync() {
    const {syncStart} = this;
    const params: AlertsParams = {
      stand_id: this.standId
    };

    if (syncStart)
      params.until = syncStart;

    getAlerts(params).then(action(newAlerts => {
      if (this.disposed)
        return;
      this.addAlerts(newAlerts);
      this.ready = true;
    })).finally(() => {
      if (!this.disposed)
        window.setTimeout(() => this.sync(), updateRate);
    })
  }

  loadMore() {
    let oldestAlert: ApronAlert | undefined = last(this.sidebarStore.alerts);
    if (!oldestAlert)
      return;

    const params: AlertsParams = {
      before: oldestAlert.id,
      stand_id: this.standId
    }

    this.isLoadingMore = true
    getAlerts(params).then(action(items => {
      if (this.disposed)
        return;
      if (items.length)
        this.addAlerts(items);
      else
        this.canLoadMore = false;
    })).finally(action(() => {
      this.isLoadingMore = false;
    }));
  }

  dispose() {
    super.dispose();
    console.log("dispose current gate");
  }
}