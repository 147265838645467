import React, {Component} from 'react';
import {orderBy,intersection} from 'lodash';
import {observer} from "mobx-react";

import './style.scss';
import TimeAxis from "./TimeAxis";
import OperationRow from "./OperationRow";
import TimeDashLine, {CurrentTimeDashLine} from "./TimeDashLine";
import OutageRow from "./OutageRow";
import CameraOutage from "../../models/cameraOutage";
import Detection from "../../models/detection";
import {showOutagesFor} from "../../services/config";
import TimelineStore, {DetectionEvent} from "../../stores/timeline";
import {StandContext} from "../../services/react";
import StandStore from "../../stores/stand";
import {now} from "../../services/time";
import { tourStepIdList } from '../Tour/steps';

export const TimelineContext  = React.createContext<TimelineStore>({} as TimelineStore);

type TimelineState = {
  tmpTime?: number | null
}

export class Timeline extends Component<{}, TimelineState> {
  static contextType = StandContext;
  context!: StandStore
  store: TimelineStore;
  ref = React.createRef<HTMLDivElement>();

  constructor(props: {},context: StandStore) {
    super(props,context);
    this.state = {};

    this.store = new TimelineStore(context);
    context.timelineStore = this.store;

    this.getLeftFromTimestamp = this.getLeftFromTimestamp.bind(this);
    this.getClickedTimestamp = this.getClickedTimestamp.bind(this);
  }

  componentWillUnmount() {
    if (this.context.timelineStore === this.store)
      this.context.timelineStore = null;
  }

  getClickedTimestamp(x:number) {
    const [start,end] = this.store.bounds;
    let rect = this.ref.current?.querySelector('.col2')?.getBoundingClientRect();
    if(!rect)
      return null;
    let left = (x - rect.left) / rect.width;
    let ts = start + (end - start) * left;
    return ts;
  }

  getLeftFromTimestamp(ts: number) {
    const {bounds} = this.store;
    return (ts - bounds[0])/(bounds[1] - bounds[0]);
  }

  onPaneClick(ev: React.MouseEvent) {
    if(!this.context.isLive){
      let ts = this.getClickedTimestamp(ev.clientX);
      if(ts)
        this.context.setDisplayedTimestamp(ts);
    }
  }

  onPaneMove(ev: React.MouseEvent) {
    let ts = this.getClickedTimestamp(ev.clientX);
    this.setState({tmpTime: ts})
  }

  renderOperationGroup(title: string,rows: (Detection | DetectionEvent)[][],index: number) {
    rows = orderBy(rows,[r=>r[0].type.toLowerCase()],['asc']);
    return this.renderGroup(title,rows.map((items) => <OperationRow items={items} key={items[0].type}/>),index)
  }

  renderOutagesGroup() {
    let {cameras,outages} = this.context;
    const subgroups : ([string,CameraOutage[]])[] = cameras.map(c=>[c,outages.filter(o=>o.camera === c)]);

    return this.renderGroup(
      'Cameras availability',
      subgroups.map(([camera,items]) => <OutageRow items={items} key={camera} camera={camera}/>),
      1
    )
  }

  renderGroup(title: string,rows: React.ReactNode[], index: number) {
    //IE11 needs gridRow
    const style = {gridRow:index+1,msGridRow:index+1};
    return (
      <React.Fragment key={title}>
        <div className={'group-name col1'} style={style}>
          {title}
        </div>
        <div
          className={'col2 pane'}
          style={style}
          onMouseMove={ev => this.onPaneMove(ev)}
          onMouseLeave={() => this.setState({tmpTime: null})}
          onClick={(ev) => this.onPaneClick(ev)}
        >
          {rows}
        </div>
      </React.Fragment>
    )
  }

  render() {
    const {outages,selectedTurnaround: turnaround} = this.context;
    const {user} = this.context.root.authStore;
    const {rows} = this.store;
    let {tmpTime} = this.state;

    const showOutages = user && intersection(user.profile.roles,showOutagesFor).length > 0 && outages.length > 0;

    return (
      <div
        className={'timeline'}
        ref={this.ref}
        id={tourStepIdList.timeline}
      >
        <TimelineContext.Provider value={this.store}>
          {turnaround && !!tmpTime && <TimeDashLine timestamp={tmpTime} className={'tmp-time'} showTimeLabel={true}/>}
          <CurrentTimeDashLine/>
          <div className={'col1 bk-top'}/>
          <div
            className={'col2 bk-top'}
            onMouseMove={ev => this.onPaneMove(ev)}
            onMouseLeave={() => this.setState({tmpTime: null})}
          >
            <TimeAxis/>
          </div>
          {showOutages && this.renderOutagesGroup()}
          {rows.map(([title, rows],index) =>this.renderOperationGroup(title,rows,outages.length ? index + 2 : index + 1))}
        </TimelineContext.Provider>
      </div>
    );
  }
}

export default observer(Timeline);
