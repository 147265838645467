import React, {useContext, useMemo} from "react";
import moment from "moment";
import "moment-timezone";

import { observer } from "mobx-react";
import {RootContext} from "../../services/react";

type Props = {
  time: number,
  sameDay?: string,
  showTime?: boolean,
  showComma?: boolean
}

const CalendarFormatter: React.FC<Props> = ({time,sameDay,showTime,showComma}) =>{
  const {timezone} = useContext(RootContext);
  const format = useMemo(()=>{
    const format = {
      sameDay: sameDay ? `[${sameDay}]` : '[Today]',
      lastDay: '[Yesterday]',
      lastWeek: 'DD MMM YYYY',
      sameElse: 'DD MMM YYYY'
    };

    return format;
  },[sameDay]);

  let str = moment(time).tz(timezone).calendar(undefined,format);
  if(showTime && showComma)
    str += ','
  if(showTime)
    str += ' ' + moment(time).tz(timezone).format("HH:mm");

  return <>{str}</>;
};

export default observer(CalendarFormatter);