import {observer} from "mobx-react";
import React, {Component, ContextType} from 'react';
import {Route, Switch, Router} from "react-router-dom";
import {autorun} from "mobx";

import Home from "../Home";
import PreLoader from "../PreLoader";
import AuthCallback from "../AuthCallback";
import ErrorCallback from "../ErrorCallback";
import SignRedirect from "./signRedirect";
import {gaRefreshRate} from "../../services/config";
import DashboardOff from "../DashboardOff";
import ErrorMessage from "../ErrorMessage";

import "./style.scss";
import {RootContext} from "../../services/react";
import {history} from "../../services/router";

type  AppState = {
  failed: boolean
}
class App extends Component<{},AppState> {
  static contextType = RootContext;
  context!: ContextType<typeof RootContext>;

  disableSignInRedirect = false

  state = {
    failed:false
  };

  componentDidMount() {
    setInterval(()=>{
      this.sendGAEvent();
    },gaRefreshRate);
    this.sendGAEvent();

    autorun(()=>{
      if(this.context.authStore.isSigned)
        this.disableSignInRedirect = true;
    })
  }

  sendGAEvent() {
    try{
      const user = this.context.authStore.user;
      if(user)
        (window as any).ga.getAll()[0].send("event", "User",user.profile.email);
    }catch(er){
      console.log("failed to send ga event");
    }
  }

  render() {
    if(this.state.failed)
      return <ErrorMessage/>;

    if(this.context.authStore.isSigned)
      return (
        <Router history={history}>
          <Home/>
        </Router>
      );

    return(
      <Router history={history}>
        <Switch>
          <Route path={'/off'} component={DashboardOff}/>
          <Route path={'/auth/callback'} component={AuthCallback}/>
          <Route path={'/error'} component={ErrorCallback}/>
          {!this.disableSignInRedirect && <Route path={'/'} render={()=><SignRedirect onFail={()=>this.setState({failed:true})}/>}/>}
          <PreLoader/>;
        </Switch>
      </Router>
    );
  }
}

export default observer(App);