import React, {useCallback, useContext} from 'react';
import './style.scss';
import {PropsWithClass} from "../../models/common";
import cn from "bem-cn";
import {observer} from "mobx-react";

import {RootContext} from "../../services/react";

const b = cn('sidebar-resizer');

const SidebarResizer: React.FC<PropsWithClass> = ({className}) => {
  const rootStore = useContext(RootContext);

  //ev.movementX doesn't work on safari
  const onMouseDown = useCallback((ev:React.MouseEvent)=>{
    const startX = ev.clientX;
    const startWidth = rootStore.sidebarStore.width;
    rootStore.sidebarStore.resizing = true;

    const onMove = (ev: MouseEvent) => {
      ev.preventDefault();

      const delta = ev.clientX - startX;
      const newWidth = startWidth + delta;
      rootStore.sidebarStore.setWidth(newWidth);
    }

    document.addEventListener('mousemove',onMove);
    document.addEventListener('mouseup',()=>{
      document.removeEventListener('mousemove',onMove);
      rootStore.sidebarStore.resizing = false;

    },{once:true})
  },[]);

  const onTouchStart = useCallback((ev:React.TouchEvent)=>{
    const startX = ev.targetTouches[0].clientX;
    const startWidth = rootStore.sidebarStore.width;
    rootStore.sidebarStore.resizing = true;

    const onMove = (ev: TouchEvent) => {
      const delta = ev.targetTouches[0].clientX - startX;
      const newWidth = startWidth + delta;
      rootStore.sidebarStore.setWidth(newWidth);
    }

    document.addEventListener('touchmove',onMove);
    document.addEventListener('touchend',()=>{
      document.removeEventListener('touchmove',onMove);
      rootStore.sidebarStore.resizing = false;
    },{once:true})
  },[]);

  // Empty onClick required for proper onTouchStart work
  const onClick = useCallback((ev:React.MouseEvent)=>{},[]);

  return (
    <div
      className={b.mix(className)}
      onMouseDown={onMouseDown}
      onTouchStart={onTouchStart}
      onClick={onClick}
    >
      <div className={b("cursor")} />
    </div>
  );
};

export default observer(SidebarResizer);