import classNames from "classnames";
import React from "react";
import whiteNoise from "../../images/white-noise.gif";
import style from "./style.module.scss";

type Props = {
  className?: string;
  msg?: string;
  hideText?: boolean;
};

const FrameUnavailable: React.FC<Props> = ({ msg, hideText, className }) => {
  return (
    <div
      className={classNames(style["frame-unavailable"], className)}
      style={{
        backgroundImage: `url(${whiteNoise})`,
      }}
    >
      <div className={style["blackout"]} />
      {!hideText && (
        <div className={style["exception"]}>
          <div className={style["msg"]}>
            <i className="fas fa-plane-slash" />
            {msg || "HistoricFrame is unavailable"}
          </div>
        </div>
      )}
    </div>
  );
};

export default FrameUnavailable;
