import {action, makeAutoObservable, reaction} from "mobx";
import {RootStore} from "./root";
import {
  ALL_GATES_TAB,
  CURRENT_GATE_TAB,
  MARKED_ALERTS_STORAGE_KEY,
  SELECTED_TURN_TAB,
  SIDEBAR_VISIBILITY_STORAGE_KEY,
  SIDEBAR_WIDTH_STORAGE_KEY,
  WATCHLIST_TAB
} from "../constants/strings";
import IncidentsWatchlist from "../models/watchlist";
import Watchlist from "../models/watchlist";
import {ApronAlertsStore} from "./alerts/alertsBase";
import {AllGatesAlertsRepository} from "./alerts/allGatesAlerts";
import {CurrentGateAlertsRepository} from "./alerts/currentGateAlerts";
import {WatchlistAlertsRepository} from "./alerts/watchListAlerts";
import {SelectedTurnAlertsRepository} from "./alerts/turnaroundAlerts";
import {disableWatchlist, enableBrowserNotifications} from "../services/config";
import {getAlertLabel, getStandLabel, readBooleanFromLocalStorage, writeBooleanToLocalStorage} from "../services/data";
import { reportError } from "../services/logger";
import ApronAlert from "../models/apronAlert";
import {capitalize, partition, uniqBy} from "lodash";
import logoDefault from "../images/notifications/logo.png";
import logoHigh from "../images/notifications/logo-high.png";
import logoMedium from "../images/notifications/logo-medium.png";
import logoLow from "../images/notifications/logo-low.png";
import {browserNotificationsActivated} from "../services/platform";
import {history} from "../services/router";
import { getAlertsConfigList } from "../services/api";

type MarkedAlert = Pick<ApronAlert, "id" | "turnaroundId">;

export default class SidebarStore {
  width = parseInt(localStorage.getItem(SIDEBAR_WIDTH_STORAGE_KEY) || "") || 400;
  watchlist: IncidentsWatchlist | null;
  tab: string | null = null;
  resizing = false;
  private isSidebarVisible = readBooleanFromLocalStorage(SIDEBAR_VISIBILITY_STORAGE_KEY, null);

  private notificationsSource: ApronAlertsStore;
  private tabAlertsStore: ApronAlertsStore;
  private lastAlertsCheck = Date.now();
  private activeNotifications: {[v:string]: Notification} = {};

  markedAlerts = this.getMarkedAlerts();

  constructor(public root: RootStore) {
    this.watchlist =  disableWatchlist ? null : Watchlist.read();
    if(this.watchlist) {
      this.notificationsSource = new WatchlistAlertsRepository(this);
      this.tabAlertsStore = this.notificationsSource;
      this.tab = WATCHLIST_TAB;
    }else {
      this.notificationsSource = new AllGatesAlertsRepository(this);
      this.tabAlertsStore = this.notificationsSource;
      this.tab = ALL_GATES_TAB;
    }

    makeAutoObservable(this,{
      setTab: action.bound,
    });

    this.initReactions();
  }

  initReactions() {
    reaction(
      () => this.root.standsStore.activeTurnarounds,
      () => (this.markedAlerts = this.getMarkedAlerts())
    );

    reaction(()=>this.root.authStore.isSigned,(signed, signedBefore)=>{
      if(signed && !signedBefore){
        this.initSidebarVisibility();
      }
    })

    if(browserNotificationsActivated && enableBrowserNotifications){
      console.log("Browser notifications ON")
      reaction(
        ()=> this.notificationsSource.alerts.length,
        () => {
          if(document.hasFocus())
            return;
          const alerts = this.notificationsSource.alerts;
          const newAlerts = alerts.filter(a=>a.ts > this.lastAlertsCheck);
          this.notify(newAlerts);
          this.lastAlertsCheck = Date.now();
        }
      )
    }
  }

  async initSidebarVisibility() {
    if (this.isSidebarVisible !== null)
      return;

    const alertsConfigList = await getAlertsConfigList();

    if (this.isSidebarVisible !== null)
      return;

    const userResources: string[] =
      this.root.authStore.user?.profile.resources || [];
    const result = alertsConfigList
      .map(({ group }) => group)
      .some((v) => userResources.includes(v));

    if (result) {
      // This writes values to local storage
      this.setVisibility(result);
    }
  }

  get sidebarVisible() {
    return !!this.isSidebarVisible;
  }

  get tabs() {
    const {standStore} = this.root;

    const tabs = [ALL_GATES_TAB];
    if(standStore?.turnaroundsListLoaded) {
      tabs.push(CURRENT_GATE_TAB);
    }

    if(this.watchlist) {
      tabs.unshift(WATCHLIST_TAB)
    }

    // Show selected turn tab for historical turns only
    if(standStore?.selectedTurnaround && standStore?.selectedTurnaround.end) {
      tabs.push(SELECTED_TURN_TAB)
    }

    return tabs;
  }

  // returns two arrays 1)current alerts (not marked) 2)marked current alerts and other alerts
  getGroupedAlerts(alerts = this.tabAlertsStore.alerts): [ApronAlert[],ApronAlert[]] {
    const { activeTurnarounds } = this.root.standsStore;
    const { markedAlerts } = this;

    const activeTurnsIds = Object.values(activeTurnarounds).map(t=>t.id);
    const markedAlertIds = markedAlerts.map(({id}) => id);
    let [activeAlerts, otherAlerts] = partition(alerts,a=>activeTurnsIds.includes(a.turnaroundId));
    const [unmarkedActiveAlerts, markedActiveAlerts] = partition(activeAlerts,a => !markedAlertIds.includes(a.id));

    return [unmarkedActiveAlerts,[...markedActiveAlerts,...otherAlerts]];
  }

  get alerts() {
    return this.tabAlertsStore.alerts;
  }

  get currentAlertsCount() {
    if(this.tab === WATCHLIST_TAB){
      return this.getGroupedAlerts()[0].length;
    }
    return this.getGroupedAlerts(this.notificationsSource.alerts)[0].length;
  }

  get isLoadingMore() {
    return this.tabAlertsStore.isLoadingMore;
  }

  get canLoadMore() {
    return this.tabAlertsStore.canLoadMore;
  }

  get ready() {
    return this.tabAlertsStore.ready && this.root.standsStore.activeTurnaroundsLoaded;
  }

  loadMore() {
    this.tabAlertsStore.loadMore();
  }

  setWatchlist(list: Watchlist | null) {
    if(!list && !this.watchlist)
      return;

    this.notificationsSource.dispose()

    this.watchlist = list;
    if(list) {
      this.notificationsSource = new WatchlistAlertsRepository(this);
      this.setTab(WATCHLIST_TAB);
    }else {
      this.notificationsSource = new AllGatesAlertsRepository(this);
      this.setTab(ALL_GATES_TAB);
    }
    this.tabAlertsStore = this.notificationsSource;
  }

  setTab(tab: string) {
    if(this.notificationsSource !== this.tabAlertsStore){
      this.tabAlertsStore.dispose();
    }

    if((this.watchlist && tab === WATCHLIST_TAB) || (!this.watchlist && tab === ALL_GATES_TAB))
      this.tabAlertsStore = this.notificationsSource;
    else if(tab === ALL_GATES_TAB){
      this.tabAlertsStore = new AllGatesAlertsRepository(this);
    } else if(tab === CURRENT_GATE_TAB) {
      this.tabAlertsStore = new CurrentGateAlertsRepository(this);
    } else if(tab === SELECTED_TURN_TAB) {
      this.tabAlertsStore = new SelectedTurnAlertsRepository(this);
    }

    this.tab = tab;
  }

  setDefaultTab() {
    const tab = this.watchlist ? WATCHLIST_TAB : ALL_GATES_TAB;
    this.setTab(tab);
  }

  setLastVisibleAlertId(id:string) {
    if(this.tabAlertsStore)
      this.tabAlertsStore.syncStart = id;
  }

  setWidth(width: number) {
    if(width < 150 || width > window.innerWidth-150)
      return;
    this.width = width;
    localStorage.setItem(SIDEBAR_WIDTH_STORAGE_KEY,width.toString())
  }

  setVisibility(value: boolean) {
    this.isSidebarVisible = value;
    writeBooleanToLocalStorage(SIDEBAR_VISIBILITY_STORAGE_KEY, value);
  }

  getMarkedAlerts(): MarkedAlert[] {
    const alerts = localStorage.getItem(MARKED_ALERTS_STORAGE_KEY);
    if (!alerts)
      return [];

    let parsed: MarkedAlert[] = [];
    try {
      parsed = JSON.parse(alerts);
    } catch (e) {
      reportError(e as Error);
      localStorage.removeItem(MARKED_ALERTS_STORAGE_KEY);
    }

    return this.filterOutdatedMarkedAlerts(parsed);
  }

  markAlertAsRead({id, turnaroundId}: ApronAlert) {
    let currentData = this.getMarkedAlerts();
    currentData.push({ id, turnaroundId });
    currentData = uniqBy(currentData, ({id}) => id);

    localStorage.setItem(MARKED_ALERTS_STORAGE_KEY, JSON.stringify(currentData));

    this.markedAlerts = currentData;

    this.activeNotifications[id]?.close();
  }

  markAlertAsUnread(alert: ApronAlert) {
    const filtered = this.getMarkedAlerts().filter(({id}) => alert.id !== id);

    localStorage.setItem(MARKED_ALERTS_STORAGE_KEY, JSON.stringify(filtered));
    
    this.markedAlerts = filtered;
  }

  filterOutdatedMarkedAlerts(data: MarkedAlert[]): MarkedAlert[] {
    return data.filter(({turnaroundId}) => !this.root.standsStore.activeTurnarounds[turnaroundId]);
  }

  notify(alerts: ApronAlert[]) {
    console.log("notify",alerts.length,"alerts");
    alerts.forEach(a=>{
      const opts: NotificationOptions = {
        body: capitalize(getAlertLabel(a)),
        silent: false,
        icon: logoDefault,
        requireInteraction: true
      }
      let title = `${getStandLabel(a.standId)}`;
      if(a.outboundFlightNumber)
        title +=  `- ${a.outboundFlightNumber}`;
      title += ` (${a.config.severity.toUpperCase()})`;

      switch (a.config.severity){
        case "HIGH":
          opts.icon = logoHigh;
          break;
        case "MEDIUM":
          opts.icon = logoMedium;
          break;
        case "LOW":
          opts.icon = logoLow;
          break;
      }

      if (!("Notification" in window))
        return;

      const notification = new Notification(title,opts);
      this.activeNotifications[a.id] = notification;

      notification.onclick = (ev) => {
        console.log("notification click");
        notification.close();
        window.parent.parent.focus();
        history.push(`/${a.standId}/${a.turnaroundId}`);
      }

      notification.onclose = () => {
        console.log("notification close");
        delete this.activeNotifications[a.id];
      }
    })
  }
}

