import React, { CSSProperties, useContext, useLayoutEffect, useRef, useState} from "react";

import styles from "./style.module.scss";
import { PropsWithClass } from "../../models/common";
import { observer } from "mobx-react";

import { RootContext } from "../../services/react";
import Popup from "../Popup";
import classNames from "classnames";

interface Props {
  className?: string,
}

const SidebarMenu: React.FC<Props> = ({ className }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState(false);
  const rootStore = useContext(RootContext);
  const {
    tab: selectedTab,
    tabs,
    alerts,
    width,
    setTab,
    currentAlertsCount
  } = rootStore.sidebarStore;

  useLayoutEffect(() => {
    if (!alerts)
      return;

    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current;
      setOverflow(clientWidth < scrollWidth);
    }
  }, [width, alerts, selectedTab, tabs, ref])

  if (!alerts) return null;

  const style = {} as CSSProperties;
  if (overflow) {
    style.visibility = "hidden";
  }

  const popupContent = tabs
    .filter((tab) => tab !== selectedTab)
    .map((tab) => (
      <a className={styles.popupElement} onClick={() => setTab(tab)} key={tab}>
        <span>{tab}</span>
      </a>
    ));

  const alertCountBadge =
    currentAlertsCount > 0 ? (
      <span className={styles.alertCount}>{currentAlertsCount}</span>
    ) : (
      <></>
    );

  return (
    <>
      <div ref={ref} className={classNames(styles.sidebarMenu, className)} style={style}>
        {tabs.map((tab) => (
          <span className={styles.tabWrapper} onClick={() => setTab(tab)} key={tab}>
            <a
              className={classNames(
                styles.tab,
                tab === selectedTab && styles.active
              )}
              key={tab}
            >
              <span>{tab}</span>
              {false && tab === selectedTab && alertCountBadge}
            </a>
          </span>
        ))}
      </div>
      {overflow && (
        <Popup
          className={classNames(styles.sidebarMenu, styles.selector, className, styles.collapsed)}
          popupClassName={styles.selectorPopup}
          popupContent={popupContent}
          closeOnPopupClick={true}
        >
          <i className="far fa-chevron-down" />
          <span className={styles.selectorTitle}>{selectedTab}</span>
          {alertCountBadge}
        </Popup>
      )}
    </>
  );
};

export default observer(SidebarMenu);
