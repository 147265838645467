import React from "react";
import {ReactourStep} from 'reactour'

const stepFactory= (content:string) => () => (
  <div className={'step'}>
    {content}
  </div>
);

export const tourStepIdList = Object.freeze({
  standSelect: "stand-select-tour-step",
  timeline: "timeline-tour-step",
  cameras: "cameras-tour-step",
  standVideoStream: "stand-video-stream-tour-step",
  userDropdown: "user-dropdown-tour-step",
  map: "map-tour-step",
  turnaroundPanel: "turnaround-panel-tour-step",
  modalCameras: "modal-cameras-tour-step",
  modalCloseBtn: "modal-close-btn-tour-step",
  modalLabel: "modal-label-tour-step",
  modalDateTime: "modal-date-time-tour-step",
  modalPrevBtn: "modal-prev-btn-tour-step",
  modalPrev10Btn: "modal-prev10-btn-tour-step",
  modalNextBtn: "modal-next-btn-tour-step",
  modalNext10Btn: "modal-next10-btn-tour-step",
  modalFrame: "modal-frame-tour-step",
  modalEventName: "modal-event-name-tour-step",
  modalEventDateTime: "modal-event-date-time-tour-step",
})

const allSteps: ReactourStep[] = [
  {
    selector: `.modal #${tourStepIdList.modalCameras}`,
    content: stepFactory('Switch between the cameras capturing the event.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalEventName}`,
    content: stepFactory('Event name.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalEventDateTime}`,
    content: stepFactory('Date and time of the detection of the event.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalCloseBtn}`,
    content: stepFactory('Close the popup and return to the main view.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalFrame}`,
    content: stepFactory('Snapshot of the detected event. The detection area is highlighted with a red rectangle.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalPrevBtn}`,
    content: stepFactory('Switch to the previous frame (you can also use the left arrow on the keyboard).'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalPrev10Btn}`,
    content: stepFactory('Rewind to several frames back.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalDateTime}`,
    content: stepFactory('Date and time of the shown snapshot.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalNext10Btn}`,
    content: stepFactory('Fast forward to several frames ahead.'),
    position: 'top'
  },
  {
    selector: `.modal #${tourStepIdList.modalNextBtn}`,
    content: stepFactory('Switch to the next frame (you can also use the right arrow on the keyboard).'),
    position: 'top'
  },

  {
    selector: `#${tourStepIdList.standSelect}`,
    content:  stepFactory('Use this dropdown list to choose between the available stands.'),
    position: 'bottom',
  },
  {
    selector: `#${tourStepIdList.timeline}`,
    content: stepFactory('The Gantt chart displays all the detected operations of the turnaround. On the top the time axis is displayed. Clicking on an event bar opens a screenshot of the detected event. Clicking on the time axis forces the main video window to show the snapshot of the apron at the moment corresponding to the area of the Gantt chart where the user has clicked.'),
    position: 'bottom',
  },
  {
    selector: `#${tourStepIdList.cameras}`,
    content: stepFactory('Switch between the cameras observing the same stand.'),
    position: 'left',
  },
  {
    selector: `#${tourStepIdList.standVideoStream}`,
    content: stepFactory('The screen displays the video of the turnaround. The default mode is showing the real time feed. Clicking on the Gantt chart or selecting the past turnaround switches to showing historical footage.'),
    position: 'left',
  },
  {
    selector: `#${tourStepIdList.turnaroundPanel}`,
    content: stepFactory('Information about the turnaround obtained from Assaia system and the airport API.'),
    position: 'bottom',
  },
  {
    selector: `#${tourStepIdList.map}`,
    content: stepFactory('Use scroll to zoom and drag to move.'),
    position: 'left',
  },
  // {
  //   selector: `#${tourStepIdList.userDropdown}`,
  //   content: stepFactory('Use this menu to log out or to change your timezone.')
  // }
]

export default allSteps;