import {StandDetails} from "../models/stand";

export const standsDetails: { [k: string]: StandDetails } = {
  "sea-a1": {
    "terminal": "conc a",
    "airline": "dl",
    "mapConfig": {"center": [1225.42822265625, 572.9605102539062], "angle": -45}
  },
  "sea-a2": {
    "terminal": "conc a",
    "airline": "dl",
    "mapConfig": {"center": [1187.247802734375, 611.1441040039062], "angle": -45}
  },
  "sea-a3": {
    "terminal": "conc a",
    "airline": "dl",
    "mapConfig": {"center": [1149.050048828125, 649.4500427246094], "angle": -45}
  },

  "sea-a4": {"terminal": "conc a", "airline": "cu", "mapConfig": {"center": [1110.8499145507812, 687.5500793457031], "angle": -45}},
  "sea-a5": {"terminal": "conc a", "airline": "cu", "mapConfig": {"center": [1072.6499633789062, 725.7500915527344], "angle": -45}},
  "sea-a6": {"terminal": "conc a", "airline": "cu", "mapConfig": {"center": [1034.509765625, 763.8785400390625], "angle": -45}},
  "sea-a7": {"terminal": "conc a", "airline": "cu", "mapConfig": {"center": [996.3276977539062, 802.0625], "angle": -45}},
  "sea-a8": {"terminal": "conc a", "airline": "ua", "mapConfig": {"center": [958.14404296875, 840.24658203125], "angle": -45}},
  "sea-a9": {
    "terminal": "conc a",
    "airline": "ua",
    "mapConfig": {"center": [919.9600219726562, 878.4305419921875], "angle": -45}
  },
  "sea-a10": {"terminal": "conc a", "airline": "ua", "mapConfig": {"center": [827.9999389648438, 911], "angle": 0}},
  "sea-a11": {"terminal": "conc a", "airline": "cu", "mapConfig": {"center": [773.9499816894531, 911], "angle": 0}},
  "sea-a12": {"terminal": "conc a", "airline": "cu", "mapConfig": {"center": [720, 911], "angle": 0}},
  "sea-a13": {"terminal": "conc a", "airline": "dl", "mapConfig": {"center": [654, 977], "angle": -90}},
  "sea-a14": {"terminal": "conc a", "airline": "dl", "mapConfig": {"center": [654, 1031], "angle": -90}},

  "sea-s1": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [324.1851220703125, 479.2650000000001],
      "size": [102, 94],
      "angle": -135
    }
  },
  "sea-s1a": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [312.8999938964844, 453.09999084472656],
      "angle": -135,
      parent: "sea-s1"
    }
  },
  "sea-s1b": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [350.3000030517578, 490.6000061035156],
      "angle": -135,
      parent: "sea-s1"
    }
  },
  "sea-s10": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [471.2651220703125, 332.18499999999995],
      "size": [102, 94],
      "angle": 45
    }
  },
  "sea-s10a": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [482.6000061035156, 358.40000915527344],
      "angle": 45,
      parent: "sea-s10"
    }
  },"sea-s10b": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [445.09999084472656, 320.79998779296875],
      "angle": 45,
      parent: "sea-s10"
    }
  },
  "sea-s9": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [547.6351220703125, 408.55500000000006],
      "size": [102, 94],
      "angle": 45
    }
  },
  "sea-s9a": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [558.9500122070312, 434.6999969482422],
      "angle": 45,
      parent: "sea-s9"
    }
  },
  "sea-s9b": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {
      "center": [521.4000244140625, 397.1999816894531],
      "angle": 45,
      parent: "sea-s9"
    }
  },
  "sea-s4": {
    "terminal": "ssat",
    "airline": "dl",
    "mapConfig": {
      "center": [476.9151220703125, 631.9949999999999],
      "size": [102, 94],
      "angle": -135
    }
  },
  "sea-s4a": {
    "terminal": "ssat",
    "airline": "dl",
    "mapConfig": {
      "center": [465.6000061035156, 605.8999633789062],
      "angle": -135,
      parent: "sea-s4"
    }
  },
  "sea-s4b": {
    "terminal": "ssat",
    "airline": "dl",
    "mapConfig": {
      "center": [503.09999084472656, 643.2999572753906],
      "angle": -135,
      parent: "sea-s4"
    }
  },
  "sea-s2": {"terminal": "ssat", "airline": "cu", "mapConfig": {"center": [388.9301220703125, 529], "angle": -135}},
  "sea-s3": {"terminal": "ssat", "airline": "cu", "mapConfig": {"center": [427.1101220703125, 567.25], "angle": -135}},
  "sea-s5": {
    "terminal": "ssat",
    "airline": "",
    "mapConfig": {"center": [597.4301220703124, 643.6099999999999], "angle": 135}
  },
  "sea-s6": {
    "terminal": "ssat",
    "airline": "dl",
    "mapConfig": {"center": [635.6101220703125, 605.4300000000001], "angle": 135}
  },
  "sea-s7": {"terminal": "ssat", "airline": "dl", "mapConfig": {"center": [635.6170654296875, 511.48779296875], "angle": 45}},
  "sea-s8": {"terminal": "ssat", "airline": "dl", "mapConfig": {"center": [597.43359375, 473.2982177734375], "angle": 45}},
  "sea-s11": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {"center": [406.5145568847656, 282.3853759765625], "angle": 45}
  },
  "sea-s12": {"terminal": "ssat", "airline": "cu", "mapConfig": {"center": [312.5666809082031, 282.384765625], "angle": -45}},
  "sea-s15": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {"center": [274.3831787109375, 320.568115234375], "angle": -45}
  },
  "sea-s16": {
    "terminal": "ssat",
    "airline": "cu",
    "mapConfig": {"center": [274.3831787109375, 414.51220703125], "angle": -135}
  },
  "sea-b1": {"terminal": "conc b", "airline": "dl", "mapConfig": {"center": [1394.0001220703125, 307], "angle": 0}},
  "sea-b3": {"terminal": "conc b", "airline": "dl", "mapConfig": {"center": [1340.0001220703125, 307], "angle": 0}},
  "sea-b5": {"terminal": "conc b", "airline": "dl", "mapConfig": {"center": [1286.0001220703125, 307], "angle": 0}},
  "sea-b5a": {"terminal": "conc b", "airline": "dl", "mapConfig": {"center": [1232.0001220703125, 307], "angle": 0}},
  "sea-b7": {"terminal": "conc b", "airline": "dl", "mapConfig": {"center": [1151.0001220703125, 293], "angle": 0,"size": [102, 100]}},
  "sea-b7a": {"terminal": "conc b", "airline": "as", "mapConfig": {"center": [1124.0001220703125, 307], "angle": 0, parent: 'sea-b7'}},
  "sea-b7b": {"terminal": "conc b", "airline": "as", "mapConfig": {"center": [1178, 307], "angle": 0, parent: 'sea-b7'}},
  "sea-b9": {"terminal": "conc b", "airline": "as", "mapConfig": {"center": [1070.0001220703125, 307], "angle": 0}},
  "sea-b11": {"terminal": "conc b", "airline": "as", "mapConfig": {"center": [1016.0001220703125, 307], "angle": 0}},
  "sea-b15": {"terminal": "conc b", "airline": "as", "mapConfig": {"center": [950.0001220703125, 330], "angle": -90}},
  "sea-b14": {"terminal": "conc b", "airline": "wn", "mapConfig": {"center": [950.0001220703125, 384], "angle": -90}},
  "sea-b12": {"terminal": "conc b", "airline": "wn", "mapConfig": {"center": [950.0001220703125, 438], "angle": -90}},
  "sea-b10": {"terminal": "conc b", "airline": "wn", "mapConfig": {"center": [1016.0001220703125, 459], "angle": 180}},
  "sea-b8": {"terminal": "conc b", "airline": "wn", "mapConfig": {"center": [1070.0001220703125, 459], "angle": 180}},
  "sea-b6": {"terminal": "conc b", "airline": "dl", "mapConfig": {"center": [1124.0001220703125, 459], "angle": 180}},
  "sea-b4": {"terminal": "conc b", "airline": "dl", "mapConfig": {"center": [1178.0001220703125, 459], "angle": 180}},
  "sea-q1": {
    "terminal": "conc c",
    "airline": "qx",
    "mapConfig": {"center": [1599.50830078125, 276.8787841796875], "angle": -45}
  },
  "sea-q2": {
    "terminal": "conc c",
    "airline": "qx",
    "mapConfig": {"center": [1637.6978759765625, 238.6962890625], "angle": -45}
  },
  "sea-q3": {
    "terminal": "conc c",
    "airline": "qx",
    "mapConfig": {"center": [1675.8782958984375, 200.51153564453125], "angle": -45}
  },
  "sea-q4": {
    "terminal": "conc c",
    "airline": "qx",
    "mapConfig": {"center": [1769.1219482421875, 201.51153564453125], "angle": 45}
  },
  "sea-q5": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [1807.302490234375, 239.6962890625], "angle": 45}},
  "sea-q6": {
    "terminal": "conc c",
    "airline": "qx",
    "mapConfig": {"center": [1845.4918212890625, 277.87841796875], "angle": 45}
  },
  "sea-q7": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [1934.0001220703125, 307], "angle": 0}},
  "sea-q8": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [1988.000244140625, 307], "angle": 0}},
  "sea-q10": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [2042.0001220703125, 307], "angle": 0}},
  "sea-q12": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [2096.0001220703125, 307], "angle": 0}},
  "sea-q13": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [2150.0001220703125, 307], "angle": 0}},
  "sea-q14": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [2204.0001220703125, 307], "angle": 0}},
  "sea-q15": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [2258.0001220703125, 307], "angle": 0}},
  "sea-q16": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [2312.0001220703125, 307], "angle": 0}},
  "sea-c3": {"terminal": "conc c", "airline": "as", "mapConfig": {"center": [2150.0001220703125, 459], "angle": 180}},
  "sea-c9": {"terminal": "conc c", "airline": "as", "mapConfig": {"center": [2204.0001220703125, 459], "angle": 180}},
  "sea-c11": {"terminal": "conc c", "airline": "as", "mapConfig": {"center": [2258.0001220703125, 459], "angle": 180}},
  "sea-c15": {"terminal": "conc c", "airline": "as", "mapConfig": {"center": [2312.0001220703125, 459], "angle": 180}},
  "sea-c17": {"terminal": "conc c", "airline": "as", "mapConfig": {"center": [2378.0001220703125, 439], "angle": 90}},
  "sea-c20": {"terminal": "conc c", "airline": "as", "mapConfig": {"center": [2378.0001220703125, 385], "angle": 90}},
  "sea-c18": {"terminal": "conc c", "airline": "qx", "mapConfig": {"center": [2378.0001220703125, 331], "angle": 90}},
  "sea-d1": {
    "terminal": "conc d",
    "airline": "as",
    "mapConfig": {"center": [2049.7523193359375, 573.1475830078125], "angle": 45}
  },
  "sea-d2": {"terminal": "conc d", "airline": "as", "mapConfig": {"center": [2087.9361572265625, 611.328125], "angle": 45}},
  "sea-d3": {
    "terminal": "conc d",
    "airline": "as",
    "mapConfig": {"center": [2126.1219482421875, 649.5081787109375], "angle": 45}
  },
  "sea-d4": {
    "terminal": "conc d",
    "airline": "as",
    "mapConfig": {"center": [2164.3026123046875, 687.6976318359375], "angle": 45}
  },
  "sea-d5": {
    "terminal": "conc d",
    "airline": "as",
    "mapConfig": {"center": [2202.4920654296875, 725.878173828125], "angle": 45}
  },
  "sea-d7": {
    "terminal": "conc d",
    "airline": "as",
    "mapConfig": {"center": [2240.6717529296875, 764.06396484375], "angle": 45}
  },
  "sea-d8": {
    "terminal": "conc d",
    "airline": "as",
    "mapConfig": {"center": [2278.8526611328125, 802.2476806640625], "angle": 45}
  },
  "sea-d9": {
    "terminal": "conc d",
    "airline": "aa",
    "mapConfig": {"center": [2279.4918212890625, 898.121826171875], "angle": 135}
  },
  "sea-d10": {
    "terminal": "conc d",
    "airline": "aa",
    "mapConfig": {"center": [2241.3026123046875, 936.3023681640625], "angle": 135}
  },
  "sea-d11": {
    "terminal": "conc d",
    "airline": "aa",
    "mapConfig": {"center": [2203.1221923828125, 974.4918212890625], "angle": 135}
  },
  "sea-n1": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2789.9725341796875, 565.161865234375], "angle": 135}
  },
  "sea-n2": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2751.7918701171875, 603.3419189453125], "angle": 135}
  },
  "sea-n3": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2713.6026611328125, 641.5223388671875], "angle": 135}
  },
  "sea-n4": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2675.4217529296875, 679.7119140625], "angle": 135}
  },
  "sea-n5": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2581.4779052734375, 679.7119140625], "angle": -135}
  },
  "sea-n6": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2543.2882080078125, 641.5223388671875], "angle": -135}
  },
  "sea-n7": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2543.2882080078125, 547.5780029296875], "angle": -45}
  },
  "sea-n8": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2581.4779052734375, 509.3975830078125], "angle": -45}
  },
  "sea-n9": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2619.6583251953125, 471.208251953125], "angle": -45}
  },
  "sea-n10": {
    "terminal": "nsat",
    "airline": "",
    "mapConfig": {"center": [2657.8380126953125, 433.0277099609375], "angle": -45}
  },
  "sea-n11": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2696.0279541015625, 394.838134765625], "angle": -45}
  },
  "sea-n12": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2734.2081298828125, 356.6580810546875], "angle": -45}
  },
  "sea-n13": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2772.3975830078125, 318.4776611328125], "angle": -45}
  },
  "sea-n14": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2810.5782470703125, 280.289794921875], "angle": -45}
  },
  "sea-n15": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2904.5223388671875, 280.289794921875], "angle": 45}
  },
  "sea-n16": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2942.7120361328125, 318.4776611328125], "angle": 45}
  },
  "sea-n17": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2942.7120361328125, 412.4219970703125], "angle": 135}
  },
  "sea-n18": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2904.5223388671875, 450.6024169921875], "angle": 135}
  },
  "sea-n19": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2866.3421630859375, 488.791748046875], "angle": 135}
  },
  "sea-n20": {
    "terminal": "nsat",
    "airline": "as",
    "mapConfig": {"center": [2828.1619873046875, 526.972412109375], "angle": 135}
  }
}