import ApronAlert from "../../models/apronAlert";
import {action, computed, makeObservable, observable, reaction} from "mobx";
import {keyBy, orderBy} from "lodash";
import SidebarStore from "../sidebar";

export abstract class ApronAlertsStore {
  disposed = false;
  allAlerts: ApronAlert[] = [];
  ready = false;
  isLoadingMore = false;
  canLoadMore = true;
  syncStart: string | null = null

  constructor(public sidebarStore: SidebarStore) {
    makeObservable(this, {
      ready: observable,
      isLoadingMore: observable,
      canLoadMore: observable,
      alerts: computed,
      allAlerts: observable,
      addAlerts: action,
      loadMore: action
    })

    if(this.sidebarStore.root.authStore.isSigned)
      this.init();
    else {
      reaction(()=>this.sidebarStore.root.authStore.isSigned,(signed, signedBefore)=>{
        if(signed && !signedBefore){
          this.init();
        }
      })
    }
  }

  get alerts() {
    return this.allAlerts.filter(a=>!a.retracted)
  }

  protected abstract init():void;

  addAlerts(alerts: ApronAlert[]): ApronAlert[] {
    if(!alerts.length)
      return [];
    const existingAlerts = keyBy(this.allAlerts, 'id');
    const newAlerts: ApronAlert[] = [];
    alerts.forEach((a: ApronAlert) => {
      if(!existingAlerts[a.id])
        newAlerts.push(a);
      existingAlerts[a.id] = a;
    });
    this.allAlerts.length = 0;
    this.allAlerts.push(...orderBy(Object.values(existingAlerts), ['ts'], ['desc']))
    return newAlerts;
  }

  abstract loadMore(): void

  dispose() {
    this.disposed = true;
  }
}