import React, { useContext } from "react";
import cn from "bem-cn";
import { observer } from "mobx-react";
import { GateContext } from "./GateContext";
import { textHeight } from ".";

const b = cn("gate-pic");

export const PlaneIcon: React.FC = observer(() => {
  const { planeOffsetY } = useContext(GateContext);
  const planeSize = [36, 40];
  const planePath =
    "M16.772 39.4404C15.7963 38.4261 15.3084 36.1819 15.3084 32.7078L15.3084 24.3139L0.353269 13.769L0.235513 13.6816C0.0785042 13.5184 -2.33141e-06 13.3318 -2.34975e-06 13.122L-2.54544e-06 10.8836C-2.56378e-06 10.6738 0.0785001 10.4872 0.235509 10.324C0.471025 10.0792 0.734575 10.0151 1.02617 10.1316L15.3084 15.3953L15.3084 6.82654C11.8766 4.84464 10.0822 3.77209 9.92523 3.60887C9.76822 3.44566 9.68972 3.25913 9.68972 3.04928L9.68971 0.8109C9.68971 0.601054 9.76822 0.414519 9.92523 0.251303C10.1271 0.0414566 10.3738 -0.0284897 10.6654 0.0414565L17.9832 2.22737L25.3009 0.0414553C25.5925 -0.0518103 25.8449 0.0123071 26.0579 0.233811C26.2149 0.397027 26.2935 0.583562 26.2935 0.793408L26.3103 3.04928C26.3103 3.25912 26.2318 3.44565 26.0748 3.60887C25.9178 3.77208 24.1234 4.84464 20.6916 6.82654L20.6916 15.3953L34.9738 10.1316C35.2654 10.0151 35.529 10.0792 35.7645 10.324C35.9215 10.4872 36 10.6737 36 10.8836L36 13.122C36 13.3318 35.9215 13.5184 35.7645 13.6816C35.7196 13.7282 35.6804 13.7573 35.6467 13.769L20.6916 24.3139L20.6916 32.7078C20.6916 36.1819 20.2037 38.4261 19.228 39.4404C18.8692 39.8135 18.4598 40 18 40C17.5402 40 17.1308 39.8135 16.772 39.4404Z";
  let planeTransform = `translate(${-planeSize[0] / 2},${
    -planeSize[1] / 2 + textHeight / 2 + planeOffsetY
  })`;

  return (
    <path className={b("plane")} d={planePath} transform={planeTransform} />
  );
});
