import React, {
  PropsWithChildren,
  SyntheticEvent,
  useContext,
  useMemo,
  useState
} from 'react';
import './style.scss';
import classnames from "classnames";
import Popup, {PopupProps} from "../Popup";

type DropdownContextValue = {
  close: ()=>void;
}
const DropdownContext = React.createContext<DropdownContextValue | null>(null);

function stopPropagation(ev: SyntheticEvent) {
  ev.stopPropagation();
  ev.nativeEvent.stopImmediatePropagation();
};

type Props = {
  className: string;
  menuClassName?: string;
  title: React.ReactNode;
  align?: PopupProps["popupHorizontalAlign"];
  /**
   * Component's ID attribute
   */
  id?: string;
}

const Dropdown:React.FC<Props> = (props: PropsWithChildren<Props>) => {
  const {className, menuClassName, children, title, align, id} = props;
  const [opened,setOpened] = useState(false);

  const contextValue = useMemo<DropdownContextValue>(()=>({
    close: ()=>setOpened(false)
  }),[]);

  const menu = (
    <div className={classnames('dropdown-menu',menuClassName || className)}>
      {children}
    </div>
  )

  return (
    <DropdownContext.Provider value={contextValue}>
      <Popup
        id={id}
        popupContent={menu}
        className={classnames("dropdown-btn", className)}
        popupHorizontalAlign={align}
      >
        <span>{title}</span>
        <i className="far fa-chevron-down" />
      </Popup>
    </DropdownContext.Provider>
  );
};

export default Dropdown;

type DropdownItemProps = {
  active?: boolean | null;
  onSelect: (ev:SyntheticEvent)=>void;
  className?: string;
  disableCloseOnClick?: boolean;
  disabled? :boolean
}

export function DropdownItem(props: PropsWithChildren<DropdownItemProps>) {
  const {active,children,onSelect, className, disableCloseOnClick, disabled} = props;
  const {close} = useContext(DropdownContext) as DropdownContextValue;
  const onClick = (ev: SyntheticEvent) => {
    if(disabled){
      stopPropagation(ev);
      return;
    }
    onSelect(ev);
    if(!disableCloseOnClick)
      close();
    else
      stopPropagation(ev);
  }
  return (
    <a className={classnames(className,{active,disabled})} onClick={onClick}>
      {children}
    </a>
  )
}