import { useCallback, useEffect, useRef } from "react";
import Hammer from "hammerjs";
import { useRefObserver } from "./useRefObserver";

export interface ExtendedHammerInput extends HammerInput {
  movementX: number;
  movementY: number;
}

export const DIRECTION_HORIZONTAL = 6;

interface Props<T> {
  /**
   *  DIRECTION_NONE:       1;
      DIRECTION_LEFT:       2;
      DIRECTION_RIGHT:      4;
      DIRECTION_UP:         8;
      DIRECTION_DOWN:       16;
      DIRECTION_HORIZONTAL: 6;  // DIRECTION_LEFT | DIRECTION_RIGHT
      DIRECTION_VERTICAL:   24; // DIRECTION_UP | DIRECTION_DOWN
      DIRECTION_ALL:        30; // DIRECTION_HORIZONTAL | DIRECTION_VERTICAL

   */
  direction?: number;
  threshold?: number;
  enabled?: boolean;
  onPanStart?: (e: ExtendedHammerInput) => void;
  onPanMove?: (e: ExtendedHammerInput) => void;
  onPanEnd?: (e: ExtendedHammerInput) => void;
  ref: React.RefObject<T>;
}

export const useHammer = <T extends Element = HTMLDivElement>({
  ref,
  onPanStart,
  onPanMove,
  onPanEnd,
  direction = Hammer.DIRECTION_ALL,
  threshold = 1,
  enabled = true,
}: Props<T>) => {
  const prevDelta = useRef({
    x: 0,
    y: 0,
  });
  const hammer = useRef<HammerManager | null>(null);

  const destroyHammer = () => {
    if (hammer.current) {
      hammer.current.destroy();
    }
  };

  const extendHammerEvent = (e: HammerInput) => {
    const prevX = prevDelta.current.x;
    const prevY = prevDelta.current.y;

    (e as ExtendedHammerInput).movementX = e.deltaX - prevX;
    (e as ExtendedHammerInput).movementY = e.deltaY - prevY;

    return e as ExtendedHammerInput;
  };

  const initHammer = useCallback(
    (node: T | null) => {
      destroyHammer();

      if (!enabled || node === null)
        return;

      hammer.current = new Hammer.Manager(node);
      hammer.current.add(
        new Hammer.Pan({
          direction,
          threshold,
        })
      );

      hammer.current.on("panstart", (e) => {
        onPanStart && onPanStart(extendHammerEvent(e));

        prevDelta.current = {
          x: e.deltaX,
          y: e.deltaY,
        };
      });
      hammer.current.on("panmove", (e) => {
        onPanMove && onPanMove(extendHammerEvent(e));

        prevDelta.current = {
          x: e.deltaX,
          y: e.deltaY,
        };
      });
      hammer.current.on("panend", (e) => {
        onPanEnd && onPanEnd(extendHammerEvent(e));

        prevDelta.current = {
          x: 0,
          y: 0,
        };
      });
    },
    [direction, threshold, enabled]
  );

  useRefObserver<T>(ref, initHammer);

  useEffect(() => {
    return () => destroyHammer();
  }, []);
};
