import React, {useContext, useEffect, useRef, useState} from 'react';
import {throttle} from 'lodash';
import styles from "./style.module.scss";
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import { CSSTransitionProps } from "react-transition-group/CSSTransition";

import Spinner from "../Spinner";
import { CollapsibleSection, CURRENT_TURNS_LABEL, PREVIOUS_TURN_LABEL } from './CollapsibleSection';
import ApronAlertContainer from "../ApronAlertContainer";
import ApronAlertContainerStyles from "../ApronAlertContainer/style.module.scss";

import { observer } from 'mobx-react';
import {RootContext} from "../../services/react";
import { findLast } from 'lodash';
import {SELECTED_TURN_TAB} from "../../constants/strings";
import classNames from 'classnames';

interface Props {
  className: string;
}

function getLastVisibleAlertId(container: HTMLDivElement) {
  const rect = container.getBoundingClientRect();
  const maxTop = rect.top + rect.height;
  const elems = Array.from(container.querySelectorAll(`.${ApronAlertContainerStyles.alert}`));
  const lastVisible = findLast(elems,el=>el.getBoundingClientRect().top <= maxTop);
  // @ts-ignore
  return lastVisible?.dataset.alertId || null;
}

const cssTransitionProps: CSSTransitionProps = {
  timeout: 200,
  unmountOnExit: true,
  classNames: {
    enter: styles.transitionEnter,
    enterActive: styles.transitionEnterActive,
    exit: styles.transitionExit,
    exitActive: styles.transitionExitActive,
  }
}

const SidebarContent: React.FC<Props> = ({className})=> {
  const rootStore = useContext(RootContext);
  const [activeAlerts,otherAlerts] = rootStore.sidebarStore.getGroupedAlerts();
  const container = useRef<HTMLDivElement | null>(null);
  const {alerts,isLoadingMore,ready,canLoadMore,tab} = rootStore.sidebarStore;

  const [collapsedActive, setCollapsedActive] = useState(false);
  const [collapsedArchived, setCollapsedArchived] = useState(true);

  useEffect(() => {
    if (activeAlerts.length === 0)
      setCollapsedActive(false);
  }, [activeAlerts])

  const notSelectedTurnTab = tab !== SELECTED_TURN_TAB;

  //could be triggered multiple scroll events
  const onLoadMore = throttle(()=>{
    rootStore.sidebarStore.loadMore();
  },2000);

  useEffect(()=>{
    if(!container.current)
      return;
    const lastVisibleAlertId = getLastVisibleAlertId(container.current);
    rootStore.sidebarStore.setLastVisibleAlertId(lastVisibleAlertId);
  },[alerts]);

  const onScroll = ()=> {
    if (collapsedArchived && otherAlerts.length > 0 && notSelectedTurnTab)
      return;

    let el = container.current;
    if(!el)
      return;

    const lastVisibleAlertId = getLastVisibleAlertId(el);
    rootStore.sidebarStore.setLastVisibleAlertId(lastVisibleAlertId);

    if(canLoadMore && !isLoadingMore && Math.abs(el.scrollHeight - el.scrollTop - el.offsetHeight) < 20)
      onLoadMore();
  }

  const otherAlertsList = otherAlerts.map((a) => (
    <CSSTransition
      key={a.id}
      {...cssTransitionProps}
    >
      <ApronAlertContainer alert={a} key={a.id} active={false} />
    </CSSTransition>
  ));
  const loadMoreBtn =
    ready && !isLoadingMore && !!alerts.length && canLoadMore ? (
      <a className={styles.loadMoreBtn} onClick={onLoadMore}>
        Load more...
      </a>
    ) : (
      <></>
    );

  return (
    <div className={classNames(styles.sidebarContent, className)} onScroll={onScroll} ref={container}>
      {!!activeAlerts.length && (
        <CollapsibleSection
          collapsed={collapsedActive}
          onClick={() => setCollapsedActive(!collapsedActive)}
          activeAlerts={activeAlerts}
          label={CURRENT_TURNS_LABEL}
          count={activeAlerts.length}
        >
          <TransitionGroup component={null} >
            {activeAlerts.map((a) => (
              <CSSTransition
                key={a.id}
                {...cssTransitionProps}
              >
                <ApronAlertContainer alert={a} key={a.id} active={true}/>
              </CSSTransition>
            ))}
          </TransitionGroup>
          {otherAlerts.length === 0 && loadMoreBtn}
        </CollapsibleSection>
      )}

      {!!otherAlerts.length && notSelectedTurnTab ? (
        <CollapsibleSection
          collapsed={collapsedArchived}
          onClick={() => setCollapsedArchived(!collapsedArchived)}
          label={PREVIOUS_TURN_LABEL}
          count={otherAlerts.length}
        >
          <TransitionGroup>
            {otherAlertsList}
            {loadMoreBtn}
          </TransitionGroup>
        </CollapsibleSection>
      ) : (
        <>
          {otherAlertsList}
          {loadMoreBtn}
        </>
      )}

      {!ready && <Spinner/>}
      {isLoadingMore && ready && <Spinner className={styles.spinner}/>}
      {ready && !isLoadingMore && alerts.length === 0 && !canLoadMore && <a className={styles.noAlertsMsg}>No incidents found</a>}
    </div>
  )
}

export default observer(SidebarContent);