import React, {Component, ContextType} from "react";
import createPanZoom, {PanZoom, Transform as PanzoomTransform} from 'panzoom';
import ReactTooltip from 'react-tooltip';

import './style.scss';
import {ReactComponent as MapSvg} from './map.svg';
import {ReactComponent as ResetIconSvg} from '../../svg/reset-map.svg';
import Gate from "../Gate";
import {LodashDebounce} from "lodash/fp";
import {createPortal} from "react-dom";
import Spinner from "../Spinner";
import {isIE11} from "../../services/platform";
import {PropsWithClass} from "../../models/common";

import {observer} from "mobx-react";
import {RootContext} from "../../services/react";
import { tourStepIdList } from "../Tour/steps";
import {standsDetails} from "../../constants/stands";
import {ALL_GATES_TAB, MAP_POSITION_STORAGE_KEY, WATCHLIST_TAB} from "../../constants/strings";
import { debounce } from "lodash";

type MapState = {
  showGates: boolean,
}

type ViewBox = [number,number,number,number];

const DEFAULT_SCALE = 0.2;
class Map extends Component<PropsWithClass,MapState> {
  ref = React.createRef<SVGSVGElement>();
  initialViewBox: ViewBox = [0,0,0,0];
  pinchInitialBox?: ViewBox;
  moveInitialViewBox?: ViewBox;
  pinchCenter? : [number,number];
  keepPinchData?: ReturnType<LodashDebounce>;
  inst?: PanZoom;

  static contextType = RootContext;
  context!: ContextType<typeof RootContext>;

  state = {
    showGates: false,
  }

  componentDidMount() {
    const svg = this.ref.current;
    if(!svg)
      return;
    if(!isIE11) {
      this.inst = createPanZoom(svg, {
        minZoom: DEFAULT_SCALE,
        maxZoom: 1,
        initialZoom: DEFAULT_SCALE,
        bounds: true,
        boundsPadding: 0.3,
        onTouch: function (e) {
          return false; // tells the library to not preventDefault touch events.
        },
      });

      this.restoreMapPosition();
 
      this.inst.on('panstart',()=>{
        svg.style.cursor = "grabbing"
      })
      this.inst.on('panend',()=>{
        svg.style.cursor = "default";
      })
      this.inst.on('transform', this.onMapTransform);

    }else {
      svg.style.width = '100%';
      svg.style.height = '100%';
    }

    this.setState({showGates:true});
    this.context.sidebarStore.setDefaultTab();
  }

  componentWillUnmount() {
    this.saveMapPosition();
    this.inst && this.inst.dispose();
  }

  onClick(ev: React.MouseEvent) {
    // @ts-ignore
    const rect = ev.target.getBBox();
  }

  saveMapPosition() {
    if (!this.inst)
      return;

    localStorage.setItem(MAP_POSITION_STORAGE_KEY, JSON.stringify(this.inst.getTransform()));
  }

  restoreMapPosition() {
    const initialMapPositionString = localStorage.getItem(MAP_POSITION_STORAGE_KEY);
    if (!initialMapPositionString)
      return;

    const parsedMapPosition: PanzoomTransform = JSON.parse(initialMapPositionString);
    this.applyTransform(parsedMapPosition);
  }

  applyTransform({x, y, scale}: PanzoomTransform) {
    if (!this.inst)
      return;

    this.inst.zoomAbs(0, 0, scale);
    this.inst.moveTo(x, y);
  }

  resetMapPosition() {
    this.applyTransform({
      x: 0,
      y: 0,
      scale: DEFAULT_SCALE,
    });
  }

  onMapTransform = debounce(() => {
    this.saveMapPosition()
  }, 50);

  getSvgCords(x:number,y:number) :[number,number]{
    const svg = this.ref.current as SVGSVGElement;
    const point = svg.createSVGPoint();
    point.x = x;
    point.y = y;
    const ctm = svg.getScreenCTM() as DOMMatrix;
    const inverse = ctm.inverse();
    const p2 = point.matrixTransform(inverse);
    return [p2.x,p2.y];
  }

  render() {
    const {showGates} = this.state;
    const {activeTurnaroundsLoaded: loaded} = this.context.standsStore;
    const {className} = this.props;
    const allStands = Object.keys(standsDetails);

    return (
      <>
        <div id={tourStepIdList.map} className={'map ' + className} >
        <ReactTooltip
            effect={"solid"}
            className="map-tooltip"
            place="bottom"
            offset={{ top: 5 }}
          />
          <MapSvg
            ref={this.ref}
            onClick={ev=>this.onClick(ev)}
            className="map-svg"
          />
          <a data-tip="Center map" onClick={() => this.resetMapPosition()} className="reset-btn">
            <ResetIconSvg />
          </a>
          {!loaded && <div className={'spinner-bk'}><Spinner/></div>}
        </div>
        {showGates && this.ref.current && createPortal(
          allStands.map(id=><Gate standId={id} key={id}/>),
          this.ref.current
        )}
      </>
    );
  }
}

export default observer(Map);