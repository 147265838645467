import React from "react";
import Logo from "../Logo"
import './style.scss'
import {COPYRIGHT} from "../../constants/strings";

const DashboardOff: React.FC = ()=>{
  return (
    <div className={'dashboard-off-page'}>
      <div className={'content'}>
        <div className={'msg'}>
          <h1>Assaia turnaround control is switched off</h1>
          <div className={'off-symbol'}/>
          <div className={'contact'}>
            For questions please write to<br/>
            <a href={'mailto:support@assaia.com'}>ch@assaia.com</a>
          </div>
        </div>
      </div>
      <footer>
        <Logo/>
        <span>{COPYRIGHT}</span>
      </footer>
    </div>
  )
};

export default DashboardOff;