import React, {Component} from 'react';
import {ERROR_MESSAGE} from "../../constants/strings";
import './style.scss';
import cn from "bem-cn";
import {PropsWithClass} from "../../models/common";

type Props = {
  msg?: string,
  showRefreshBtn?: boolean,
} & PropsWithClass

const b = cn('error-message');

const ErrorMessage:React.FC<Props> = ({msg,showRefreshBtn = true,className}) => {
  return (
    <div className={b.mix(className)}>
      <i className={b('tower-icon').mix("far fa-broadcast-tower")}/>
      <h1 className={b('title')}>{msg || ERROR_MESSAGE}</h1>
      {showRefreshBtn && (
        <a onClick={()=>window.location.reload()} className={b('refresh-btn')}>
          <i className="far fa-sync-alt"/>
          retry
        </a>
      )}
    </div>
  )
};

export default ErrorMessage;