import React, { useEffect } from "react";
import { getCb } from "../services/data";

interface Props {
  onWindowResize?: (e?: Event) => void;
  onDocumentClick?: (e?: PointerEvent) => void;
  onDocumentMouseDown?: (e?: PointerEvent) => void;
  onDocumentMouseUp?: (e?: PointerEvent) => void;
  onKeyDown?: (e: KeyboardEvent) => void;
}

export const useWindowEventListener = ({
  onWindowResize,
  onDocumentClick,
  onDocumentMouseDown,
  onDocumentMouseUp,
  onKeyDown,
}: Props) => {
  useEffect(() => {
    const onWindowResizeCb = getCb(onWindowResize);
    const onDocumentClickCb = getCb(onDocumentClick as ((e?: Event) => void));
    const onDocumentMouseDownCb = getCb(onDocumentMouseDown as ((e?: Event) => void));
    const onDocumentMouseUpCb = getCb(onDocumentMouseUp as ((e?: Event) => void));
    const onKeyDownCb = getCb(onKeyDown as ((e?: Event) => void));

    onWindowResizeCb && window.addEventListener("resize", onWindowResizeCb);
    onDocumentClickCb && document.addEventListener("click", onDocumentClickCb);
    onDocumentMouseDownCb && document.addEventListener("mousedown", onDocumentMouseDownCb);
    onDocumentMouseUpCb && document.addEventListener("mouseup", onDocumentMouseUpCb);
    onKeyDownCb && document.addEventListener("keydown", onKeyDownCb);

    return () => {
      onWindowResizeCb && window.removeEventListener("resize", onWindowResizeCb);
      onDocumentClickCb && document.removeEventListener("click", onDocumentClickCb);
      onDocumentMouseDownCb && document.removeEventListener("mousedown", onDocumentMouseDownCb);
      onDocumentMouseUpCb && document.removeEventListener("mouseup", onDocumentMouseUpCb);
      onKeyDownCb && document.removeEventListener("keydown", onKeyDownCb);
    }
  });
};
