import React, {Component, createRef} from 'react';
import cn, {IBlock} from 'bem-cn';
import {debounce, findIndex, findLastIndex, groupBy, last, orderBy} from "lodash";
import {observer} from "mobx-react";

import './style.scss';
import Turnaround from "../../models/turnaround";
import {EMPTY_APRON_LABEL} from "../../constants/strings";
import {resetTime} from "../../services/time";
import {RootContext} from "../../services/react";
import {RootStore} from "../../stores/root";
import GenericTurnaroundsList, {TurnaroundsGroup,GenericTurnaroundsList as ListClass} from "./list";
import { turnaroundsRequestLimit } from '../../services/config';

type Props = {
  b: IBlock
}

type ListState = {
  loading: boolean;
}

//TODO alert network errors
class LocalTurnaroundsList extends Component<Props, ListState> {
  static contextType = RootContext;
  context! : RootStore;

  state: ListState = {
    loading: false,
  };

  listRef = createRef<ListClass>()

  onScroll = (ev: React.UIEvent) => {
    if(!this.state.loading)
      this.checkSkeletons();
  }

  async checkSkeletons() {
    const container = this.listRef.current?.scrollRef.current;
    if(!container)
      return;

    const mainRect = container.getBoundingClientRect();
    const skeletonClass = this.props.b('skeleton');
    const turnClass = this.props.b('turnaround');

    const skeletons = Array.from(container.querySelectorAll('.' + skeletonClass)) as HTMLElement[];
    const allElements = Array.from(container.querySelectorAll(`.${skeletonClass}, .${turnClass}`)) as HTMLElement[];
    const visibleSkeletons = skeletons.filter(s=>{
      const rect = s.getBoundingClientRect();
      return rect.top < mainRect.bottom && rect.bottom > mainRect.top
    });
    if(!visibleSkeletons.length)
      return;

    const groups: (HTMLElement[])[] = [[]];
    visibleSkeletons.forEach((s,index)=>{
      if(index && !s.previousElementSibling?.classList.contains(skeletonClass))
        groups.push([]);
      last(groups)?.push(s);
    });

    this.setState({loading: true});

    for(let group of groups) {
      const firstSkeleton = group[0];
      const firstSkeletonIndex = allElements.indexOf(firstSkeleton);
      const lastSkeleton = group[group.length-1];
      const lastSkeletonIndex = allElements.indexOf(lastSkeleton);

      const newestTurnIndex = findLastIndex(allElements,el=>el.classList.contains(turnClass),firstSkeletonIndex);
      const newestTurnId = allElements[newestTurnIndex]?.dataset.turnId;
      if(!newestTurnId)
        return;

      const oldestTurnIndex = findIndex(allElements,el=>el.classList.contains(turnClass),lastSkeletonIndex);
      const oldestTurnId = allElements[oldestTurnIndex]?.dataset.turnId || null;

      if(oldestTurnIndex !== -1 && (oldestTurnIndex - lastSkeletonIndex) < (firstSkeletonIndex - newestTurnIndex)){
        this.listRef.current?.startScrollPreserving();
        await this.loadGap(newestTurnId,oldestTurnId,"asc");
        this.listRef.current?.stopScrollPreserving();
      }
      else
        await this.loadGap(newestTurnId,oldestTurnId,"desc");
    }

    this.setState({loading: false});
  }

  //TODO dont load whole gap, just visible part
  async loadGap(maxId: string, minId: string | null,direction: "asc" | "desc") {
    if(direction === "asc" && minId) {
      do {
        const newTurns: Turnaround[] = await this.context.standStore!.loadTurnaroundsAfter(minId);
        if(newTurns.map(t=>t.id).includes(maxId))
          break;
        minId = newTurns[0]?.id
      }while (minId);
    }else if (direction === "desc") {
      if(!minId) {
        await this.context.standStore!.loadTurnaroundsBefore(maxId);
      }else {
        do {
          const newTurns: Turnaround[] = await this.context.standStore!.loadTurnaroundsBefore(maxId);
          if(newTurns.map(t=>t.id).includes(maxId))
            break;
          maxId = newTurns[newTurns.length-1]?.id;
        }while (maxId);
      }
    }
  }

  getGroups() {
    const {turnarounds,averageIterationLength,standId,turnaroundsRanges} = this.context.standStore!;
    const {timezone} = this.context;
    const groups: TurnaroundsGroup[] = [];

    if(!!turnarounds[0]?.end) {
      groups.push({
        date: resetTime(Date.now(), timezone),
        items: [{label: EMPTY_APRON_LABEL, href:`/${standId}`}]
      })
    }

    turnaroundsRanges.forEach((range,index)=>{
      range.turnarounds.forEach(turn=>{
        let group = last(groups);
        const dt = resetTime(turn.start, timezone);
        if(!group || group.date !== dt) {
          group = {date:dt, items: []};
          groups.push(group);
        }
        group.items.push(turn);
      });

      const nextRange = turnaroundsRanges[index+1];
      if(nextRange && nextRange.max) {
        const count = Math.floor((range.min-nextRange.max)/averageIterationLength);
        if(count)
          last(groups)?.items.push(...Array(count).fill(null));
      }
      if(!nextRange && !range.final){
        last(groups)?.items.push(...Array(turnaroundsRequestLimit).fill(null));
      }

    });

    return groups;
  }

  render() {
    const {b} = this.props;
    return (
      <GenericTurnaroundsList loading={this.state.loading} groups={this.getGroups()} b={b} onScroll={this.onScroll} ref={this.listRef}/>
    );
  }
}

export default observer(LocalTurnaroundsList);