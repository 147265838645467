import React, {Component, createRef} from 'react';
import cn, {Block, IBlock} from 'bem-cn';
import {last, orderBy} from "lodash";

import './style.scss';
import Turnaround from "../../models/turnaround";
import {searchTurnarounds} from "../../services/api";
import {RootContext} from "../../services/react";
import {RootStore} from "../../stores/root";
import {observer} from "mobx-react";
import {turnaroundsRequestLimit} from "../../services/config";
import GenericTurnaroundsList, {TurnaroundsGroup} from "./list";
import {resetTime} from "../../services/time";

type Props = {
  b: IBlock,
  query: string,
  allStands: boolean
}

type ListState = {
  searchResult: (Turnaround | null)[],
  canLoadMore: boolean,
  loading: boolean
}

//TODO alert network errors
class GlobalTurnaroundsList extends Component<Props, ListState> {
  static contextType = RootContext;
  context! : RootStore;

  state: ListState = {
    searchResult: [],
    canLoadMore: true,
    loading: false,
  };

  componentDidMount() {
    this.search();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<ListState>, snapshot?: any) {
    if (prevProps.query !== this.props.query || prevProps.allStands !== this.props.allStands) {
      this.search();
    }
  }

  getOrderedActiveTurns() {
    return orderBy(Object.values(this.context.standsStore.activeTurnarounds),['start'],['desc']);
  }

  search() {
    if(!this.props.query){
      this.setState({searchResult: []});
      return;
    }
    this.setState({loading: true});

    const {query,allStands} = this.props;
    this.setState({searchResult: []});
    searchTurnarounds(allStands ? null : this.context.standStore?.standId,query,undefined,turnaroundsRequestLimit)
      .then((turns)=>{
        if(allStands !== this.props.allStands || query !== this.props.query)
          return;

        this.setState({
          searchResult: [...turns],
          canLoadMore: turns.length >= turnaroundsRequestLimit
        });
      })
      .finally(()=> this.setState({loading: false}));
  }

  loadMore() {
    this.setState({loading: true});

    const {query,allStands} = this.props;
    const before = last(this.state.searchResult.filter(i=>i))?.start;
    searchTurnarounds(allStands ? null : this.context.standStore?.standId,query,before,turnaroundsRequestLimit).then((turns)=>{
      if(allStands !== this.props.allStands || query !== this.props.query)
        return;
      const length = this.state.searchResult.length;
      const searchResult = [...this.state.searchResult.filter(i=>i),...turns];
      if(turns.length >= turnaroundsRequestLimit) {
        const remain = length - searchResult.length;
        if(remain > 0){
          searchResult.push(...Array(remain).fill(null));
          this.loadMore();
        }
      }else {
        this.setState({canLoadMore: false});
      }
      this.setState({searchResult},()=>{
        if(this.state.searchResult.some(i=>i===null))
          this.loadMore();
      });
    }).finally(()=>this.setState({loading: false}));
}

  onScrolledToEnd = () => {
    if(!this.props.query)
      return;
    if(this.state.loading || !this.state.canLoadMore)
      return;

    console.log("load more");
    this.loadMore();
    const searchResult = [...this.state.searchResult];
    searchResult.push(...Array(turnaroundsRequestLimit).fill(null));
    this.setState({searchResult});
  };

  getGroups():TurnaroundsGroup[] {
    const {timezone} = this.context;
    const groups: TurnaroundsGroup[] = [];

    if(this.state.loading && !this.state.searchResult.length)
      return [
        {
          label: 'Loading...',
          items: Array(10).fill(null),
          hideCounter: true
        }
      ]

    this.state.searchResult.forEach((i)=>{
      if(!i) {
        const group = last(groups);
        if(group)
          group.items.push(i);
      }else {
        let group = last(groups);
        const dt = resetTime(i.start, timezone);
        if(!group || group.date !== dt) {
          group = {date:dt, items: []};
          groups.push(group);
        }
        group.items.push(i);
      }
    })
    return groups;
  }


  render() {
    const {query,b} = this.props;

    let groups: TurnaroundsGroup[] = [];
    if(!query){
      groups = [{
        label: 'Active turnarounds',
        items: this.getOrderedActiveTurns()
      }]
    }else {
      groups = this.getGroups()
    }

    return (
      <GenericTurnaroundsList loading={this.state.loading} query={query} groups={groups} b={b} onScrolledToEnd={this.onScrolledToEnd}/>
    );
  }
}

export default observer(GlobalTurnaroundsList);