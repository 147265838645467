import {detect} from "detect-browser";
import {enableBrowserNotifications} from "./config";
import vanillaYesNo from "../components/VanillaYesNo";

export const browser = detect();
console.log(browser);

export const isWindows =  browser?.os?.toLowerCase().indexOf('windows') !== -1;

export const isFirefox =  browser?.name?.toLowerCase().indexOf('firefox') !== -1;

export const isIOS = browser?.os?.toLowerCase().indexOf('ios') !== -1;

export const isDesktopSafari = (
  browser?.os?.toLowerCase().indexOf('mac') !== -1 &&
  browser?.name.toLowerCase().indexOf('safari') !== -1
);

export const isIE11 = browser?.name?.toLowerCase().indexOf('ie') !== -1;

export const isEdge = browser?.name?.toLowerCase().indexOf('edge') !== -1;

const mobileWidth = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--mobile-width').replace('px',''));
export const isMobile = window.innerWidth <= mobileWidth;

export const isTouchDevice = () =>
  "ontouchstart" in window || navigator.maxTouchPoints > 0;

export let browserNotificationsActivated = (() => {
  if (!("Notification" in window)) return false;
  return Notification.permission === "granted"
})();

export function requestNotificationsPermission() {
  return;

  if (!("Notification" in window))
    return;

  const step1 = () => {
    if(isDesktopSafari) {
      Notification.requestPermission(step2)
    }else
      Notification.requestPermission().then(step2);
  }
  const step2 = (permission:string) => {
    if (permission === "granted") {
      browserNotificationsActivated = true;
    }
  };

  if(isFirefox) {
    vanillaYesNo("Allow desktop notifications?",(v)=>{
      if(v)
        step1();
    })
  }else {
    step1();
  }
}
