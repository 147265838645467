import React, { useContext } from "react";
import cn from "bem-cn";
import { observer } from "mobx-react";
import { GateContext } from "./GateContext";
import { getAirlineIcon, getStandLabel } from "../../services/data";
import { textHeight } from ".";

const b = cn("gate-pic");

export const StandLabel: React.FC = observer(() => {
  const context = useContext(GateContext);
  const turnaround = context.turnaround!;
  const { size, angle, standId, parent } = context;

  const getLabel = (id: string) => {
    let label = getStandLabel(id);

    return label;
  }

  let standLabel = getLabel(standId);
  if (parent) {
    // Use short label for child gates
    let parentLabel = getLabel(parent);
    standLabel = standLabel.replace(parentLabel,"");
  }

  const rotated = Math.abs(angle) > 90;
  const rotate = rotated ? "rotate(180)" : "rotate(0)";
  const sign = rotated ? -1 : 1;

  const xShift = (-size[0] / 2) * sign;
  const yShift = -size[1] / 2 + 16 * +rotated;

  const rectId = `rect-${standId}`;
  const clipId = `clip-${standId}`;

  return (
    <g>
      <g>
        <rect
          className={b("header")}
          x={0}
          y={0}
          width={size[0]}
          height={textHeight / 2}
          transform={`translate(${xShift}, ${
            yShift + (textHeight / 2) * +!rotated
          }) ${rotate}`}
        />
        <rect
          className={b("header")}
          x={0}
          y={0}
          rx={2}
          width={size[0]}
          height={textHeight}
          transform={`translate(${xShift}, ${yShift}) ${rotate}`}
        />
      </g>

      <text
        transform={`translate(${xShift + 4 * sign}, ${
          yShift + 2 * sign
        }) ${rotate}`}
        dominantBaseline="hanging"
        className={b("label")}
      >
        {standLabel}
      </text>

      {turnaround && (
        <>
          <defs>
            <rect
              id={rectId}
              x="-4"
              y={rotated ? -4 : 0}
              width="20px"
              height="20px"
              rx="2"
            />
            <clipPath id={clipId}>
              <use href={`#${rectId}`} />
            </clipPath>
          </defs>
          <image
            href={getAirlineIcon(
              turnaround?.outboundFlight?.companyIata ||
                turnaround?.inboundFlight?.companyIata
            )}
            clipPath={`url(#${clipId})`}
            width={`${textHeight}px`}
            height={`${textHeight}px`}
            transform={`translate(${-(
              xShift +
              textHeight * sign
            )}, ${yShift}) ${rotate}`}
          />
        </>
      )}
    </g>
  );
});
