import React, { useContext } from "react";
import cn from "bem-cn";
import { observer } from "mobx-react";
import { GateContext } from "./GateContext";

const b = cn("gate-pic");

export const ProgressCircle: React.FC = observer(() => {
  const context = useContext(GateContext);
  const turnaround = context.turnaround!;
  const { planeOffsetY, angle, disabled } = context;
  const circleCenter = [0, 7 + planeOffsetY];

  if (!turnaround.progress || disabled) return null;

  const progress = Math.floor(turnaround.progress * 100);

  const transform = `translate(${circleCenter[0]} ${circleCenter[1]}) rotate(${-angle} 0 0)`

  return (
    <g transform={transform}>
      <circle
        cx={0}
        cy={0}
        r={12}
        className={b("progress-circle")}
      />
      <text
        x={0}
        y={0}
        dominantBaseline="middle"
        textAnchor="middle"
        className={b("progress-text")}
      >
        {progress}%
      </text>
    </g>
  );
});
