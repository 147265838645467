import React, { ChangeEvent, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import moment from "moment";
import "moment-timezone";
import "react-datepicker/dist/react-datepicker.css";
import { ReactComponent as CalendarSvg } from "../../svg/calendar.svg";
import { ReactComponent as ClockSvg } from "../../svg/clock.svg";

import styles from "./style.module.scss";
import classnames from "classnames";

function formatDate(ts: number, timezone: string) {
  const zone = moment.tz.zone(timezone);
  if (!zone) return new Date(ts);
  let offset =
    (zone.utcOffset(Date.now()) - new Date().getTimezoneOffset()) * 60 * 1000;
  let res = new Date(ts + offset);
  res.setSeconds(0);
  return res;
}

function parseDate(ts: Date, timezone: string) {
  const zone = moment.tz.zone(timezone);
  if (!zone) return ts.getTime();
  let offset =
    (zone.utcOffset(Date.now()) - new Date().getTimezoneOffset()) * 60 * 1000;
  return ts.getTime() - offset;
}

type Props = {
  onChange: (ts: number) => void;
  value: number;
  min?: number;
  max?: number;
  disabled?: boolean;
  className?: string;
  timezone: string;
};

const DateTimeInput: React.FC<Props> = ({
  onChange,
  value,
  min,
  max,
  disabled,
  className,
  timezone,
}) => {
  const [timeStr, changeTimeStr] = useState("");

  const getDate = () => formatDate(value, timezone);

  useEffect(() => {
    const date = getDate();
    changeTimeStr(moment(date).format("HH:mm"));
  }, [timezone, value]);

  const validateAndSave = (ts: number) => {
    if (min) ts = Math.max(ts, min);
    if (max) ts = Math.min(ts, max);
    changeTimeStr(moment(ts).format("HH:mm"));
    onChange(ts);
  };

  const onDateChange = (val: Date) => {
    const date = getDate();
    val.setHours(date.getHours());
    val.setMinutes(date.getMinutes());
    validateAndSave(parseDate(val, timezone));
  };

  const onTimeChange = (ev: ChangeEvent) => {
    let str = (ev.target as HTMLInputElement).value || "00:00";
    str = str.replace(/_/g, "0");
    let hours = parseInt(str.split(":")[0]);
    let minutes = parseInt(str.split(":")[1]);

    hours = Math.max(hours, 0);
    hours = Math.min(hours, 24);

    minutes = Math.max(minutes, 0);
    minutes = Math.min(minutes, 59);

    const date = getDate();
    date.setHours(hours);
    date.setMinutes(minutes);
    validateAndSave(parseDate(date, timezone));
  };

  return (
    <section className={classnames(styles.dateTimeSection, className)}>
      <div className={classnames(styles.date)}>
        <DatePicker
          wrapperClassName={styles.datePickerWrapper}
          selected={getDate()}
          onChange={onDateChange}
          minDate={min ? new Date(min) : null}
          maxDate={max ? new Date(max) : null}
          portalId="root"
          popperPlacement="top-start"
          disabled={disabled}
          dateFormat={"dd MMM yyyy"}
        />
        {/* {moment(getDate()).format("dddd")} */}
        <CalendarSvg />
      </div>
      <div className={styles.clock}>
        <InputMask
          mask={"99:99"}
          value={timeStr}
          className={styles.time}
          disabled={disabled}
          onChange={(ev) => changeTimeStr(ev.target.value)}
          onBlur={onTimeChange}
        />
        <ClockSvg />
      </div>
    </section>
  );
};

export default DateTimeInput;
