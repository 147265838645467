import alaska from '../images/airlines/alaska.png';
import american from '../images/airlines/american.png';
import delta from '../images/airlines/delta.png';
import horizon from '../images/airlines/horizon.png';
import cu from '../images/airlines/cu.png';
import southwest from '../images/airlines/southwest.png';
import ua from '../images/airlines/ua.png';
import gti from '../images/airlines/atlas.png';
import aca from '../images/airlines/air-canada.png';
import afr from '../images/airlines/air-france.png';
import amx from '../images/airlines/aeromexico.png';
import baw from '../images/airlines/british-airways.png';
import eva from '../images/airlines/eva-air.png';
import cpa from '../images/airlines/cathay-pacific.png';
import cfg from '../images/airlines/condor.png';
import ein from '../images/airlines/aer-lingus.png';
import uae from '../images/airlines/emirates.png';
import fft from '../images/airlines/frontier.png';
import ice from '../images/airlines/iceland-air.png';
import hal from '../images/airlines/hawaii.png';
import chh from '../images/airlines/hainan.png';
import jal from '../images/airlines/japan-airlines.png';
import kal from '../images/airlines/korean-air.png';
import dlh from '../images/airlines/lufthansa.png';
import rch from '../images/airlines/air-mobility-command.png';
import ana from '../images/airlines/ana.png';
import oae from '../images/airlines/omni.png';
import aar from '../images/airlines/asiana.png';
import qtr from '../images/airlines/qatar.png';
import sia from '../images/airlines/singapore.png';
import scx from '../images/airlines/sun-country.png';
import vir from '../images/airlines/virgin-atlantic.png';
import swq from '../images/airlines/swiftair.png';
import wja from '../images/airlines/westjet.png';
import voi from '../images/airlines/volaris.png';
import nks from '../images/airlines/spirit.png';
import jbu from '../images/airlines/jetblue.png';
import msc from '../images/airlines/air-cargo.png';
import noAirline from '../images/airlines/no-airline-transparent.png';

import cai from '../images/airlines/corendon.png';
import eju from '../images/airlines/easyjet.png';
import ewg from '../images/airlines/eurowings.png';
import ryr from '../images/airlines/ryanair.png';
import sas from '../images/airlines/sas.png';
import tvs from '../images/airlines/smartwings.png';
import swr from '../images/airlines/swiss.png';
import tui from '../images/airlines/tui.png';
import cj from '../images/airlines/city-flyer.jpeg';
import hv from '../images/airlines/transavia.png';
import lg from '../images/airlines/luxair.png';
import ux from '../images/airlines/aireuropa.png';
import wk from '../images/airlines/edelweiss.png';
import fb from '../images/airlines/bulgaria.png';
import av from '../images/airlines/avianca.png';
import bw from '../images/airlines/caribbean-airlines.png';
import cm from '../images/airlines/copa.png';
import ey from '../images/airlines/etijad.png';
import ku from '../images/airlines/kuwait.jpeg';
import kq from '../images/airlines/kenya.png';
import ly from '../images/airlines/israel.jpeg';
import ms from '../images/airlines/egyptair.png';
import jj from '../images/airlines/latam.png';
import ix from '../images/airlines/india.png';
import hy from '../images/airlines/hala.png';

const airlines: {[k:string]:string} = {
  noAirline: noAirline,

  AV: av,
  BW: bw,
  CM: cm,
  EK: uae,
  EY: ey,
  KU: ku,
  KQ: kq,
  LY: ly,
  MS: ms,
  SQ: sia,
  VS: vir,
  WS:wja,
  B6: jbu,
  CX: cpa,
  JJ: jj,
  IX: ix,
  HY: hy,

  CJ: cj,
  HV: hv,
  WK: wk,

  CAI: cai,
  XC: cai,
  XR: cai,

  EJU: eju,
  EC: eju,
  U2: eju,
  DS: eju,

  EWG: ewg,
  EW: ewg,
  "4Y": ewg,

  RYR: ryr,
  FR: ryr,
  RK: ryr,

  SAS: sas,
  SK: sas,

  FB: fb,

  TVS: tvs,
  QS: tvs,

  SWR: swr,
  LX: swr,
  SR: swr,

  TUI: tui,
  X3: tui,

  LG: lg,
  UX: ux,

  ASA:alaska,
  AAL:american,
  CUB: cu,
  DAL: delta,
  DL: delta,
  QXE: horizon,
  UAL: ua,
  SWA: southwest,
  GTI: gti,
  ACA: aca,
  AFR: afr,
  AMX: amx,
  BAW: baw,
  BA: baw,
  EVA: eva,
  CPA: cpa,
  CFG: cfg,
  EIN: ein,
  UAE: uae,
  FFT: fft,
  ICE: ice,
  HAL: hal,
  CHH: chh,
  JAL: jal,
  KAL: kal,

  DLH: dlh,
  LH: dlh,

  RCH: rch,
  ANA: ana,
  OAE: oae,
  AAR: aar,
  QTR: qtr,
  SIA: sia,
  SCX: scx,
  VIR: vir,
  SWQ: swq,
  WJA: wja,
  VOI: voi,
  NKS: nks,
  JBU: jbu,
  MSC: msc,
}

export default airlines;
