import React, {useContext, useLayoutEffect} from "react";
import {useHistory} from "react-router-dom";

import userManager from "../../services/userManager";
import {reportError} from "../../services/logger";
import PreLoader from '../PreLoader';
import {getUrlPathBackup} from "../../services/router";
import {RootContext} from "../../services/react";

const RELOADED_AFTER_AUTH_ERROR_KEY = 'RELOADED_AFTER_AUTH_ERROR_KEY';

const AuthCallback: React.FC = () => {
  const history = useHistory();
  const rootStore = useContext(RootContext);

  useLayoutEffect(()=>{
    userManager.signinCallback().then(user=>{
      rootStore.authStore.init(user);
      localStorage.setItem(RELOADED_AFTER_AUTH_ERROR_KEY,"");
      let path = getUrlPathBackup();
      history.push(path || '/');
    },error=> {
      reportError(error);
      if(localStorage.getItem(RELOADED_AFTER_AUTH_ERROR_KEY) !== '1') {
        localStorage.setItem(RELOADED_AFTER_AUTH_ERROR_KEY,'1');
        window.location.pathname = "";
      }
      history.push("/error",{error: error.message});
    })
  },[])

  return <PreLoader/>;
}

export default AuthCallback;
