export async function loadImageAjax(url: string) {
  const resp = await fetch(url);

  if(!resp.ok){
    throw resp;
  }

  const blob = await resp.blob();
  const dataUrl = URL.createObjectURL(blob);
  return dataUrl;
}

function getResolution(url: string):Promise<[number,number]> {
  return new Promise(resolve=>{
    let img = document.createElement('img');
    img.src = url;
    img.style.position = 'absolute';
    img.style.left = '-9999px';
    document.body.appendChild(img);
    img.onload = () => {
      let res:[number,number] = [img.naturalWidth,img.naturalHeight];
      document.body.removeChild(img);
      resolve(res);
    }
  });
}