import {action, makeAutoObservable} from "mobx";
import {flatten, last, orderBy, partition, sum,keyBy,merge} from "lodash";

import {getStandTurnarounds} from "../services/api";
import {frozen, turnaroundsRequestLimit, updateRate} from "../services/config";
import Turnaround from "../models/turnaround";
import StandStore from "./stand";

type TurnaroundsRange = {
  min: number
  max: number
  turnarounds: Turnaround[]
  final: boolean
}

export default class TurnaroundsStore {
  turnaroundsRanges: TurnaroundsRange[] = []
  loaded = false;
  selectedTurnaroundId: string | null = null;

  constructor(private standStore: StandStore) {
    makeAutoObservable(this);
    this.loadTurnaroundsBefore();
  }

  private get allTurnarounds() {
    return flatten(this.turnaroundsRanges.map(r=>r.turnarounds));
  }

  get turnarounds(): ReadonlyArray<Turnaround> {
    return this.allTurnarounds.filter((turn,index)=>{
      return (!index || turn.end) && (!turn.end || turn.end - turn.start >= 60000) && (!frozen || turn.end)
    });
  }

  get turnaroundsMap() {
    return keyBy(this.turnarounds,'id');
  }

  get averageIterationLength(): number {
    const vals: number[] = [];
    this.turnaroundsRanges.forEach(range=>{
      range.turnarounds.forEach((t,index)=>{
        const next = range.turnarounds[index+1];
        if(!t.end || !next?.end)
          return;
        vals.push(t.start - next.start);
      })
    })
    return sum(vals)/vals.length;
  }

  get selectedTurnaround() {
    return this.allTurnarounds.find(t=>t.id === this.selectedTurnaroundId);
  }

  private get selectedTurnPosition(): [TurnaroundsRange,number] | null {
    const {turnaroundsRanges, selectedTurnaround} = this;
    if(!selectedTurnaround)
      return null;

    for(let range of turnaroundsRanges) {
      const index = range.turnarounds.findIndex(t=>t.id === selectedTurnaround.id);
      if(index !== -1) {
        return [range,index];
      }
    }
    return null;
  }

  get canSelectPrevTurn() {
    const {turnaroundsRanges, selectedTurnaround, turnarounds} = this;
    if(!selectedTurnaround && !turnarounds.length)
      return false;

    const lastRange = last(turnaroundsRanges);
    return !(selectedTurnaround && lastRange?.final && last(lastRange.turnarounds)?.id === selectedTurnaround.id);
  }

  get canSelectNextTurn() {
    const {selectedTurnaround,turnarounds} = this;
    if(!selectedTurnaround)
      return false;

    return selectedTurnaround.end || turnarounds[0].id !== selectedTurnaround.id;
  }

  async getPrevTurn(): Promise<Turnaround | null> {
    let {selectedTurnaround,selectedTurnPosition} = this;
    if(!selectedTurnaround) {
      return this.turnaroundsRanges[0].turnarounds[0];
    }
    if(!selectedTurnPosition)
      return null;

    let [currentRange,index] = selectedTurnPosition;

    if(index < currentRange.turnarounds.length-1) {
      return currentRange.turnarounds[index+1];
    }
    await this.loadTurnaroundsBefore(selectedTurnaround.id);

    selectedTurnPosition = this.selectedTurnPosition;
    if(!selectedTurnPosition)
      return null;
    [currentRange,index] = selectedTurnPosition;
    return currentRange.turnarounds[index+1];
  }

  async getNextTurn(): Promise<Turnaround | null> {
    let {selectedTurnaround,selectedTurnPosition} = this;
    if(!selectedTurnPosition || !selectedTurnaround)
      return null;

    let [range,indexInRange] = selectedTurnPosition;

    if(indexInRange > 0) {
      return range.turnarounds[indexInRange-1];
    }

    const rangeIndex = this.turnaroundsRanges.findIndex(r=>r.min === range.min);
    if(rangeIndex === 0) {
      return null;
    }

    await this.loadTurnaroundsAfter(selectedTurnaround.id);
    selectedTurnPosition = this.selectedTurnPosition;
    if(!selectedTurnPosition)
      return null;
    [range,indexInRange] = selectedTurnPosition;
    if(indexInRange === 0)
      return null;
    return range.turnarounds[indexInRange-1];
  }

  setSelectedTurnaround(id: string | null) {
    this.selectedTurnaroundId = id;
  }

  //TODO Handle invalid turn inside new loaded turns
  //TODO catch on side of method (make initial method for turns list with catch and retry)
  loadTurnaroundsBefore(turnId?: string) {
    let before = turnId ? this.turnaroundsMap[turnId].end : undefined;
    if(turnId && !before)
      return Promise.reject();

    const params = {
      before,
      limit: turnaroundsRequestLimit
    }

    return getStandTurnarounds(this.standStore.standId, params).then(action((newTurns: Turnaround[]) => {
      if (this.standStore.disposed)
        return [];
      let max = before ? Math.max(...newTurns.map(t=>t.end || t.start)) : Infinity;
      let min = Math.min(...newTurns.map(t=>t.start));
      this.addTurnarounds(min,max,newTurns,newTurns.length<turnaroundsRequestLimit);
      return newTurns;
    })).catch((er)=>{
      setTimeout(() => {
        this.loadTurnaroundsBefore(turnId);
      }, updateRate);
      throw er;
    })
  }

  loadTurnaroundsAfter(turnId: string) {
    const after =this.turnaroundsMap[turnId].start;
    if(!after)
      return Promise.reject();

    const params = {
      after,
      limit: turnaroundsRequestLimit
    }
    return getStandTurnarounds(this.standStore.standId, params).then(action((newTurns: Turnaround[]) => {
      if (this.standStore.disposed)
        return [];

      const max = Math.max(...newTurns.map(t=>t.end || t.start));
      this.addTurnarounds(after,max,newTurns,newTurns.length<turnaroundsRequestLimit);
      return newTurns;
    }));
  }

  addTurnarounds(min: number, max: number, newTurns: Turnaround[],final? : boolean) {
    if(!this.loaded)
      this.loaded = true;

    const [rangesToMerge,otherRanges] = partition(this.turnaroundsRanges, r=>r.max >= min && r.min <= max);
    const maxRange = rangesToMerge[0];
    const minRange = last(rangesToMerge);

    const newMin = minRange ? Math.min(min,minRange.min) : min;
    const newMax = maxRange ? Math.max(max,maxRange.max) : max;

    const rangeTurns = keyBy(flatten(rangesToMerge.map(r=>r.turnarounds)),'id');
    newTurns.forEach(t=>{
      if(rangeTurns[t.id])
        merge(rangeTurns[t.id],t);
      else
        rangeTurns[t.id] = t;
    })

    if(final === undefined) {
      final = minRange ? minRange.final : false;
    }

    if(minRange && minRange.min < min)
      final = minRange.final;

    const range: TurnaroundsRange = {
      min: newMin,
      max: newMax,
      turnarounds: orderBy(rangeTurns,["start"],["desc"]),
      final
    };
    this.turnaroundsRanges = orderBy([range,...otherRanges],['min'],["desc"]);
  }
}