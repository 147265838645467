import React, {useContext, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react";

import {TimelineContext} from "./index";
import {now} from "../../services/time";
import TimeFormatter from "../TimeFormatter";

type Tick = {
  ts: number,
  left: string
}

const TimeAxis : React.FC = ()=> {
  const {bounds,getClickedTimestamp,standStore} = useContext(TimelineContext);
  const [windowWidth,setWindowWidth] = useState(window.innerWidth);

  const containerRef = useRef<HTMLDivElement>(null);
  const txtMeasureRef = useRef<HTMLSpanElement>(null);
  const [ticks,setTicks] = useState<Tick[]>([]);

  useEffect(()=>{
    const cb = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize',cb);
    return ()=>window.removeEventListener('resize',cb);
  },[]);

  useEffect(()=>{
    if(!containerRef.current || !txtMeasureRef.current)
      return;
    let duration = bounds[1] - bounds[0];
    let axisWidth = containerRef.current.offsetWidth;
    let minTickWidth = txtMeasureRef.current.offsetWidth;
    let min5 = 60 * 5 * 1000;

    let step = (minTickWidth / axisWidth) * duration;
    step = Math.ceil(step / min5) * min5;

    let ts = bounds[0];
    ts = Math.ceil(ts / step) * step;
    let start = bounds[0];

    let ticks: Tick[] = [];
    do {
      ticks.push({
        ts,
        left: (ts - start) * 100 / duration + '%'
      });
      ts += step;
    } while (ts < bounds[1]);
    setTicks(ticks);
  },[...bounds,windowWidth]);

  const onAxisClick = (ev: React.MouseEvent) => {
    const timestamp = getClickedTimestamp(ev.nativeEvent);
    if(!timestamp || timestamp > now())
      return;
    standStore.openModal(timestamp,null);
  };

  return (
    <div
      className={'timeaxis'}
      ref={containerRef}
      onClick={(ev) => onAxisClick(ev)}
    >
      <span className={'text-measure tick'} ref={txtMeasureRef}>00:00:00</span>
      {ticks.map(item =>(
        <div className={'tick'} style={{left: item.left}} key={item.ts}>
          <span>
            <TimeFormatter time={item.ts} format={'HH:'}/>
            <span className={'minutes'}><TimeFormatter time={item.ts} format={'mm'}/></span>
          </span>
        </div>
      ))}
    </div>
  );
}

export default observer(TimeAxis);