import {action, makeAutoObservable, reaction} from "mobx";
import moment from "moment";
import "moment-timezone"

import AuthStore from "./auth";
import {getImgToken} from "../services/api";
import SidebarStore from "./sidebar";
import {airportTimezone, redWarning, updateRate} from "../services/config";
import StandsStore from "./stands";
import {CAMERAS_UNAVAILABLE_MSG, TIME_ZONE_KEY} from "../constants/strings";
import StandStore from "./stand";
import { now } from "../services/time";

export class RootStore {
  authStore: AuthStore;
  standsStore: StandsStore;
  sidebarStore: SidebarStore;
  standStore: StandStore | null = null;

  serverUnavailable = false;
  serverUnavailableCount = 0;
  imgToken: string = "";
  timezones: [string,string][] = [["Local",moment.tz.guess()],["Utc","Etc/UTC"]];
  timezone: string;

  private currentTime = 0;

  constructor() {
    makeAutoObservable(this);

    this.authStore = new AuthStore(this);
    this.standsStore = new StandsStore(this);
    this.sidebarStore = new SidebarStore(this);

    if(airportTimezone)
      this.timezones.push(["Airport",airportTimezone]);

    this.timezone = localStorage.getItem(TIME_ZONE_KEY) || this.timezones[0][1];
    this.setTimezone(this.timezone);

    this.setTime(now());
    setInterval(()=>{
      this.setTime(now());
    }, updateRate || 1000);

    reaction(()=>this.authStore.isSigned,(signed, signedBefore)=>{
      if(signed && !signedBefore){
        this.loadImgToken();
      }
    })
  }

  get topAlert(): string[] {
    let messages: string[] = [];

    const standStore = this.standStore;
    if (standStore?.selectedTurnaround && !standStore.selectedTurnaround.end) {
      const now = Date.now();
      const MINUTES_15 = 15 * 60 * 1000;

      const unfinishedOutages = standStore.outages.filter((outage) => !outage.end);
      const longOutage = unfinishedOutages.find(
        (outage) => now - outage.start > MINUTES_15
      );
      longOutage && messages.push(CAMERAS_UNAVAILABLE_MSG);
    }

    if(!redWarning)
      return messages;

    const standId = standStore?.standId;
    if(!standId) {
      if(typeof redWarning === 'string')
        messages.push(redWarning);
      else
        messages.push(redWarning.default);

      return messages;
    }

    if(typeof redWarning === 'string')
      messages.push(redWarning);
    else
      messages.push(redWarning[standId] || redWarning.default);

    return messages.filter(s => s);
  }

  get time() {
    return this.currentTime;
  }

  //TODO move to auth store
  loadImgToken() {
    getImgToken().then(action((token:string) => {
      this.imgToken = token;
    }))
  }

  toggleServerUnavailable(val: boolean) {
    if(!val)
      this.serverUnavailableCount = 0;
    else
      this.serverUnavailableCount++;
    if(val && this.serverUnavailableCount > 3)
      this.serverUnavailable = true;
    else
      this.serverUnavailable = false;
  }

  setTimezone(zone:string) {
    console.log("set timezone", zone);
    this.timezone = zone;
    localStorage.setItem(TIME_ZONE_KEY, zone);
  }

  setStandStore(store: StandStore | null) {
    this.standStore = store;
  }

  setTime(ts: number) {
    this.currentTime = ts;
  }
}

export const rootStore = new RootStore();

// @ts-ignore
window._rootStore = rootStore;