import classNames from "classnames";
import React, { CSSProperties, useLayoutEffect, useRef, useState } from "react";
import { getCameraLabel } from "../../services/data";
import Dropdown, { DropdownItem } from "../Dropdown";
import { tourStepIdList } from "../Tour/steps";
import styles from "./style.module.scss";
import { useResizeObserver } from "../../hooks/useResizeObserver";

type Props = {
  cameraData: [string, () => void][];
  selectedCamera: string;
};

const CamerasToolbar: React.FC<Props> = ({ cameraData, selectedCamera }) => {
  const [overflow, setOverflow] = useState(false);
  const [width, setWidth] = useState(0);

  const noCameras = cameraData.length < 1;

  const [refCallback, ref] = useResizeObserver({
    onResize: ({ clientWidth }) => setWidth(clientWidth),
  });

  useLayoutEffect(() => {
    if (noCameras) return;

    if (ref.current) {
      const { scrollWidth, clientWidth } = ref.current;
      setOverflow(clientWidth < scrollWidth);
    }
  }, [cameraData, selectedCamera, noCameras, width, ref]);

  if (noCameras) return null;

  const style: CSSProperties = {};
  if (overflow) {
    style.visibility = "hidden";
  }

  return (
    <>
      <div
        ref={refCallback}
        id={!overflow ? tourStepIdList.cameras : undefined}
        className={styles.cameras}
        style={style}
      >
        {cameraData.map(([c, onClick]) => (
          <a
            className={classNames(styles.camera, {
              [styles["active"]]: c === selectedCamera,
            })}
            onClick={onClick}
            key={c}
          >
            {getCameraLabel(c)}
          </a>
        ))}
      </div>
      {overflow && (
        <Dropdown
          id={tourStepIdList.cameras}
          title={getCameraLabel(selectedCamera)}
          align={"left"}
          className={styles.dropdownBtn}
          menuClassName={styles.dropdownMenu}
        >
          {cameraData.map(([c, onClick]) => (
            <DropdownItem
              className={styles.dropdownItem}
              active={c === selectedCamera}
              key={c}
              onSelect={onClick}
            >
              {getCameraLabel(c)}
            </DropdownItem>
          ))}
        </Dropdown>
      )}
    </>
  );
};

export default CamerasToolbar;
