import React, {Component} from 'react';
import {RouteComponentProps, withRouter} from "react-router-dom";
import { observer } from 'mobx-react';
import moment from "moment";
import {IReactionDisposer, reaction, when} from "mobx";

import Spinner from "../Spinner";
import Timeline from "../Timeline";
import ErrorBoundary from "../ErrorBoundary";
import {
  MODAL_DETECTION_ID_QUERY_KEY,
  MODAL_TS_QUERY_KEY,
} from "../../constants/strings";
import TurnaroundStatusFrame from "../TurnaroundStatusFrame";
import {UnauthorizedTurn} from "../EmptyApron";
import {PropsWithClass} from "../../models/common";
import {StandContext} from "../../services/react";
import StandStore from "../../stores/stand";
import TurnaroundInfoPanel from "../TurnaroundInfoPanel";
import {disablePOBT} from "../../services/config";

import styles from "./style.module.scss";
import classNames from 'classnames';

type RouterParams = {
  turnId: string
}

type Props = PropsWithClass & RouteComponentProps<RouterParams>

class Turnaround extends Component<Props, any> {
  static contextType = StandContext;
  context!: StandStore;

  disposers: IReactionDisposer[] = [];

  componentDidMount() {
    const idFromUrl = this.props.match.params.turnId;
    this.context.selectTurnaround(idFromUrl).then((id)=>{
      if(id !== idFromUrl) {
        if(!id)
          this.props.history.push('/' + this.context.standId);
        else
          this.props.history.push('/' + this.context.standId + '/' + id);
      }
    });

    const dispose1 = reaction(()=>this.context.selectedTurnaroundId,(newId, oldId)=>{
      if(!newId)
        this.props.history.push('/' + this.context.standId);
      else if(newId !== this.props.match.params.turnId)
        this.props.history.push('/' + this.context.standId + '/' + newId);
    })
    this.disposers.push(dispose1);

    const dispose2 = when(()=>!!this.context.detections.length,()=>{
      const search = this.props.location.search;
      if (!search || !window.URLSearchParams)
        return;

      const args = new URLSearchParams(search);
      const tsRaw = args.get(MODAL_TS_QUERY_KEY);
      if (!tsRaw) {
        return;
      }
      
      const ts = moment(tsRaw);
      if (ts.isValid()) {
        // Get "delection_id" for backward compatibility
        const detectionId = args.get(MODAL_DETECTION_ID_QUERY_KEY) || args.get("delection_id");
        const detection = detectionId
          ? this.context.detections.find(d => d.id === detectionId)
          : null;

        this.context.openModal(ts.toDate().getTime(), detection || null);
      }
    })
    this.disposers.push(dispose2);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<any>, snapshot?: any) {
    const oldIdFromUrl = prevProps.match.params.turnId;
    const newIdFromUrl = this.props.match.params.turnId;
    if(oldIdFromUrl !== newIdFromUrl && newIdFromUrl !== this.context.selectedTurnaroundId) {
      this.context.selectTurnaround(newIdFromUrl);
    }
  }

  componentWillUnmount() {
    this.disposers.forEach(d=>d());
  }

  render() {
    const {className} = this.props;
    const {selectedTurnaround: turn, lastImageTimestamp, selectedTurnaroundIsLoaded} = this.context;

    //TODO dont wait for lastImageTimestamp
    if (!turn || !selectedTurnaroundIsLoaded || !lastImageTimestamp)
      return <Spinner/>;

    if (!turn.authorized){
      return <UnauthorizedTurn className={className}/>
    }

    if(disablePOBT) {
      return (
        <div className={classNames(styles.inner,className?.toString())}>
          <TurnaroundInfoPanel turnaround={turn}/>
          <ErrorBoundary msg={'Progress chart is broken'} showRefreshBtn={false}>
            <Timeline/>
          </ErrorBoundary>
        </div>
      )
    }

    return (
      <TurnaroundStatusFrame className={className}>
        <div className={styles.inner}>
          <TurnaroundInfoPanel turnaround={turn}/>
          <ErrorBoundary msg={'Progress chart is broken'} showRefreshBtn={false}>
            <Timeline/>
          </ErrorBoundary>
        </div>
      </TurnaroundStatusFrame>
    )
  }
}

export default withRouter(observer(Turnaround));