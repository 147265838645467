import {User, UserManager} from "oidc-client";
import {action, makeAutoObservable} from "mobx";
import {RootStore} from "./root";
import userManager from "../services/userManager";
import {openID} from "../services/config";
import * as Sentry from "@sentry/browser";

export default class AuthStore {
  user: User | null = null;

  constructor(private root: RootStore) {
    makeAutoObservable(this,{
      logout: action.bound,
      setUser: action.bound
    });
  }

  init(user: User) {
    this.setUser(user);
    userManager.events.addUserLoaded(this.setUser);
  }

  setUser(user: User) {
    console.log("set user");

    Sentry.setUser({
      email: user.profile.email,
      // @ts-ignore
      username: user.profile.profile?.full_name
    })
    
    this.user = user;
  }

  logout() {
    if (!window.confirm('Are you sure you want to log out?'))
      return;

    Sentry.setUser(null);
  
    // @ts-ignore
    userManager._settings._metadata['end_session_endpoint'] = openID.authority.replace('/connect', '/logout')
    userManager.signoutRedirect({useReplaceToNavigate:true}).then(()=>{
    });
  }

  get isSigned() {
    return this.user && !this.user.expired
  }

  get authToken() {
    return this.user?.id_token
  }

  get authHeader() {
    if(!this.authToken)
      return "";
    return 'JWT ' + this.authToken;
  }
}