import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { observer } from "mobx-react";

import "./style.scss";
import cn from "bem-cn";
import { PropsWithClass } from "../../models/common";
import Modal from "../Modal";

const b = cn("zoom-container");

type Props = {
} & PropsWithClass;

const ZoomContainer: React.FC<Props> = (props) => {
  const { className, children } = props;
  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const [zoomed, setZoomed] = useState(false);

  const onZoom = useCallback(() => setZoomed(true), []);
  const onUnzoom = useCallback(() => setZoomed(false), []);

  const onWrapperMount = useCallback((node: HTMLDivElement) => {
    if (!wrapperRef.current) {
      wrapperRef.current = node;
    }
  }, []);

  const onModalMount = useCallback((node: HTMLDivElement) => {
    if (!wrapperRef.current)
      return;

    if (wrapperRef.current && node) {
      node.appendChild(wrapperRef.current);
    } else if (ref.current) {
      ref.current.appendChild(wrapperRef.current);
    }
  }, []);

  const Wrapper = (
    <div ref={onWrapperMount} className={b("wrapper")}>
      {children}
    </div>
  );

  return (
    <>
      <div
        id="zoom-container"
        className={b({ zoomed }).mix(className)}
        ref={ref}
      >
        {!zoomed && <a className={b("button")} onClick={onZoom}>
          <i className={`fa fa-lg fa-${zoomed ? "compress" : "expand"}-alt`} />
        </a>}
        {Wrapper}
      </div>
      {zoomed && (
        <Modal
          movable={true}
          resizable={true}
          backdrop={false}
          ref={onModalMount}
          modalTitle={"Camera"}
          onClose={onUnzoom}
          modalClassName={b("modal").toString()}
        />
      )}
    </>
  );
};

export default observer(ZoomContainer);
