import React, {useContext} from 'react';
import {padStart} from 'lodash'
import cn from "bem-cn";

import Turnaround from '../../models/turnaround';
import "./style.scss";
import {TURNAROUND_STATUS_GREEN, TURNAROUND_STATUS_GREY} from "../../constants/strings";
import {PropsWithClass} from "../../models/common";
import {StandContext} from "../../services/react";
import {observer} from "mobx-react";
import {formatDelayHumanText, now} from "../../services/time";

const bars = Array(10).fill(0.1).map((v,i)=>v*i);

const b = cn('turnaround-status-frame');

const TurnaroundStatusFrame: React.FC<PropsWithClass> = ({children,className}) => {
  const standContext = useContext(StandContext);
  const turn = standContext.selectedTurnaround as Turnaround;
  const isLive = !turn.end;
  const {progress} = turn;

  const bkColor = !isLive ? TURNAROUND_STATUS_GREY : TURNAROUND_STATUS_GREEN;
  let remaining = "";

  if(turn.end) {
    remaining = formatDelayHumanText(turn.end - turn.start) + ' TOTAL'
  }else if(turn.pobt) {
    remaining = formatDelayHumanText(turn.pobt - now()) + ' remaining'
  }

  return (
    <div className={b.mix(className)} style={{borderColor: bkColor}}>
      <div className={b('header')} style={{background: bkColor}}>
        <span className={b('status')}>
          {turn.end ? 'completed turnaround' : 'turnaround in progress'}:
        </span>

        {progress !== null ? (
          <div className={b("progress")}>
            {bars.map((barMin) => (
              <div
                key={barMin}
                className={b("progress-bar", { active: progress >= barMin })}
                style={{
                  opacity:
                    progress >= barMin && progress < barMin + 0.1 ? 0.7 : 1,
                }}
              />
            ))}
            &nbsp;
            {Math.floor(progress * 100)}%
          </div>
        ) : !remaining && turn.outboundFlight && (
          <div className={b("status")}>calculating</div>
        )}

        {remaining && (
          <div className={b('time-left')}>{remaining}</div>
        )}
      </div>
      <div className={b('inner')}>
        {children}
      </div>
    </div>
  )
}

export default observer(TurnaroundStatusFrame);