import { observer } from 'mobx-react';
import React, {useContext, useEffect} from 'react';
import {RootContext} from "../../services/react";
import {hotjarId} from "../../services/config";

function init(hotjarId: number) {
  (function(h:any,o,t,j,a,r){
    // @ts-ignore
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:hotjarId,hjsv:6};
    // @ts-ignore
    a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
}

const HotjarManager: React.FC = () => {
  const root = useContext(RootContext);
  const standId = root.standStore?.standId;
  const turnId = root.standStore?.selectedTurnaround?.id;

  useEffect(()=>{
    const email = root.authStore.user?.profile.email;
    if(email && email.indexOf('assaia.com') === -1 && hotjarId) {
      console.log("init hotjar")
      init(hotjarId);
    }else {
      console.log("no hotjar for assaia users")
    }
  },[])

  useEffect(()=>{
    // @ts-ignore
    const hj = window.hj;

    if(hj) {
      let url = "/";
      if(standId && turnId)
        url = "/turnaround";
      else if (standId && !turnId)
        url = "/empty-stand"

      hj('stateChange', url);
    }
  },[standId,turnId])

  return null;
}

export default observer(HotjarManager);