import {action, makeAutoObservable, when} from "mobx";
// @ts-ignore
import chroma from 'chroma-js'

import {RootStore} from "./root";
import {getTurnaround, timeline} from "../services/api";
import {camerasOrder, filterCameras, updateRate} from "../services/config";
import Turnaround from "../models/turnaround";
import CameraOutage from "../models/cameraOutage";
import Detection from "../models/detection";
import {FrameModalData} from "../models/common";
import {now, setTimeOffset, timeOffset} from "../services/time";
import _, {difference, flatten} from "lodash";
import Stand from "../models/stand";
import {detectionToEvents} from "../services/data";
import TurnaroundsStore from "./turnarounds";
import {CURRENT_GATE_TAB, SELECTED_TURN_TAB, WATCHLIST_TAB} from "../constants/strings";
import TimelineStore from "./timeline";

const REQUEST_FAIL_COUNT_THRESHOLD = 2;

let keys = _.uniq(Object.keys((window as any).operationsLabels));
let colors: any = {};
chroma
  .scale(["#fa7900", "#3e7d95"])
  .mode("lch")
  .colors(keys.length)
  .forEach((k:string, i:number) => (colors[keys[i]] = k));

export default class StandStore {
  private allDetections: Detection[] = []
  private turnaroundsStore: TurnaroundsStore;
  timelineStore: TimelineStore | null = null;

  outages: CameraOutage[] = []

  inferenceTimestamp: {[key:string]:number} | null = null
  lastImageTimestamp: {[key:string]:number} | null = null
  displayedTimestamp: number = now()

  resolution: {[key:string]:[number,number]} = {}
  colors: {[key:string]:string} = colors;
  isTurnSelecting = false;
  frameModalData: FrameModalData  | null = null
  timerId: number | null = null;
  disposed = false;
  showConfidenceOnTimeline = false;
  timelineRequestFailCounter = 0;
  fetchedTurnarounds: {[k:string]:boolean} = {}

  constructor(public root: RootStore, public standId: string) {
    this.turnaroundsStore = new TurnaroundsStore(this);

    makeAutoObservable(this,{
      getFrameUrl: action.bound,
      openModal: action.bound,
      selectPrevTurn: action.bound,
      selectNextTurn: action.bound,
    });

    this.sync();

    root.setStandStore(this);
  }

  get stand() {
    return this.root.standsStore.stands.find(s=>s.id === this.standId) as Stand;
  }

  get cameras() {
    const {stand} = this;
    let cameras: string[] = [];
    camerasOrder.forEach((key:string)=>{
      let cam = this.stand.cameras.find(c=>c.endsWith(key));
      if(cam)
        cameras.push(cam);
    });
    cameras.push(...difference(stand.cameras,cameras).sort());

    if(filterCameras){
      let filter = Array.isArray(filterCameras) ? filterCameras : filterCameras[stand.id];
      if(filter)
        cameras = cameras.filter(c=>!filter.some((f:string)=>c.indexOf(f)!==-1));
    }

    return cameras;
  }

  get isLive() {
    const selectedId = this.turnaroundsStore.selectedTurnaroundId;
    const fistTurn = this.turnaroundsStore.turnarounds[0];
    return !selectedId || (fistTurn && fistTurn.id === selectedId && !fistTurn.end);
  }

  get detections() {
    let {turnaroundsStore: {selectedTurnaround}, allDetections} = this;
    if (!selectedTurnaround || !selectedTurnaround.authorized)
      return [];

    let {start} = selectedTurnaround;
    const end = selectedTurnaround.end || now();

    return allDetections.filter(op => op.end
      ? op.start <= end && op.end >= start
      : op.start <= end);
  }

  get events() {
    let events = this.allDetections.map(detectionToEvents);
    return flatten(events);
  }

  get selectedTurnaroundIsLoaded() {
    const turn = this.turnaroundsStore.selectedTurnaround;
    if(!turn)
      return false;
    return this.fetchedTurnarounds[turn.id];
  }

  get selectedTurnaroundId() { return this.turnaroundsStore.selectedTurnaroundId; }

  get selectedTurnaround() { return this.turnaroundsStore.selectedTurnaround; }

  get turnarounds() { return this.turnaroundsStore.turnarounds; }

  get turnaroundsListLoaded() { return this.turnaroundsStore.loaded; }

  get canSelectNextTurn() { return this.turnaroundsStore.canSelectNextTurn; }

  get canSelectPrevTurn() { return this.turnaroundsStore.canSelectPrevTurn; }

  get averageIterationLength() { return this.turnaroundsStore.averageIterationLength; }

  get turnaroundsRanges() { return this.turnaroundsStore.turnaroundsRanges; }

  loadTurnaroundsAfter(id: string) { return this.turnaroundsStore.loadTurnaroundsAfter(id); }

  loadTurnaroundsBefore(id: string) { return this.turnaroundsStore.loadTurnaroundsBefore(id); }

  async selectTurnaround(id: string | null): Promise<string | null> {
    const {turnarounds} = this.turnaroundsStore;

    this.isTurnSelecting = true;

    if(!id) {
      await when(()=>this.turnaroundsStore.loaded);
      return this.finishTurnaroundSelection(!turnarounds.length || turnarounds[0].end ? null : turnarounds[0]);
    }

    let turn: Turnaround | null | undefined = this.turnaroundsStore.turnarounds.find(t=>t.id === id);
    if(!turn) {
      turn = await getTurnaround(this.standId,id);
      if(turn) {
        this.turnaroundsStore.addTurnarounds(turn.start,turn.end || Infinity,[turn]);
      }
    }
    if(!turn)
      turn = !turnarounds.length || this.turnaroundsStore.turnarounds[0].end ? null : this.turnaroundsStore.turnarounds[0];

    return this.finishTurnaroundSelection(turn);
  }

  private finishTurnaroundSelection(turn: Turnaround | null) {
    this.turnaroundsStore.setSelectedTurnaround(turn ? turn.id : null)
    this.displayedTimestamp = turn && turn.end ? turn.start : now();
    if(turn && !this.selectedTurnaroundIsLoaded) {
      this.loadTimeRange(turn.start, turn.end);
    }

    this.isTurnSelecting = false;

    const {sidebarStore} = this.root;
    if(turn && (sidebarStore.tab !== WATCHLIST_TAB || !sidebarStore.alerts.some(a=>a.turnaroundId === turn.id))) {
      const newTab = turn?.end ? SELECTED_TURN_TAB : CURRENT_GATE_TAB;
      sidebarStore.setTab(newTab);
    } else if(!turn) {
      sidebarStore.setTab(CURRENT_GATE_TAB);
    }

    return turn ? turn.id : null;
  }

  async selectPrevTurn() {
    const turn = await this.turnaroundsStore.getPrevTurn();
    return this.selectTurnaround(turn ? turn.id : null);
  }

  async selectNextTurn() {
    const turn = await this.turnaroundsStore.getNextTurn();
    return this.selectTurnaround(turn ? turn.id : null);
  }

  sync() {
    if (this.disposed)
      return;
    let turn = this.turnaroundsStore.turnarounds[0];
    let start = turn ? turn.start : now();
    let requestStartTs = Date.now();

    const turnId = this.turnaroundsStore.selectedTurnaroundId || undefined;

    timeline({standId: this.standId, startTs: start, turnId: turnId}).then(action((resp: any) => {
      this.timelineRequestFailCounter = 0;

      if (this.disposed)
        return;
      let {detections, inferenceTimestamp, absoluteTime, turnarounds, outages, lastImageTimestamp} = resp;

      if (!timeOffset && absoluteTime) {
        let localTime = Date.now();
        let requestDuration = localTime - requestStartTs;
        absoluteTime = absoluteTime + requestDuration / 2;
        setTimeOffset(absoluteTime - localTime);
      }

      const isLive = this.isLive;
      this.updateData(detections,turnarounds,outages,start,null);
      this.inferenceTimestamp = inferenceTimestamp;
      this.lastImageTimestamp = lastImageTimestamp;

      if(!this.isTurnSelecting) {
        const {selectedTurnaroundId,turnarounds} = this.turnaroundsStore;
        const firstTurn = turnarounds[0];
        if(isLive && firstTurn?.end && selectedTurnaroundId){
          this.selectTurnaround(null);
        } else if(isLive && !selectedTurnaroundId && firstTurn && !firstTurn.end){
          this.selectTurnaround(firstTurn.id);
        }
      }
    })).catch((e) => {
      this.timelineRequestFailCounter++;
      if (this.timelineRequestFailCounter > REQUEST_FAIL_COUNT_THRESHOLD) {
        // This sends error to Sentry
        throw e;
      }
    }).finally(()=>{
      this.timerId = window.setTimeout(
        () => this.sync(),
        this.timelineRequestFailCounter > REQUEST_FAIL_COUNT_THRESHOLD
          ? 60 * 1000
          : updateRate
      );
    });
  }

  loadTimeRange(start: number, end: number | null) {
    let requestStartTs = Date.now();

    const turnId = this.turnaroundsStore.selectedTurnaroundId || undefined;

    return timeline({standId: this.standId, startTs: start, endTs: end, turnId}).then((resp: any) => {
      let {detections, absoluteTime, turnarounds,outages} = resp;
      if(this.disposed)
        return;

      if (!timeOffset && absoluteTime) {
        let localTime = Date.now();
        let requestDuration = localTime - requestStartTs;
        absoluteTime = absoluteTime + requestDuration / 2;
        setTimeOffset(absoluteTime - localTime);
      }

      this.updateData(detections,turnarounds,outages,start,end);
    })
  }

  //TODO make separate methods
  //TODO try update fields and arrays without replacement
  updateData(detections: Detection[], turns: Turnaround[], outages: CameraOutage[], start: number, end: number | null ) {
    let newDetectionsIds = detections.map(e=>e.id);
    this.allDetections = [
      ...this.allDetections.filter(({start:ts,id})=>(ts<start || (end && ts > end)) && !newDetectionsIds.includes(id)),
      ...detections
    ];

    let newOutagesIds = outages.map(e=>e.id);
    this.outages = [
      ...this.outages.filter(({start:ts,id})=>(ts<start || (end && ts > end)) && !newOutagesIds.includes(id)),
      ...outages
    ];

    this.turnaroundsStore.addTurnarounds(start,end || Infinity,turns);

    turns.forEach(t=>{
      if(start <= t.start)
        this.fetchedTurnarounds[t.id] = true;
    })
  }

  openModal(timestamp:number,detection: Detection | null | undefined) {
    this.frameModalData = {timestamp,detection};
  }

  closeModal() {
    this.frameModalData = null;
  }

  setDisplayedTimestamp(ts: number) {
    this.displayedTimestamp = ts;
  }

  getVideoStartByCamera(camera: string): number {
    const turn = this.turnaroundsStore.selectedTurnaround;
    if(!turn)
      return 0;

    const video = turn.videos[camera];
    if (!video) {
      return 0;
    }
    
    return video.startTs;
  }

  getFrameUrl(camera: string, ts: number) {
    const token = this.root.imgToken;
    const turn = this.turnaroundsStore.selectedTurnaround;
    ts = Math.floor(ts / 1000);
    if(turn && turn.videos[camera]){
      ts = ts - Math.floor(this.getVideoStartByCamera(camera) / 1000);
      return `/replay_frame/${camera}/${turn.id}?at=${ts}&token=${token}`;
    }
    return `/images/${this.standId}/${camera}/${ts}.jpg?token=${token}`;
  }

  dispose() {
    this.disposed = true;
    if(this.root.standStore === this){
      this.root.setStandStore(null);
    }
    this.timelineRequestFailCounter = 0;

    if(this.timerId)
      clearTimeout(this.timerId);
  }
}