import React from 'react';
import './style.scss';
import { ReactComponent as LogoSvg } from './logo.svg';
import {PropsWithClass} from "../../models/common";

const Logo: React.FC<{sm?:boolean} & PropsWithClass> = ({sm,className})=> {
  return <LogoSvg viewBox={`0 0 ${sm ? 90 : 407} 84`} className={'logo ' + className}/>;
}

export default Logo;
