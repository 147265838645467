import axios, {AxiosInstance} from "axios";
import {requestTimeout} from "./config";
import {reportError} from "./logger";
import {rootStore} from "../stores/root";

const instance: AxiosInstance = axios.create({
  timeout: requestTimeout,
  transformRequest: [function (data, headers) {
    const header = rootStore.authStore.authHeader;
    if(headers) {
      // headers['Authorization'] = header;
      if(data){
        headers['Content-Type'] = 'application/json';
        data = JSON.stringify(data);
      }
    }
    return data;
  }],
});

function onError(er:any) {
  if(er.code === 418){
    //With no stands only exception will be displayed, unless header could be displayed
    rootStore.toggleServerUnavailable(false);
    rootStore.standsStore.resetStands();
    return Promise.reject(er);
  }

  rootStore.toggleServerUnavailable(true);
  reportError(er);
  return Promise.reject(er);
}

instance.interceptors.request.use(function (config) {
  return config;
}, onError);

instance.interceptors.response.use(function (response) {
  if(response.headers['content-type'] === "text/html"){
    const error = new Error("wrong resp format (html instead of json)");
    onError(error);
    throw error;
  }
  rootStore.toggleServerUnavailable(false);
  return response;
}, onError);

export default instance;