import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import classnames from "classnames";
import './style.scss';
import star from "./star.png";
import starActive from "./star-active.png";
import {RootContext} from "../../services/react";
import { observer } from "mobx-react";

type  Props = {
  onClose: () => void,
  onTourStart: () => void
}

const SupportForm: React.FC<Props> = ({onClose, onTourStart}) => {
  const formRef = useRef<HTMLDivElement>(null);
  const root = useContext(RootContext);
  const [score, setScore] = useState(0);
  const [showThanks, setShowThanks] = useState(false);
  const [hoveredScore, setHoveredScore] = useState(0);
  const [msg, setMsg] = useState("");
  const [sending, setSending] = useState(false);
  const {user} = root.authStore;

  const onDocumentClick = (e: MouseEvent) => {
    const { current } = formRef;
    if (!current)
      return;

    const { clientX, clientY } = e;
    const { left, right, top, bottom } = current.getBoundingClientRect();

    if (clientY < top || clientY > bottom || clientX < left || left > right) {
      onClose();
    }
  }

  useEffect(() => {
    document.addEventListener("click", onDocumentClick);
    return () => document.removeEventListener("click", onDocumentClick);
  }, [])

  const submit = () => {
    setSending(true);

    const data = {
      email: user?.profile.email,
      message: `Score: ${score};  Msg: ${msg}`
    };

    fetch('https://formspree.io/meqpopan', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then((res) => {
      if (!res.ok)
        throw new Error(res.statusText);
      setShowThanks(true);
    }).catch((er) => {
      alert("Failed.");
      throw er;
    }).finally(() => {
      setSending(false);
    });
  };

  const canSubmit = !sending && msg;
  const highlightedScore = hoveredScore || score;

  return (
    <div ref={formRef} className={'support-popup'}>
      {!showThanks && (
        <>
          <h2>Help us make this<br/> product better for you</h2>
          <label>rate product</label>
          <div className={'stars'}>
            {[1, 2, 3, 4, 5].map(v => (
              <a onClick={() => setScore(v)} onMouseEnter={() => setHoveredScore(v)}
                 onMouseLeave={() => setHoveredScore(0)}>
                <img src={v <= highlightedScore ? starActive : star}/>
              </a>
            ))}
          </div>

          <label>my ideas</label>
          <textarea
            autoFocus
            onInput={(ev) => setMsg((ev.target as HTMLTextAreaElement).value)}
          >
            {msg}
          </textarea>

          <a className={classnames('submit', {disabled: !canSubmit})}
             onClick={canSubmit ? submit : undefined}>submit</a>
        </>
      )}

      {showThanks && (
        <div className={'thanks'}>
          <i>🏆</i>
          <span>Thank you for your<br/>feedback.</span>
        </div>
      )}


      <a className={'how-to-btn'} href={'https://youtube.com/playlist?list=PLlT7mbQPvw_Zf5QAjMuspv6MBxQbRoHhh'} target={'_blank'}>
        💡️ Watch how-to videos →
      </a>

      <a className={'tour-btn'} onClick={() => onTourStart()}>
        ✈️ Product tour →
      </a>

      <label>customer support</label>

      <a className={'contact'} href={'mailto:support@assaia.com'}>support@assaia.com</a>
      <a className={'contact'} href={'tel:+442036080414'}>+44 20 36080414</a>
    </div>
  );
};

export default observer(SupportForm);