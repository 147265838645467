import {ApronAlertsStore} from "./alertsBase";
import {AlertsParams} from "../../models/api";
import {getAlerts} from "../../services/api";
import {action} from "mobx";
import {enableBrowserNotifications, updateRate} from "../../services/config";
import ApronAlert from "../../models/apronAlert";
import {last} from "lodash";

export class AllGatesAlertsRepository extends ApronAlertsStore {
  disposed = false;

  init() {
    console.log("init all gates");
    this.sync();
  }

  sync() {
    const {syncStart} = this;
    const params: AlertsParams = {};

    if (syncStart)
      params.until = syncStart;

    getAlerts(params).then(action(newAlerts => {
      if (this.disposed)
        return;
      this.addAlerts(newAlerts);
      this.ready = true;
    })).finally(() => {
      if (!this.disposed)
        window.setTimeout(() => this.sync(), updateRate);
    })
  }

  loadMore() {
    let oldestAlert: ApronAlert | undefined = last(this.sidebarStore.alerts);
    if (!oldestAlert)
      return;

    const params: AlertsParams = {
      before: oldestAlert.id
    }

    this.isLoadingMore = true;
    getAlerts(params).then(action(items => {
      if (this.disposed)
        return;
      if (items.length)
        this.addAlerts(items);
      else
        this.canLoadMore = false;
    })).finally(action(() => {
      this.isLoadingMore = false;
    }));
  }

  dispose() {
    super.dispose();
    console.log("dispose all gates");
  }
}