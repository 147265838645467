import React, {useEffect, useRef} from "react";

export function useRefObserver<T extends Element>(ref: React.RefObject<T>,cb: (el: T | null)=>void) {
  const prevRef = useRef(ref.current);

  useEffect(() => {
    if(ref.current !== prevRef.current) {
      prevRef.current = ref.current;
      cb(ref.current);
    }
  });
}
