import axios from "./axios";
import {
  filterStands,
  stands,
  standsLabels,
  timelineMiddlewares,
  turnaroundsRequestLimit,
  turnaroundsStartTimestamp,
} from "./config";
import {
  parseCameraOutage,
  parseDetections, parseTurnaround,
  parseTurnarounds,
} from "./apiParse";
import {toMilliSecondsOrNull} from "./time";
import _,{fromPairs} from 'lodash';
import * as Sentry from "@sentry/browser";
import Stand from "../models/stand";
import Turnaround from "../models/turnaround";
import {AxiosResponse} from "axios";
import ApronAlert, { ApronAlertConfig } from "../models/apronAlert";
import {camelCaseKeys, getStandLabel} from "./data";
import {AlertsParams} from "../models/api";
import {Flight, FutureFlight, OutboundFlight} from "../models/flightInfo";
import { sortBy } from "lodash";

export function getStands():Promise<Stand[]> {
  if(stands)
    return Promise.resolve(stands);

  return axios.get(`/api/stands/`).then((resp:AxiosResponse)=>{
    let stands = resp.data;

    stands = stands.map((stand:any)=>({
      id: stand.id,
      label: getStandLabel(stand.id),
      cameras: stand.camera_ids
    }));

    if(!filterStands)
      return stands;
    return stands.filter((s:Stand)=>!filterStands.includes(s.id));
  });
}

export function getStandTurnarounds(standId:string, params: any): Promise<Turnaround[]>{
  let url = `/api/stands/${standId}/turnarounds/`;
  if(!params)
    params.limit = turnaroundsRequestLimit;
  if(params.before)
    params.before = Math.floor(params.before/1000);
  if(params.after)
    params.after = Math.floor(params.after/1000);
  return axios.get(url,{params: params}).then((resp:AxiosResponse)=> {
    return parseTurnarounds(resp.data,standId);
  });
}

export function getActiveTurnarounds(lastRequestTs?: number): Promise<Turnaround[]>{
  const config: any = {
    params: {}
  };
  if(lastRequestTs)
    config.params.since = lastRequestTs/1000;

  return axios.get('/api/stands/turnarounds/',config).then((resp:AxiosResponse)=> {
    const res: Turnaround[] = [];
    resp.data.forEach((t:any)=>{
      if(filterStands && filterStands.includes(t.stand_id))
        return;
      res.push(parseTurnaround(t,t.stand_id))
    })
    return res;
  });
}

export function searchTurnarounds(stand: string | null | undefined,query: string,before?: number,limit = turnaroundsRequestLimit): Promise<Turnaround[]> {
  if(!query)
    return Promise.resolve([]);

  const config: any = {
    params: {
      limit,
    }
  };
  if(before)
    config.params.before = Math.round(before/1000);
  if(stand)
    config.params.stand_id = stand;
  if(query)
    config.params.q = query;

  return axios.get('/api/stands/search',config).then((resp:AxiosResponse)=> {
    const res: Turnaround[] = [];
    resp.data.forEach((t:any)=>{
      if(filterStands && filterStands.includes(t.stand_id))
        return;
      res.push(parseTurnaround(t,t.stand_id))
    })
    return res;
  });
}

export function getTurnaround(standId:string,turnId:string): Promise<Turnaround | null> {
  let url = `/api/stands/${standId}/turnarounds/${turnId}`;
  return axios.get(url).then((resp:AxiosResponse)=> {
    if(resp.data.turnaround)
      return parseTurnaround(resp.data.turnaround,standId);
    return null;
  });
}

export async function timeline(
  params: {
    standId:string,
    startTs:number,
    endTs?:number | null,
    turnId?: string,
  }
) {
  let {standId, startTs, endTs, turnId} = params;
  startTs = startTs / 1000;
  let url = `/api/stands/${standId}/timeline?start_ts=${startTs}`;
  if (endTs) {
    endTs = endTs / 1000;
    url += `&end_ts=${endTs}`;
  }

  if (turnId) {
    url += `&turn_id=${turnId}`;
  }

  return axios.get(url).then((resp:AxiosResponse)=> {
    let {turnarounds,detections,camera_outages,last_prediction_ts,last_image_ts,current_ts} = resp.data;

    turnarounds = parseTurnarounds(turnarounds,standId);

    Object.entries(last_prediction_ts).forEach(([cam,ts])=>{
      if(!last_image_ts[cam])
        last_image_ts[cam] = ts;
    });
    last_prediction_ts = fromPairs(Object.entries(last_prediction_ts).map(([key,val])=>[key,toMilliSecondsOrNull(val as string)]));
    last_image_ts = fromPairs(Object.entries(last_image_ts).map(([key,val])=>[key,toMilliSecondsOrNull(val as string)]));

    detections = parseDetections(detections);

    camera_outages = camera_outages.map(parseCameraOutage);

    timelineMiddlewares.forEach(middleware=>{
      try {
        [detections,turnarounds] = middleware(detections,turnarounds,last_prediction_ts,_);
      }catch(er){
        console.error(er);
      }
    })

    let res = {
      inferenceTimestamp: last_prediction_ts,
      lastImageTimestamp: last_image_ts,
      absoluteTime:toMilliSecondsOrNull(current_ts),
      turnarounds,
      detections,
      outages: camera_outages
    };
    return res;
  });
}

export function getImgToken(): Promise<string> {
  let url = '/api/account/token';
  return axios.get(url).then((resp:AxiosResponse)=> resp.data.token);
}

export function getFlights(to?: number): Promise<FutureFlight[]> {
  let url = '/api/flights/';

  const params:any = {}
  if(to)
    params.to = to;

  return axios.get(url,{params}).then((resp:AxiosResponse)=> {
    const items = resp.data.map((f:any)=>({
      standId: f.stand_id,
      flightNumber: f.flight_number,
      scheduledOffBlockTime: f.scheduled_off_block_utc*1000,
    }))
    return sortBy(items,'scheduledOffBlockTime')
  });
}


export function getAlerts(params:AlertsParams = {}): Promise<ApronAlert[]> {
  let url = "/api/stands/incidents";

  let startTs = 0;
  const { stand_id: standId } = params;
  if (typeof turnaroundsStartTimestamp === "object") {
    if (standId) {
      startTs = turnaroundsStartTimestamp[standId];
    }
  } else {
    startTs = turnaroundsStartTimestamp;
  }

  return axios.get(url, { params }).then((resp: AxiosResponse) => {
    let alerts: ApronAlert[] = resp.data
      .map((item: any) => {
        const parseTriggeredEvents = () => {
          if (item.data.triggered_events) {
            return item.data.triggered_events.map((v: any) => camelCaseKeys(v));
          }

          const {event_name, object_name} = item.config.data;
          if (event_name && object_name) {
            return [(camelCaseKeys({event_name, object_name}))];
          }

          return [];
        }

        const triggeredEvents = parseTriggeredEvents();

        item = camelCaseKeys(item);
        item.ts *= 1000;
        item.alertType = item.incidentType;
        item.data.triggeredEvents = triggeredEvents;
        item.fake = false;

        if (!item.config) item.config = { severity: "" };

        return item;
      });
    if(startTs)
      alerts = alerts.filter(({ ts }: any) => ts >= startTs);
    if(filterStands)
      alerts = alerts.filter(a=>!filterStands.includes(a.standId))

    return alerts;
  });
}

export async function getAlertsConfigList(): Promise<ApronAlertConfig[]> {
  let url = "/api/incident_configs/";
  return axios.get(url).then((resp: AxiosResponse) => resp.data);
}

export async function makeExport(
  standId:string,
  startTs:number,
  endTs:number,
) {
  startTs = startTs / 1000;
  endTs = endTs / 1000;

  let url = `/api/stands/${standId}/export?start_ts=${startTs}&end_ts=${endTs}`;

  return axios.get(url,{timeout:60*5*1000}).then((resp:AxiosResponse)=> {
    let {turnarounds,detections} = resp.data;
    turnarounds = parseTurnarounds(turnarounds, standId);
    detections = parseDetections(detections);

    timelineMiddlewares.forEach(middleware=>{
      try {
        [detections,turnarounds] = middleware(detections,turnarounds,0,_);
      }catch(er){
        console.error(er);
      }
    })

    return [detections,turnarounds];
  });
}