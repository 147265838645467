import {action, makeAutoObservable, reaction} from "mobx";
import {RootStore} from "./root";
import {getActiveTurnarounds, getStands} from "../services/api";
import {updateRate} from "../services/config";
import Stand from "../models/stand";
import Turnaround from "../models/turnaround";
import { keyBy } from "lodash";
import {standsDetails} from "../constants/stands";

export default class StandsStore {
  stands: Stand[] = [];
  standsLoaded = false;

  lastSyncTs?: number;
  activeTurnarounds: {[k:string]: Turnaround} = {};
  activeTurnaroundsLoaded = false;

  constructor(private root: RootStore) {
    makeAutoObservable(this);

    reaction(()=>root.authStore.isSigned,(signed, signedBefore)=>{
      if(signed && !signedBefore){
        this.loadStands();
        this.loadActiveTurnarounds();
      }
    })
  }

  get selectedStand() {
    return this.root.standStore?.stand;
  }

  get standsMap() {
    return keyBy(this.stands,'id');
  }

  get ids() {
    return this.stands.map(s=>s.id);
  }

  get sortedStands() {
    const collator = new Intl.Collator(undefined, {numeric: true, sensitivity: 'base'});
    return this.root.standsStore.stands.slice().sort((s1,s2)=>collator.compare(s1.id,s2.id));
  }

  get activeTurnaroundsForMap() {
    const turns = Object.values(this.activeTurnarounds);
    return keyBy(turns,t=>t.originalStandId && standsDetails[t.originalStandId] ? t.originalStandId : t.standId);
  }

  loadStands() {
    getStands().then(
      action((stands: Stand[]) => {
        this.stands = stands;
        this.standsLoaded = true;
      }),() => {
        setTimeout(() => this.loadStands(), updateRate);
      });
  }

  resetStands() {
    this.stands = [];
  }

  loadActiveTurnarounds() {
    getActiveTurnarounds(this.lastSyncTs).then(action(turnarounds=>{
      if(!this.root.authStore.isSigned)
        return;

      turnarounds.forEach(turn=>{
        if (turn.end) {
          //Prev finished turn could be updated after next turn started. dict[selectedStandId] and turn are different turns
          if (this.activeTurnarounds[turn.standId]?.id === turn.id)
            delete this.activeTurnarounds[turn.standId];
        } else {
          this.activeTurnarounds[turn.standId] = turn;
        }
      })

      this.activeTurnaroundsLoaded = true;
    })).finally(()=>{
      setTimeout(()=>this.loadActiveTurnarounds(),updateRate*5);
    });
    this.lastSyncTs = Date.now();
  }
}