import React, {useContext} from 'react';
import './style.scss';
import Logo from "../Logo";
import {COPYRIGHT} from "../../constants/strings";
import {PropsWithClass} from "../../models/common";
import cn from "bem-cn";
import {observer} from "mobx-react";

const b = cn('sidebar-footer');

const SidebarFooter: React.FC<PropsWithClass> = ({className})=> {
  return (
    <div className={b.mix(className)}>
      <Logo className={b('logo')}/>
      <span className={b('copyright')}>{COPYRIGHT}</span>
    </div>
  )
}

export default observer(SidebarFooter);