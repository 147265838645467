import React, {useContext} from "react";
import moment from "moment";
import "moment-timezone";

import {getTimeFormatTemplate} from "../../services/time";

import {observer} from "mobx-react";
import {RootContext} from "../../services/react";

type Props = {
  time: number
  date?:boolean,
  seconds?: boolean,
  format?: string
}

const TimeFormatter: React.FC<Props> = ({time,date=true,seconds=true,format}) =>{
  const {timezone} = useContext(RootContext);
  if(!format)
    format = getTimeFormatTemplate(date,seconds);
  return <>{moment(time).tz(timezone).format(format)}</>;
};

export default observer(TimeFormatter);