import React, {CSSProperties, useCallback, useContext, useEffect, useRef, useState} from "react";
import { observer } from "mobx-react";

import VideoPlayer, {defaultState, PlayerState} from "../VideoPlayer";
import Dropdown,{DropdownItem} from "../Dropdown";
import {disableVideoStreamBBox} from "../../services/config";
import BBox from "../BBox";
import Frame from "../Frame";
import Spinner from "../Spinner";
import TimeFormatter from "../TimeFormatter";
import {getBBoxByCamera} from "../../services/data";
import "./style.scss"
import styles from "./style.module.scss";
import cn from "bem-cn";
import {StandContext} from "../../services/react";
import { tourStepIdList } from "../Tour/steps";
import CamerasToolbar from "../CamerasToolbar";

type Props = React.HTMLProps<HTMLDivElement>

const b = cn('turnaround-video');

const TurnaroundVideo:React.FC<Props> = ({className, style })=> {
  const standContext = useContext(StandContext);
  const {cameras,events,colors,displayedTimestamp,root: {imgToken},getFrameUrl} = standContext;
  const turnaround = standContext.selectedTurnaround!;

  const player = useRef<VideoPlayer>(null);
  const progressBar = useRef<HTMLInputElement>(null);
  const lastCommittedUTC = useRef<number | null>(null);


  const [camera, setCamera] = useState(cameras.find(c=>turnaround!.videos[c]) || cameras[0]);
  const [playerState,setPlayerState] = useState<PlayerState>({...defaultState});
  const videoInProgress = !turnaround!.videos[camera];
  const video = turnaround!.videos[camera];
  const videoStart = standContext.getVideoStartByCamera(camera);
  const [playerDefaultStart,setPlayerDefaultStart] = useState(video ? (turnaround!.start - videoStart)/(video.speed*1000) : 0);
  const [playerDefaultSpeed,setPlayerDefaultSpeed] = useState<number | undefined>();

  useEffect(()=>{
    if(video && lastCommittedUTC.current && lastCommittedUTC.current !== displayedTimestamp) {
      const ts = (displayedTimestamp - videoStart)/(video.speed*1000);
      player.current?.navigate(ts)
    }
  },[displayedTimestamp])

  const onProgressClick = (ev: React.MouseEvent) => {
    if(!video || !progressBar.current)
      return;
    const rect = progressBar.current.getBoundingClientRect();
    let k = (ev.clientX - rect.left)/(rect.width);

    const turnLength = (turnaround.end as number)- videoStart
    const utc = videoStart + k*turnLength;
    standContext.setDisplayedTimestamp(utc);
    lastCommittedUTC.current = utc;

    const playerTime = (turnLength/(video.speed*1000))*k;
    player.current?.navigate(playerTime);
  };

  const onPlayerStateChanged = useCallback((state: PlayerState) => {
    setPlayerDefaultSpeed(state.speed);
    setPlayerState(state);
    setPlayerDefaultStart(state.ts);
    if(video && state.ts !== playerState.ts){
      const utc = videoStart + state.ts*1000*video.speed;
      standContext.setDisplayedTimestamp(utc);
      lastCommittedUTC.current = utc;
    }
  },[videoStart,video?.speed,setPlayerState]);

  const getBBox = () => {
    if(disableVideoStreamBBox || !displayedTimestamp)
      return null;

    let displayPeriod = playerState.speed ? 7000 * playerState.speed : 5000;
    let nearEvents = events.filter(e=>e.timestamp <= displayedTimestamp && displayedTimestamp - e.timestamp < displayPeriod);
    nearEvents = nearEvents.filter(e=>Object.keys(e.detection.bboxes).includes(camera));
    let boxes = nearEvents.map(event=>{
      const bbox = getBBoxByCamera(event.detection.bboxes, camera);
      return {
        label: event.label,
        bbox,
        color: colors[event.detection.type],
        id: event.id
      }
    });
    return boxes.map(bbox=>(
      // @ts-ignore
      <BBox {...bbox} key={bbox.id}/>
    ));
  };

  const getPreviewUrl = useCallback((ts:number)=>{
    if(!video)
      return "";
    ts = videoStart + ts*video.speed*1000;
    return getFrameUrl(camera,ts);
  },[camera,video?.speed,turnaround])

  const camerasToolbar = (
    <CamerasToolbar
      cameraData={cameras.map((c) => [c, () => setCamera(c)])}
      selectedCamera={camera}
    />
  );

  if(videoInProgress) {
    return (
      <div id={tourStepIdList.standVideoStream} className={b.mix(className)}>
        <Frame onFail={()=>{}} camera={camera} timestamp={turnaround.start} className={b('frame-plug').toString()} key={camera}>
          <div className={b('processing-alert')}>The replay video will be available after processing is complete</div>
        </Frame>
        <div className={b('toolbar')}>
          {camerasToolbar}
        </div>
      </div>
    )
  }

  const progress = (displayedTimestamp - videoStart)/((turnaround.end || videoStart) - videoStart);
  
  return (
    <div
      id={tourStepIdList.standVideoStream}
      className={b.mix(className)}
      style={style}
    >
      <VideoPlayer
        src={video!.url + `?token=${imgToken}`}
        key={camera}
        ref={player}
        onStateChanged={onPlayerStateChanged}
        getPreviewUrl={getPreviewUrl}
        defaultTs={playerDefaultStart}
        className={b("player")}
        defaultSpeed={playerDefaultSpeed}
      >
        {getBBox()}
      </VideoPlayer>

      <div className={b("toolbar")}>
        <div
          className={b("progress")}
          onClick={(ev) => onProgressClick(ev)}
          ref={progressBar}
        >
          <div style={{ width: progress * 100 + "%" }} />
        </div>

        {camerasToolbar}

        <Dropdown
          title={playerState.speed * video!.speed + "X"}
          align={"left"}
          className={b("speed-dropdown").toString()}
        >
          {playerState.speeds.map((s) => (
            <DropdownItem
              active={playerState.speed === s}
              key={s}
              onSelect={() => player.current && player.current.setSpeed(s)}
            >
              {s * video!.speed}
            </DropdownItem>
          ))}
        </Dropdown>

        <div className={b("time")}>
          <TimeFormatter time={displayedTimestamp} date={false} />
        </div>

        {playerState.videoReady && (
          <a
            className={b("play-btn")}
            onClick={() => player.current && player.current.play()}
          >
            {playerState.playing ? (
              <i className="fas fa-pause" />
            ) : (
              <i className="fas fa-play" />
            )}
          </a>
        )}
        {!playerState.videoReady && (
          <span className={b("play-btn")}>
            <Spinner />
          </span>
        )}
      </div>
    </div>
  );
}

export default observer(TurnaroundVideo);