import React, {useContext, useEffect, useState} from 'react';
import {PropsWithClass} from "../../models/common";
import styles from "./style.module.scss";
import { observer } from 'mobx-react';

import {RootContext} from "../../services/react";
import IncidentWatchlistModal from "../WatchListModal";
import {disableWatchlist} from "../../services/config";
import classNames from 'classnames';

const SidebarHeader: React.FC<PropsWithClass> = ({className})=> {
  const rootStore = useContext(RootContext);
  const [showSettingsModal,setShowSettingsModal] = useState(false);

  return (
    <div className={classNames(styles.sidebarHeader, className)}>
      <i className={classNames("fas fa-bell", styles.bell)}/>
      <div className={styles.title}>Notifications</div>
      {!disableWatchlist && (
        <a onClick={()=>setShowSettingsModal(true)} className={styles.settingsBtn}>
          <i className={'fas fa-cog'}/>
        </a>
      )}
      <a onClick={() => rootStore.sidebarStore.setVisibility(false)} className={styles.settingsBtn}>
        <i className={'far fa-chevron-double-left'}/>
      </a>
      {showSettingsModal && <IncidentWatchlistModal onClose={()=>setShowSettingsModal(false)}/>}
    </div>
  )
};

export default observer(SidebarHeader);