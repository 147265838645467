import React, {Component} from "react";
import classnames from 'classnames'

import {formatTimeDelayShort, now} from "../../services/time";

import "./style.scss";
import {loadImageAjax} from "../../services/images";
import FrameUnavailable from "../FrameUnavailable";
import TimeFormatter from "../TimeFormatter";
import {getCameraLabel} from "../../services/data";

import {observer} from "mobx-react";
import {StandContext} from "../../services/react";
import StandStore from "../../stores/stand";
import {IReactionDisposer} from "mobx";
import { tourStepIdList } from "../Tour/steps";
import Frame from "../Frame";
import CamerasToolbar from "../CamerasToolbar";

const HOURS_18 = 18*60*60*1000;

type Props = React.HTMLProps<HTMLDivElement>

type StreamState = {
  camera: string,
  restricted: boolean,
  loadedTs: number | null,
  lastRequestedTs?: number
}

class StandVideoStream extends Component<Props,StreamState> {
  static contextType = StandContext;
  context!: StandStore

  readonly state: StreamState;
  private mounted = true;
  private intervalId?: NodeJS.Timeout;
  private reactionDisposer?: IReactionDisposer

  constructor(props: Props,ctx: StandStore) {
    super(props,ctx);

    this.state = {
      loadedTs: null,
      camera: ctx.cameras[0],
      restricted: false
    };
  }

  componentDidMount() {
    this.intervalId = setInterval(()=>this.forceUpdate(),1000);
  }

  componentWillUnmount() {
    this.mounted = false;
    if(this.reactionDisposer)
      this.reactionDisposer();
    if(this.intervalId)
      clearInterval(this.intervalId);
  }

  onError = (er: any) => {
    if(er.status === 403 || er.code === 403){
      this.setState({restricted: true})
    }
  }

  onSuccess = (ts:number) => {
    this.setState({
      loadedTs: ts,
      restricted: false
    })
  }

  render() {
    const { camera,loadedTs, restricted } = this.state;
    const { cameras } = this.context;
    const {style, className } = this.props;
    const {lastImageTimestamp} = this.context;

    if (restricted)
      return (
        <FrameUnavailable className={className} msg={"Forbidden"} />
      );

    return (
      <div
        id={tourStepIdList.standVideoStream}
        style={style}
        className={"stand-video-stream " + className}
      >
        <Frame
          className={'frame'}
          camera={camera}
          disableSpinner={true}
          timestamp={lastImageTimestamp ? lastImageTimestamp[camera] : now()}
          onFail={this.onError}
          onSuccess={this.onSuccess}
          key={camera}
        />

        <div className={"toolbar"}>
          <CamerasToolbar
            cameraData={cameras.map((c) => [
              c,
              () =>
                this.setState({
                  camera: c,
                  loadedTs: null,
                }),
            ])}
            selectedCamera={camera}
          />
          {loadedTs && (
            <div className={"time"}>
              <TimeFormatter
                time={loadedTs}
                date={now() - loadedTs > HOURS_18}
              />
              ({formatTimeDelayShort(loadedTs, now())})
            </div>
          )}
          {loadedTs && now() - loadedTs < 60000 && (
            <div className={"live-indicator"}>
              <i className="fas fa-circle" />
              LIVE
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default observer(StandVideoStream);