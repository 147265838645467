import Oidc from "oidc-client";
import { rootStore } from "../stores/root";

import {openID} from "./config";

const config = {
  redirect_uri: window.location.origin + "/auth/callback",
  response_type: "code",
  scope: "openid",
  post_logout_redirect_uri: window.location.origin,
  automaticSilentRenew: true,
  revokeAccessTokenOnSignout: true,
  ...openID
};

const oidcUserManager = new Oidc.UserManager(config);

oidcUserManager.events.addSilentRenewError(()=>{
  window.location.reload();
})

const getDemoUser = () => ({
  expired: false,
  profile: {
    email: "demo@assaia.com",
    profile: {
      full_name: "Demo"
    }
  }
} )

const fakeUserManager = {
  signinCallback: async () => {
    return getDemoUser()
  },
  events: {
    addEventListener: () => {},
    addUserLoaded: () => {}
  },
  user: getDemoUser(),
  signinRedirect: async () => {
    rootStore.authStore.init(getDemoUser() as any);
  },
  addUserLoaded: () => {},
}

const userManager = fakeUserManager as any as Oidc.UserManager;

Oidc.Log.logger = console;

export default userManager;