import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { observer } from "mobx-react";

import styles from "./style.module.scss";
import { useResizeObserver } from "../../hooks/useResizeObserver";

type State = {
  showFirstShadow: boolean;
  showSecondShadow: boolean;
  scrollValue: number;
};

type Props = {
  // TODO: Implement vertical shadows if needed
  type: "vertical" | "horizontal";
  className?: string;
};

const calcHorizontal = (wrapper: HTMLDivElement): State => {
  const { scrollWidth, scrollLeft, clientWidth } = wrapper;

  const leftShadowVisible = Math.abs(scrollLeft) > 1;
  const rightShadowVisible =
    Math.abs(Math.round(scrollLeft)) < scrollWidth - clientWidth - 1;

  return {
    showFirstShadow: leftShadowVisible,
    showSecondShadow: rightShadowVisible,
    scrollValue: scrollLeft,
  };
};

const ScrollShadows: React.FC<Props> = ({ className, type, children }) => {
  const [state, setState] = useState<State>({
    showFirstShadow: false,
    showSecondShadow: false,
    scrollValue: 0,
  });
  const ref = useRef<HTMLDivElement>(null);

  const [observeResize] = useResizeObserver({
    onResize: (node) => setState(calcHorizontal(node)),
  });

  useEffect(() => {
    observeResize(ref.current);
  }, []);

  const onScroll = () => {
    if (ref.current) {
      setState(calcHorizontal(ref.current));
    }
  };

  const getStyle = (visible: boolean) =>
    ({
      visibility: visible ? "visible" : "hidden",
      transform: `translateX(${state.scrollValue}px)`,
    } as React.CSSProperties);

  return (
    <section ref={ref} className={className} onScroll={onScroll}>
      {children}
      <div
        className={classNames(styles.firstShadow, styles[type])}
        style={getStyle(state.showFirstShadow)}
      />
      <div
        className={classNames(styles.secondShadow, styles[type])}
        style={getStyle(state.showSecondShadow)}
      />
    </section>
  );
};

export default observer(ScrollShadows);
