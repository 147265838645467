import {orderBy} from 'lodash';
import moment from "moment";
import "moment-timezone";
import {formatTimeForFilename, getTimeFormatTemplate, now} from "../../services/time";
import exportFile from "../../services/exportFile";
import Detection from "../../models/detection";
import Turnaround from "../../models/turnaround";
import {makeExport} from "../../services/api";

type Row = [string,Detection,Turnaround?]
type Column = [string,(r:Row)=>string]

const columns:Column[]  = [
  ['Stand ID',([standId]:Row)=>standId],
  ['Type',([id,e]:Row)=>e.label || e.type],
  ['Start',([id,e]:Row)=>moment(e.start).utc().format(getTimeFormatTemplate())],
  ['End',([id,e]:Row)=>e.end ? moment(e.end).utc().format(getTimeFormatTemplate()):""],
  ['Url',([id,e,t]:Row)=>t ? window.location.origin + '/' + id + '/' + t.id : ""]
];

export default async (ids: string[], start: number, end:number) => {
  const chunks: [number,number,string][] = [];

  let current = start;
  do{
    const chunk:[number,number] = [current,Math.min(end,current + 3600*24*1000)];
    ids.forEach(id=>chunks.push([...chunk,id]));
    if(chunk[1] >= end)
      break;
    current = chunk[1];
  }while (true);

  const result: {[key:string]:[Detection[],Turnaround[]]} = {};
  ids.forEach(id=>result[id] = [[],[]]);

  while (chunks.length) {
    console.log(chunks.length);
    const promises = [];
    for(let i=0;i<Math.min(chunks.length,3);i++) {
      let chunk = chunks.pop();
      if(!chunk)
        break;
      let id = chunk[2];
      promises.push(makeExport(id,chunk[0],chunk[1]).then(res=>[id,...res]));
    }

    let tmp = await Promise.all(promises);
    tmp.forEach(row=>{
      let id = row[0]
      result[id][0].push(...row[1])
      result[id][1].push(...row[2])
    });
  }

  const lines = [
    columns.map(c=>c[0]).join(';')
  ];
  ids.forEach(id=> {
    let [detections,turns] = result[id];

    let rows: Row[] = [];

    detections.forEach(d=>{
      let turn = turns.find(t=>t.start<=(d.end || now()) && (!t.end || t.end >= d.start));
      rows.push([id,d,turn]);
    })

    rows = orderBy(rows,[(r:Row)=>r[1].start],['desc']);
    rows.forEach(row=>{
      let line = columns.map(([name,getValue])=>getValue(row)).join(';');
      lines.push(line);
    })
  })

  let text = lines.join('\n');
  let filename = ids.length > 1 ? 'export_' : ids[0] + '_';
  filename += `${formatTimeForFilename(start,"Etc/UTC")}-${formatTimeForFilename(end,"Etc/UTC")}.csv`;
  exportFile(filename, text);
}
