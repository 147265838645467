import React from 'react';

export const ERROR_MESSAGE = <>Assaia ApronAI is unavailable.<br/>Please try later...</>;

export const TIME_ZONE_KEY = 'TIME_ZONE';

export const EMPTY_APRON_LABEL = 'No active turnaround';

export const UNAUTHORIZED_TURN_SORRY = 'Sorry, this turnaround is not for you';

export const CAMERAS_UNAVAILABLE_MSG= "ONE OR MORE CAMERAS ON THIS GATE ARE CURRENTLY UNAVAILABLE, SOME EVENTS AND ALERTS FOR THIS GATE MIGHT THEREFORE BE INACTIVE";

export const NOT_AVAILABLE = 'N/A';

export const OTHERS_GROUP = 'Other';

export const SIDEBAR_VISIBLE_STORAGE_KEY = 'sidebar_collapsed';

export const SIDEBAR_WIDTH_STORAGE_KEY = 'SIDEBAR_WIDTH';

export const SIDEBAR_VISIBILITY_STORAGE_KEY = 'SIDEBAR_VISIBILITY';

export const COPYRIGHT = `© ${new Date().getFullYear()} Assaia International AG`

export const MODAL_TS_QUERY_KEY = "ts";
export const MODAL_DETECTION_ID_QUERY_KEY = "detection_id";

export const TURNAROUND_STATUS_GREEN = '#239052';

export const TURNAROUND_STATUS_ORANGE = '#D57E0C';

export const TURNAROUND_STATUS_RED = '#BD4E2B';

export const TURNAROUND_STATUS_GREY = '#445059';

export const AIRCRAFT_ON_STAND = 'aircraft_on_stand';

export const NOT_COVERED_GATE_ALERT_TYPE = "not_covered_gate";

export const CURRENT_GATE_TAB = 'current gate'

export const ALL_GATES_TAB = 'all gates'

export const WATCHLIST_TAB = 'watchlist'

export const SELECTED_TURN_TAB = 'selected turnaround'

export const MAP_POSITION_STORAGE_KEY = 'MAP_POSITION';

export const MARKED_ALERTS_STORAGE_KEY = 'MARKED_ALERTS';