import 'core-js';
import 'react-app-polyfill/ie11';
import * as Sentry from "@sentry/browser";
import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";
import { APRON_ENV, SENTRY_DSN } from "./services/config";
import { report } from "./services/logger";
import "./services/debugging";
import "./styles/index.scss";
import lodash from 'lodash';
import './models/global'
import {rootStore} from './stores/root';
import {RootContext} from "./services/react";
import { unregister } from './serviceWorkerRegistration';

window.lodash = lodash;

// This unregister Service Worker if it was registered before
unregister();

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [],
    environment: APRON_ENV
  });
  report("Application start.");
}

ReactDOM.render(
  <RootContext.Provider value={rootStore}>
    <App/>
  </RootContext.Provider>,
  document.getElementById("root")
);