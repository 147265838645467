import React, {useContext, useMemo, useRef, useState} from "react";
import {useHistory} from "react-router-dom";
import cn from "bem-cn";
import {observer} from "mobx-react";

import GatePopup from "../TurnaroundMapPopup";
import {RootContext} from "../../services/react";
import Turnaround from "../../models/turnaround";
import './style.scss'
import {standsDetails} from "../../constants/stands";
import { GateContext, GateContextValue } from "./GateContext";
import { StandLabel } from "./StandLabel";
import { PobtLabel } from "./PobtLabel";
import { ProgressRect } from "./ProgressRect";
import { ProgressCircle } from "./ProgressCircle";
import { PlaneIcon } from "./PlaneIcon";

export const defaultGateSize: [number,number] = [48,72];
export const textHeight = 16;

type GateProps = {
  standId: string,
}

const b = cn('gate-pic');

const Gate: React.FC<GateProps> = ({standId}) => {
  const rootStore = useContext(RootContext);
  const activeTurns = rootStore.standsStore.activeTurnaroundsForMap;
  const turnaround = activeTurns[standId] as Turnaround | undefined;
  const [showPopup,setShowPopup] = useState(false);
  const [clickCoords,setClickCoords] = useState<[number, number] | null>(null);
  const history = useHistory();
  const ref = useRef<SVGRectElement>(null);
  const {mapConfig: {angle,size = defaultGateSize,center,parent}} = standsDetails[standId];
  const childGates = useMemo(()=>
    Object.entries(standsDetails).filter(p=>p[1].mapConfig.parent === standId).map(p=>p[0]),
  [standId,standsDetails]);

  const hasChildren = childGates.length > 0;
  const activeChildrenTurns = childGates
    .filter((g) => activeTurns[g])
    .map((id) => activeTurns[id]);

  const disabled =
    (turnaround && !turnaround.authorized) ||
    (activeChildrenTurns.length > 0 && activeChildrenTurns.every((t) => !t.authorized)) ||
    (!turnaround && !rootStore.standsStore.ids.includes(standId)) ||
    (!turnaround && childGates.length > 0 && activeChildrenTurns.length === 0);

  const contextValue = useMemo<GateContextValue>(()=>({
    standId,
    hasChildren,
    titleHeight: 16,
    angle,
    size,
    center,
    turnaround,
    parent,
    disabled,
    planeOffsetY: size[1] === defaultGateSize[1] ? 0 : (size[1]- defaultGateSize[1])/2
  }),[turnaround]);

  let cellTransform = `translate(${center[0]} ${center[1]}) rotate(${angle})`;

  //hide overlay if parent gate or child gate has turn
  if(!turnaround && parent && activeTurns[parent]) {
    return null;
  }

  const handleClickEnd = (clientX: number, clientY: number) => {
    if (!clickCoords) {
      return;
    }

    const clickThreshold = 2;
    const [originX, originY] = clickCoords;

    if (
      Math.abs(clientX - originX) < clickThreshold &&
      Math.abs(clientY - originY) < clickThreshold
    ) {
      history.push(
        turnaround ? `/${turnaround.standId}/${turnaround.id}` : "/" + standId
      );
    }

    setClickCoords(null);
  }

  const onMouseUp = (e: React.MouseEvent) => {
    if (disabled)
      return;

    const {clientX, clientY} = e;
    handleClickEnd(clientX, clientY);
  }

  const onMouseDown = (e: React.MouseEvent) => {
    if (disabled)
      return;

    setClickCoords([e.clientX, e.clientY]);
  }

  const onTouchStart = (e: React.TouchEvent) => {
    if (disabled || !e.targetTouches.length)
      return;

    const {clientX, clientY} = e.targetTouches[0];

    setClickCoords([clientX, clientY]);
  }

  const onTouchEnd = (e: React.TouchEvent) => {
    if (disabled || !e.targetTouches.length)
      return;

    const {clientX, clientY} = e.targetTouches[0];
    handleClickEnd(clientX, clientY);
  }

  return (
    <GateContext.Provider value={contextValue}>
      <g
        transform={cellTransform}
        data-stand={standId}
      >
        <rect
          className={b('background',{parent: hasChildren})}
          x={0} y={0}
          width={size[0]} height={size[1]}
          transform={`translate(-${size[0]/2} -${size[1]/2})`}
          rx={2}
          ry={2}
        />

        <StandLabel />

        {turnaround && !childGates.some(g=>activeTurns[g]) && (
          <>
            <ProgressRect/>
            <PlaneIcon/>
            <PobtLabel/>
            <ProgressCircle/>
          </>
        )}

        <rect
          className={b('overlay',{dark: disabled})}
          x={0} y={0}
          rx={2}
          ry={2}
          width={size[0] + 1} height={size[1] + 1}
          transform={`translate(-${(size[0] + 1) /2} -${(size[1] + 1)/2})`}
          onMouseOver={()=>setShowPopup(true)}
          onMouseLeave={()=>setShowPopup(false)}
          onMouseUp={onMouseUp}
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          ref={ref}
        />
      </g>
      {!disabled && showPopup && turnaround && ref.current && (
        <GatePopup turnaround={turnaround} target={ref.current}/>
      )}
    </GateContext.Provider>
  )
}

export default observer(Gate);
