import React, { useContext, useState } from "react";
import { observer } from 'mobx-react';
import styles from "./style.module.scss";
import classNames from "classnames";
import { RootContext } from "../../services/react";
import ApronAlert from "../../models/apronAlert";
import { ReactComponent as CircleCheckSvg } from "../../svg/circle-check.svg";

export const CURRENT_TURNS_LABEL = "current notifications";
export const PREVIOUS_TURN_LABEL = "NOTIFICATION ARCHIVE";

type Label = typeof CURRENT_TURNS_LABEL | typeof PREVIOUS_TURN_LABEL;

export const CollapsibleSection: React.FC<{
  label: Label | React.ReactNode;
  count: number;
  collapsed: boolean;
  activeAlerts?: ApronAlert[];
  onClick: (e: React.MouseEvent) => void;
}> = observer(({ label, count, children, activeAlerts, collapsed, onClick }) => {
  const rootStore = useContext(RootContext);
  const [markAllHovered, setMarkAllHovered] = useState(false);

  const markAllAsDone = (e: React.MouseEvent) => {
    if (activeAlerts) {
      e.stopPropagation();
      activeAlerts.forEach((a) => rootStore.sidebarStore.markAlertAsRead(a));
    }
  }

  return (
    <>
      <div
        className={classNames(
          styles.groupHeader,
          !markAllHovered && styles.hoverable
        )}
        onClick={onClick}
      >
        {label}
        <span
          className={classNames(styles.groupBadge, {
            [styles.current]: label === CURRENT_TURNS_LABEL
          })}
        >
          {count}
        </span>

        <span
          className={classNames(styles.markAll, !activeAlerts && styles.hidden)}
          onClick={markAllAsDone}
          onMouseEnter={() => setMarkAllHovered(true)}
          onMouseLeave={() => setMarkAllHovered(false)}
        >
          <CircleCheckSvg className={styles.icon} />
          mark all as done
        </span>

        <span className={styles.groupIndicator}>
          <i
            className={collapsed ? "fas fa-caret-left" : "fas fa-caret-down"}
          />
        </span>
      </div>
      {!collapsed && children}
    </>
  );
});

