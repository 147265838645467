import React, {useContext} from 'react';
import cn from "bem-cn";
import { observer } from 'mobx-react';
// @ts-ignore
import KeyboardEventHandler from 'react-keyboard-event-handler';


import './style.scss';
import {EMPTY_APRON_LABEL} from "../../constants/strings";
import {formatFlightNumber, getIcaoByTurn} from "../../services/data";
import TimeFormatter from "../TimeFormatter";
import TurnaroundsList from "../TurnaroundsList";
import Popup from "../Popup";
import CalendarFormatter from "../CalendarFormatter";
import {RootContext} from "../../services/react";
import {PropsWithClass} from "../../models/common";
import AirlineIcon from '../AirlineIcon';

const b = cn('turnaround-selector');

const TurnaroundSelector: React.FC<PropsWithClass> = ({className}) => {
  const {standStore} = useContext(RootContext);

  const popupContent = (
    <>
      <div className={b('popup-triangle')}/>
      <div className={b('popup-inner')}>
        <TurnaroundsList/>
      </div>
    </>
  )

  if(!standStore)
    return (
      <div className={b()}>
        <Popup popupContent={popupContent} className={b('button')} popupClassName={b('popup')}>
          <i className={'fas fa-search'}/>
          &nbsp;
          Search turnaround
          <i className="far fa-chevron-down"/>
        </Popup>
      </div>
    )

  const {selectedTurnaround: turn,turnaroundsListLoaded,canSelectNextTurn,canSelectPrevTurn,selectNextTurn,selectPrevTurn} = standStore;

  return (
    <div className={b.mix(className)}>
      <KeyboardEventHandler handleKeys={['left']} onKeyEvent={selectPrevTurn} isDisabled={!canSelectPrevTurn}/>
      <a
        className={b('prev-btn',{disabled: !canSelectPrevTurn})}
        onClick={canSelectPrevTurn ? selectPrevTurn : undefined}
      >
        <i className="far fa-arrow-to-left"/>
      </a>
      <Popup
        popupContent={popupContent}
        className={b('button',{'sharp-corners':true})}
        popupClassName={b('popup')}
        closeOnPopupClick={true}
      >
        {!turn && (turnaroundsListLoaded ? EMPTY_APRON_LABEL : "")}
        {turn && (
          <>
            <AirlineIcon className={b('turn-icon')} icaoCode={getIcaoByTurn(turn)} />
            <span className={b('turn-label')}>
              {turn.inboundFlight ? formatFlightNumber(turn.inboundFlight) : 'N/A'}
              <i className={'fas fa-circle'}/>
              {turn.outboundFlight ? formatFlightNumber(turn.outboundFlight) : 'N/A'}
            </span>
            <span className={b('turn-time')}>
              <CalendarFormatter time={turn.start} showTime={true}/>&nbsp;-&nbsp;
              {turn.end ? <TimeFormatter time={turn.end} format={'HH:mm'}/> : 'In progress'}
            </span>
          </>
        )}
        <i className="far fa-chevron-down"/>
      </Popup>
      <KeyboardEventHandler
        handleKeys={['right']}
        onKeyEvent={selectNextTurn}
        isDisabled={!canSelectNextTurn}
      />
      <a
        className={b('next-btn',{disabled: !canSelectNextTurn})}
        onClick={canSelectNextTurn ? selectNextTurn : undefined}
      >
        <i className="far fa-arrow-to-right"/>
      </a>
    </div>
  );
};

export default observer(TurnaroundSelector);