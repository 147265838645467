import React, { useCallback, useEffect, useRef } from "react";

interface Props<T> {
  onResize: (node: T) => void;
}

export const useResizeObserver = <T extends Element = HTMLDivElement>({
  onResize,
}: Props<T>): [(n: T | null) => void, React.MutableRefObject<T>] => {
  const element = useRef<T | null>(null);
  const observer = useRef<ResizeObserver | null>(null);

  const refCallback = useCallback((node: T | null) => {
    if (node !== null) {
      if (observer.current) {
        observer.current.disconnect();
      }

      element.current = node;

      observer.current = new ResizeObserver(() => onResize(node));
      observer.current.observe(node);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = null;
      element.current = null;
    };
  }, []);

  return [refCallback, element as React.MutableRefObject<T>];
};
