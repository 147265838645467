import React from 'react';
import cn from "bem-cn";
import { NavLink } from 'react-router-dom';

import './style.scss';
import StandSelector from "../StandSelector";
import {PropsWithClass} from "../../models/common";
import icon from './airport-icon.png';
import TurnaroundSelector from "../TurnaroundSelector";
import ExportButton from "../ExportButton";
import ScrollShadows from '../ScrollShadows';
import {disableMap} from "../../services/config";

const b = cn('main-menu')

const MainMenu: React.FC<PropsWithClass> = ({className}) => {
  return (
    <ScrollShadows className={b.mix(className).toString()} type={"horizontal"}>
      {!disableMap && (
        <NavLink to={'/'} className={b('map-btn').toString()}>
          <img src={icon}/>
          Map
        </NavLink>
      )}
      {!disableMap && <span className={b('slash')}/>}

      <StandSelector/>

      <span className={b('slash')}/>

      <TurnaroundSelector className={b('turn-selector')}/>

      {/* <ExportButton className={b('export-btn')}/> */}
    </ScrollShadows>
  )
}

export default MainMenu;