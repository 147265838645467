import moment from "moment";
import "moment-timezone";
import {padStart} from 'lodash'

export function getTimeFormatTemplate(date = true ,seconds = true) {
  let format = "";
  if(date)
    format = "YYYY-MM-DD ";
  format += "HH:mm";
  if(seconds)
    format += ":ss";
  return format;
}

export function formatTime(time:number,timezone:string,date = true ,seconds = true) {
  let format = getTimeFormatTemplate(date,seconds);
  return moment(time).tz(timezone).format(format);
}

export function formatTimeForFilename(time:number,timezone:string) {
  return moment(time).tz(timezone).format("YYYY-MM-DD_HH-mm");
}

export var timeOffset = 0;
export function now() {
  return Date.now() + timeOffset;
}

export function setTimeOffset(offset: number) {
  timeOffset = offset;
}

function splitDelayIntoUnits(milliseconds: number): [number,number,number] {
  let secs = Math.round(milliseconds/1000);
  const hours = Math.floor(secs/3600);
  const mins = Math.floor((secs%3600)/60);
  secs  = Math.floor(secs%60);

  return [hours,mins,secs];
}

export function formatDelayHHMMSS(milliseconds: number) {
  const items = splitDelayIntoUnits(milliseconds);
  if(items[0] === 0)
    items.splice(0,1);

  return items.map(i=>padStart(i.toString(),2,'0')).join(':');
}

export function formatDelayHumanText(milliseconds: number,forceHours = false,forceMinutes = false) {
  if(milliseconds < 0)
    milliseconds = 0;
  const units = splitDelayIntoUnits(milliseconds);
  const items = [];

  if(units[0] || forceHours)
    items.push(padStart(units[0].toString(),2,'0') + 'h');
  if(units[1] || forceMinutes)
    items.push(padStart(units[1].toString(),2,'0') + 'm');
  if(units[2] && !units[0] && !units[1])
    items.push(padStart(units[2].toString(),2,'0') + 's');
  if(!items.length)
    items.push("0m")
  return items.join(' ');
}

export function formatTimeDelayShort(relativeTs: number, absoluteTs:number) {
  let delay = Math.ceil(relativeTs/1000) - Math.ceil(absoluteTs/1000);
  if(delay >= 0)
    return '0s';

  let delayUnit;
  if(Math.abs(delay) < 300)
    delayUnit = 's';
  else if(Math.abs(delay) < 180*60){
    delayUnit = 'min';
    delay = Math.round(delay/60);
  }else {
    delayUnit = 'h';
    delay = Math.round(delay/3600)
  }
  return delay + delayUnit;
}

export function toMilliSecondsOrNull(t: string | null): number | null {
  if(!t)
    return null;
  let parsedTs = parseFloat(t);
  return !isNaN(parsedTs) ? parsedTs * 1000 : null;
}

const SLEEP_DELAY = 40000;
export function startSleepCheck() {
  let ts = Date.now();
  setInterval(()=>{
    let current = Date.now();
    let delta = current - ts;
    if(delta > SLEEP_DELAY){
      window.location.reload();
    }else
      ts = current;
  },1000)
}

export function resetTime(ts: number, timezone?: string) {
  const dt = timezone ? moment(ts).tz(timezone) : moment(ts);

  return dt.startOf("day").toDate().getTime();
}